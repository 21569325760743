<template>
  <form class="py-4">
    <fieldset class="space-y-5">
      <div>
        <div class="mt-1">
          <label for="title" class="block text-sm font-medium">{{
            $t("news.title")
          }}</label>
          <input
            type="text"
            name="title"
            id="title"
            class="shadow-sm focus:ring-primary w-2/3 focus:border-primary block sm:text-sm border-gray-300 rounded-md mb-2"
            :placeholder="$t('news.title')"
            v-model="form.title"
            aria-required="true"
          />

          <label for="title_en" class="block text-sm font-medium">{{
            $t("news.title_en")
          }}</label>
          <input
            type="text"
            name="title_en"
            id="title_en"
            class="shadow-sm focus:ring-primary w-2/3 focus:border-primary block sm:text-sm border-gray-300 rounded-md mb-2"
            :placeholder="$t('news.title_en')"
            v-model="form.title_en"
          />

          <label for="body" class="block text-sm font-medium">{{
            $t("news.message")
          }}</label>
          <textarea
            type="text"
            name="body"
            id="body"
            class="shadow-sm focus:ring-primary w-2/3 focus:border-primary block sm:text-sm border-gray-300 rounded-md mb-2"
            :placeholder="$t('news.message')"
            v-model="form.body"
          />

          <label for="body_en" class="block text-sm font-medium">{{
            $t("news.message_en")
          }}</label>
          <textarea
            type="text"
            name="body_en"
            id="body_en"
            class="shadow-sm focus:ring-primary w-2/3 focus:border-primary block sm:text-sm border-gray-300 rounded-md mb-2"
            :placeholder="$t('news.message_en')"
            v-model="form.body_en"
          />
        </div>
      </div>
    </fieldset>

    <button
      type="button"
      @click="createNews"
      :class="
        isDisabledButton
          ? 'opacity-50 cursor-not-allowed disabled:opacity-50'
          : 'hover:bg-primary-light'
      "
      class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
      :disabled="isDisabledButton"
    >
      {{ $t("create") }}
    </button>
  </form>
</template>

<script>
import { ref, reactive, computed } from "vue";
import { useStore } from "vuex";

export default {
  methods: {
    middleware: "admin",
    async createNews() {
      this.isLoading = true;
      await this.store.dispatch("createNews", this.form);
      this.form = { ...this.defaultForm };
      await this.store.dispatch("fetchAllIssues");
      this.isLoading = false;
    },
  },
  setup() {
    const store = useStore();
    const defaultForm = {
      title: "",
      title_en: "",
      body: "",
      body_en: "",
    };
    const form = reactive({ ...defaultForm });
    const isLoading = ref(false);

    const isDisabledButton = computed(() => {
      const title = form.title.length;
      const title_en = form.title_en.length;
      const body = form.body.length;
      const body_en = form.body_en.length;

      const titles = title && title_en;
      const bodies = body && body_en;
      const condition = !(titles && bodies) || isLoading.value;

      return condition;
    });

    return {
      store,
      defaultForm,
      form,
      isLoading,
      isDisabledButton,
    };
  },
};
</script>

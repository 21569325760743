<template>
  <span class="flex space-x-2">
    <img
      @click="changeLocale('ru')"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAYAAACaq43EAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAA3SURBVEhL7dOxAQAwCAJBcP+dEwtneBqusfxG/JYC5i6uYUwsbKlfzWgYEwt7t9Q5IRrGhMLSBxppCB7mxr++AAAAAElFTkSuQmCC"
      class="cursor-pointer border border-gray-300 rounded-sm"
    />
    <img
      @click="changeLocale('en')"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAYAAACaq43EAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAKxSURBVEhLxZRfSFNhGId/56yMxOZioWRzVoNiDsK1kvSmujEiSRxNl0soC6VdJHmTonSxGiqCYcJCqxWKJhoTwYi8kW5UlDUbrFGwymlLJWGzYaTOk9/hDF3745xSz9U553sPv/Oc73tfylFYyIibm7EiEEL32Iy65+PwrTCIxMpYKd7vFcDn8UDBMBBf6MTkjJdbDU2pUooaegRuQzNEDQ2geftTYJPJsNDfh/vakxh6loejaQKufOscStmDwUopbvffweLoMKRmM/iqAtDnnKew+LAd36qq8FmtxvEkCtauS6gukYNHU9zrsUEs32ZPQVihQXJ5OSQmExwLO5F9rQ/0xwk3shqd6L75BNtlH85Sb7RAXmTCqG0WNCkke6pr/4AAe5UqJvtIljWGMfz6vczWscF+AuxFqZuyj8ZyPQHBhGjs/6bovCQqy/VQULSE7R3ye6s1UpR8NcHT9QJigwGC/Hx2bVyw1k6fcnLAS0hAWksLKOE+tiX1Ty0hA/1QzCrc9aZYHxwLlIXPZ3zz89ztv4P+H6E8Pj/2X22mKPASE5HhdnNPoockRjxcu3ftQPV1OSqvZoCZ+4GJsjL4vF4cGRgICnb39sKp1SJRfRnGg0roO+wRZ35QO/nJlCXB0qlk22e+pxt2hQJxmVkwXrzHVaxBWiY+Nw/pVit8U5MoeHQDwxXiiH0fFEws/QNDEr8Eh1KJmaYmzDV24PSQCJ2vHVzlGmRIHFO/xLtZBod7enCgthZxt4rxRjyCu8XpIadeQHA4ywe59ThbZ8cX10+uMhgy9fwDg9jLbLaI9mzwRpatJjtbvBFkT6O1p7diGY6Q9t9dAfbU8upn+k/s0vQ0pivqUdI2FTYwNTkBzldFAaeaOtHKrQZDQtp0Z9htJCef5Ag0V0Bvl2U4Qtu78Aeu3K+joU7p0AAAAABJRU5ErkJggg=="
      class="cursor-pointer border border-gray-300 rounded-sm"
    />
  </span>
</template>

<script>
export default {
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("language", locale);
    },
  },
};
</script>

<template>
  <Loader v-if="loading" />
  <div class="flex flex-col lg:flex-row space-x-5" v-else>
    <div class="lg:w-2/3">
      <h3 class="text-xl font-semibold text-gray-900">
        {{ $t("lms.create.title") }}
      </h3>
      <Form>
        <div>
          <Input
            v-model="name"
            :label="$t('lms.create.name')"
            :placeholder="placerNewLms"
            name="name"
            id="name"
            class="w-1/2"
          />
        </div>
        <!-- PrefixInput
          v-model="prefix"
        / -->
        <div class="flex flex-col">
          <label for="prefix" class="text-sm font-medium">
            {{ $t("lms.create.prefix") }}
          </label>
          <div
            class="flex flex-col lg:flex-row space-y-2 space-x-2 items-center"
          >
            <div class="mt-1 flex">
              <span
                class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
              >
                https://
              </span>
              <input
                type="text"
                name="prefix"
                id="prefix"
                class="min-w-0 px-3 py-2 rounded-none focus:ring-primary focus:border-primary sm:text-sm border-gray-300"
                placeholder="emas"
                v-model="prefix"
                @input="checkDomain"
              />
              <span
                class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
              >
                .studixy.com
              </span>
            </div>
            <div class="flex items-center space-x-1" v-if="viewCheckDomain">
              <span
                v-if="loadingDomain"
                class="inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium bg-yellow-100 text-primary"
              >
                <Loader size="s" class="mr-1.5" rootClasses="" />
                {{ $t("lms.create.domain_wait") }}
              </span>
              <span
                v-else-if="domainValid.data === true"
                class="inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium bg-green-200 text-green-600"
              >
                <CheckIcon class="-ml-1 mr-1.5 h-5 w-5 text-green-600" />
                {{ $t("lms.create.domain_success") }}
              </span>
              <span
                v-else
                class="inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium bg-red-200 text-red-600"
              >
                <XIcon
                  class="-ml-1 mr-1.5 mh-5 min-w-5 min-h-5 w-5 text-red-600"
                />
                <template v-if="domainValid.data === 'ISSET_DOMAIN'">
                  {{ $t("lms.create.domain_isset") }}
                </template>
                <template v-if="domainValid.data === 'EXCEPTION_DOMAIN'">
                  {{ $t("lms.create.domain_exception") }}
                </template>
                <template v-if="domainValid.data.startsWith('MIN_LEN_')">
                  {{ $t("lms.create.domain_min_len") }}
                  {{ domainValid.data.split("_").pop() }}
                  {{ $t("auth.warnings.symbols") }}
                </template>
                <template v-if="domainValid.data === 'NOT_RIGHT_WRITING'">
                  {{ $t("lms.create.domain_not_right") }}
                </template>
                <template v-if="domainValid.data === 'DOMAIN_DONT_NUMBER'">
                  {{ $t("lms.create.domain_dont_number") }}
                </template>
              </span>
            </div>
          </div>
        </div>
        <div>
          <label for="email" class="block text-sm font-medium">{{
            $t("profile.yourProfile.email")
          }}</label>
          <div class="mt-1">
            <input
              type="text"
              name="email"
              id="email"
              class="w-1/2 shadow-sm focus:ring-primary focus:border-primary block sm:text-sm border-gray-300 rounded-md"
              v-model="email"
            />
          </div>
        </div>
        <PasswordInput
          id="password"
          v-model="password"
          class="w-1/2"
          labelClasses="block text-sm font-medium"
          :label="$t('update_create.password')"
        />
        <Select
          v-model="selectedCurrency"
          :options="currencyOptions"
          :label="$t('currency.select')"
          class="w-1/2"
        />
        <div
          class="relative border p-4 flex flex-col lg:pl-4 lg:pr-6 focus:outline-none lg:flex-row rounded-md bg-white items-center"
        >
          <label class="flex items-center space-x-1 cursor-pointer">
            <Checkbox v-model="newLms[0].value" />
            <span>{{ $t("lms.create.transfer_students.title") }}</span>
            <QuestionMarkCircleIcon
              class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
              ref="transfer_students"
            />
            <Hint targetref="transfer_students" position="top">
              {{ $t("lms.create.transfer_students.hint") }}
            </Hint>
          </label>
          <div class="ml-auto pl-1 text-sm flex flex-col text-center">
            <span v-if="newLms[0].type === 'once'">
              {{ $t("lms.create.transfer_students.one_time") }}
            </span>
            <span class="font-medium"
              >{{ newLms[0][getPrice] || 0 }}
              {{ $t(`currency.${selectedCurrency.value}`)
              }}<span v-if="newLms[0].type === 'month'">
                / {{ $t("lms.create.time_unit") }}</span
              ></span
            >
          </div>
        </div>
        <div
          class="relative border p-4 flex flex-col lg:pl-4 lg:pr-6 focus:outline-none rounded-md bg-white"
        >
          <div class="items-center flex">
            <label class="flex items-center space-x-1 cursor-pointer">
              <Checkbox v-model="newLms[1].value" disabled />
              <span>{{ $t("lms.create.base.title") }}</span>
              <QuestionMarkCircleIcon
                class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                ref="baseFn"
              />
              <Hint targetref="baseFn" position="top">
                {{ $t("lms.create.base.hint") }}
              </Hint>
            </label>
            <button
              type="button"
              @click="newLms[1].childActive = !newLms[1].childActive"
              class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
            >
              <component
                :is="arrowComponent(newLms[1].childActive)"
                class="h-6 w-6 text-white"
              />
            </button>
            <div class="ml-auto pl-1 text-sm flex flex-col text-center">
              <span v-if="newLms[1].type === 'once'">{{
                $t("lms.create.one_time")
              }}</span>
              <span class="font-medium"
                >{{ newLms[1][getPrice] || 0 }}
                {{ $t(`currency.${selectedCurrency.value}`)
                }}<span v-if="newLms[1].type === 'month'">
                  / {{ $t("lms.create.time_unit") }}</span
                ></span
              >
            </div>
          </div>
          <Inlay :open="newLms[1].childActive" class="ml-5 mt-2 px-3 py-1.5">
            <fieldset class="space-y-0.5">
              <label
                class="flex items-center space-x-1 text-sm"
                v-for="(item, index) in newLms[1].child"
                :key="index"
              >
                <Checkbox modelValue="true" size="s" disabled />
                <span>{{ $t(item.title) }}</span>
                <QuestionMarkCircleIcon
                  class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                  :ref="`base_student-${index}`"
                />
                <Hint :targetref="`base_student-${index}`" position="right">
                  {{ $t(item.hint) }}
                </Hint>
              </label>
            </fieldset>
          </Inlay>
        </div>
        <div
          class="relative border p-4 flex flex-col lg:pl-4 lg:pr-6 focus:outline-none rounded-md bg-white"
        >
          <div class="items-center flex">
            <label class="flex items-center space-x-1 cursor-pointer">
              <Checkbox
                v-model="newLms[2].value"
                @changed="changeNetworkingStatus"
              />
              <span>{{ $t("lms.create.networking.title") }}</span>
              <QuestionMarkCircleIcon
                class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                ref="network"
              />
              <Hint targetref="network" position="top">
                {{ $t("lms.create.networking.hint") }}
              </Hint>
            </label>
            <button
              type="button"
              @click="newLms[2].childActive = !newLms[2].childActive"
              class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
            >
              <component
                :is="arrowComponent(newLms[2].childActive)"
                class="h-6 w-6 text-white"
              />
            </button>
            <div class="ml-auto pl-1 text-sm flex flex-col text-center">
              <span v-if="newLms[2].type === 'once'">{{
                $t("lms.create.one_time")
              }}</span>
              <span class="font-medium"
                >{{ newLms[2][getPrice] || 0 }}
                {{ $t(`currency.${selectedCurrency.value}`)
                }}<span v-if="newLms[2].type === 'month'">
                  / {{ $t("lms.create.time_unit") }}</span
                ></span
              >
            </div>
          </div>
          <Inlay
            :open="newLms[2].childActive"
            class="ml-5 px-3 py-1.5 space-y-3"
          >
            <fieldset>
              <h3 class="font-medium">
                {{ $t("lms.create.networking.type.title") }}
              </h3>
              <RadioGroup
                v-model="newLms[2].child[0].value"
                :disabled="!newLms[2].value"
              >
                <div class="relative bg-white rounded-md -space-y-px">
                  <RadioGroupOption
                    as="template"
                    v-for="(item, index) in newLms[2].child[0].type"
                    :key="index"
                    :value="item.name"
                    v-slot="{ checked, active, disabled }"
                  >
                    <div
                      :class="[
                        index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                        index === 1 ? 'rounded-bl-md rounded-br-md' : '',
                        checked
                          ? 'bg-indigo-50 border-indigo-200 z-10'
                          : 'border-gray-200',
                        disabled ? 'opacity-80' : '',
                        'relative border p-4 flex flex-col cursor-pointer lg:pl-4 lg:pr-6 focus:outline-none lg:flex-row',
                      ]"
                    >
                      <div class="flex items-center text-sm w-3/5">
                        <span
                          :class="[
                            checked
                              ? 'bg-primary border-transparent'
                              : 'bg-white border-gray-300',
                            active ? 'ring-2 ring-offset-2 ring-primary' : '',
                            'h-4 w-4 rounded-full border flex items-center justify-center',
                          ]"
                          aria-hidden="true"
                        >
                          <span class="rounded-full bg-white w-1.5 h-1.5" />
                        </span>
                        <RadioGroupLabel
                          as="span"
                          :class="[
                            checked ? 'text-primary' : 'text-gray-900',
                            'ml-3 font-medium',
                          ]"
                          >{{ $t(item.title) }}</RadioGroupLabel
                        >
                      </div>
                      <div class="w-1/5">
                        <QuestionMarkCircleIcon
                          class="m-auto h-5 w-5 text-gray-400 hover:text-gray-700"
                          :ref="`network-${item.name}`"
                        />
                        <Hint
                          :targetref="`network-${item.name}`"
                          position="right"
                        >
                          {{ $t(item.hint) }}
                        </Hint>
                      </div>
                      <RadioGroupDescription
                        class="ml-6 pl-1 text-sm lg:ml-0 lg:pl-0 lg:text-center w-1/5"
                      >
                        <div
                          v-if="newLms[7].type === 'once'"
                          :class="[checked ? 'text-primary' : 'text-gray-900']"
                        >
                          {{ $t("lms.create.one_time") }}
                        </div>
                        <span
                          :class="[
                            checked ? 'text-primary' : 'text-gray-900',
                            'font-medium',
                          ]"
                          >{{ item[getPrice] }}
                          {{ $t(`currency.${selectedCurrency.value}`)
                          }}<span v-if="item.type === 'month'">
                            / {{ $t("lms.create.time_unit") }}
                          </span>
                        </span>
                      </RadioGroupDescription>
                    </div>
                  </RadioGroupOption>
                </div>
              </RadioGroup>
            </fieldset>
            <div>
              <label class="flex items-center space-x-1">
                <Checkbox v-model="newLms[2].value" disabled />
                <span>{{ $t("lms.create.networking.base.title") }}</span>
                <QuestionMarkCircleIcon
                  class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                  ref="base"
                />
                <Hint targetref="base" position="right">
                  {{ $t("lms.create.networking.base.hint") }}
                </Hint>
                <button
                  type="button"
                  @click="
                    newLms[2].child[1].active = !newLms[2].child[1].active
                  "
                  class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
                >
                  <component
                    :is="arrowComponent(newLms[2].child[1].active)"
                    class="h-6 w-6 text-white"
                  />
                </button>
              </label>
              <Inlay
                :open="newLms[2].child[1].active"
                class="ml-5 mt-2 px-3 py-1.5 space-y-0.5"
              >
                <label
                  class="flex items-center space-x-1 text-sm"
                  v-for="(item, index) in newLms[2].child[1].fields"
                  :key="index"
                >
                  <Checkbox modelValue="true" size="s" disabled />
                  <span>{{ $t(item) }}</span>
                </label>
              </Inlay>
            </div>
            <div>
              <div class="items-center flex">
                <label class="flex items-center space-x-1 cursor-pointer">
                  <Checkbox
                    v-model="newLms[5].value"
                    :disabled="!newLms[2].value"
                  />
                  <span>{{
                    $t("lms.create.networking.additional_fields.title")
                  }}</span>
                  <QuestionMarkCircleIcon
                    class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                    ref="adititonal"
                  />
                  <Hint targetref="adititonal" position="top">
                    {{ $t("lms.create.networking.additional_fields.hint") }}
                  </Hint>
                </label>
                <button
                  type="button"
                  @click="newLms[5].childActive = !newLms[5].childActive"
                  class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
                >
                  <component
                    :is="arrowComponent(newLms[5].childActive)"
                    class="h-6 w-6 text-white"
                  />
                </button>
                <div
                  v-if="newLms[5].selected.length"
                  class="ml-auto pl-1 text-sm flex flex-col text-center"
                >
                  <span v-if="newLms[5].type === 'once'">{{
                    $t("lms.create.one_time")
                  }}</span>
                  <span class="font-medium"
                    >{{ newLms[5][this.getPrice] * newLms[5].selected.length }}
                    {{ $t(`currency.${selectedCurrency.value}`)
                    }}<span v-if="newLms[5].type === 'month'"
                      >/ {{ $t("lms.create.time_unit") }}</span
                    >
                  </span>
                </div>
              </div>
              <Inlay :open="newLms[5].childActive" class="ml-4 space-y-5 mt-5">
                <div
                  class="flex space-x-2 items-center"
                  v-for="(item, index) in newLms[5].selected"
                  :key="index"
                >
                  <button
                    @click="deleteAdditional(index)"
                    type="button"
                    class="h-6 w-6 ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
                  >
                    <MinusIcon class="h-4 w-4 text-white" />
                  </button>
                  <Listbox
                    as="div"
                    v-model="newLms[5].selected[index]"
                    class="w-1/3 relative"
                  >
                    <ListboxLabel
                      class="block text-sm font-medium text-gray-700 absolute -top-4"
                    >
                      {{ $t("lms.create.networking.additional_fields.name") }}
                    </ListboxLabel>
                    <div class="mt-1 relative">
                      <ListboxButton
                        class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm"
                      >
                        <span class="flex items-center">
                          <span class="block truncate">{{
                            newLms[5].selected[index].text ||
                            $t(
                              "lms.create.networking.additional_fields.placeholder"
                            )
                          }}</span>
                        </span>
                        <span
                          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                        >
                          <SelectorIcon
                            class="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </ListboxButton>

                      <transition
                        leave-active-class="transition ease-in duration-100"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0"
                      >
                        <ListboxOptions
                          class="scrollbar absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                        >
                          <ListboxOption
                            as="template"
                            v-for="child in newLms[5].child"
                            :key="child.id"
                            :value="child"
                            v-slot="{ active, selected }"
                            :disabled="isDisabled(child.value)"
                          >
                            <li
                              :class="[
                                active
                                  ? 'text-white bg-primary'
                                  : 'text-gray-900',
                                'cursor-default select-none relative py-2 pl-3 pr-9',
                              ]"
                            >
                              <div class="flex items-center">
                                <span
                                  :class="[
                                    selected ? 'font-semibold' : 'font-normal',
                                    'block truncate',
                                  ]"
                                >
                                  {{ child.text }}
                                </span>
                              </div>

                              <span
                                v-if="selected"
                                :class="[
                                  active ? 'text-white' : 'text-primary',
                                  'absolute inset-y-0 right-0 flex items-center pr-4',
                                ]"
                              >
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                              </span>
                            </li>
                          </ListboxOption>
                        </ListboxOptions>
                      </transition>
                    </div>
                  </Listbox>
                </div>
                <div class="flex">
                  <div
                    class="flex items-center space-x-2 cursor-pointer"
                    @click="addAdditionalField"
                  >
                    <button
                      type="button"
                      class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
                    >
                      <PlusIcon class="h-4 w-4 text-white" />
                    </button>
                    <span class="text-sm font-medium text-gray-700">{{
                      $t("lms.create.networking.additional_fields.add")
                    }}</span>
                  </div>
                </div>
              </Inlay>
            </div>
            <!-- div>
              <div class="flex items-center space-x-1">
                <span>{{ $t("lms.create.networking.preview.title") }}</span>
                <QuestionMarkCircleIcon
                  class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                  ref="preview"
                />
                <Hint targetref="preview" position="right">
                  {{ $t("lms.create.networking.preview.hint") }}
                </Hint>
                <button
                  type="button"
                  @click="newLms[6].childActive = !newLms[6].childActive"
                  class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
                >
                  <component
                    :is="arrowComponent(newLms[6].childActive)"
                    class="h-6 w-6 text-white"
                  />
                </button>
              </div>
              <Inlay
                :open="newLms[6].childActive"
                class="mt-2 relative border p-4 lg:pl-4 lg:pr-6 focus:outline-none rounded-md bg-white shadow-sm"
              >
                <div class="flex space-x-3">
                  <div class="w-1/4 flex flex-col space-y-2 items-center">
                    <img
                      src="https://avatars.dicebear.com/api/initials/STUDIXY.svg"
                      class="h-28 w-28 rounded-full"
                    />
                    <span> {{ $t("lms.create.networking.preview.learning") }}</span>
                  </div>
                  <div class="w-3/4 flex flex-col space-y-2">
                    <p class="text-xl">
                      {{ $t("lms.create.networking.preview.name") }} {{ $t("lms.create.networking.preview.surname") }}
                    </p>
                    <TabGroup as="div">
                      <div class="border-b border-gray-200">
                        <TabList class="-mb-px flex lg:space-x-8 space-x-2">
                          <Tab
                            as="template"
                            v-slot="{ selected }"
                            v-for="tab in tabs"
                            :key="tab.name"
                          >
                            <a
                              :class="[
                                selected
                                  ? 'border-primary text-primary'
                                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm cursor-pointer text-center',
                              ]"
                            >
                              <span>{{ $t(tab.name) }}</span>
                            </a>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanels as="template">
                        <TabPanel class="py-5 flex flex-col space-y-2">
                          <div class="flex">
                            <span class="w-1/2 font-medium">{{
                              $t("lms.create.networking.base.country")
                            }}</span>
                            <span class="w-1/2">{{ 
                              <span class="w-1/2">{{ 
                            <span class="w-1/2">{{ 
                              <span class="w-1/2">{{ 
                            <span class="w-1/2">{{ 
                              <span class="w-1/2">{{ 
                            <span class="w-1/2">{{ 
                              <span class="w-1/2">{{ 
                            <span class="w-1/2">{{ 
                              $t("lms.create.networking.preview.country") 
                                $t("lms.create.networking.preview.country") 
                              $t("lms.create.networking.preview.country") 
                                $t("lms.create.networking.preview.country") 
                              $t("lms.create.networking.preview.country") 
                                $t("lms.create.networking.preview.country") 
                              $t("lms.create.networking.preview.country") 
                                $t("lms.create.networking.preview.country") 
                              $t("lms.create.networking.preview.country") 
                            }}</span>
                          </div>
                          <div class="flex">
                            <span class="w-1/2 font-medium">{{
                              $t("lms.create.networking.base.city")
                            }}</span>
                            <span class="w-1/2">{{ 
                              <span class="w-1/2">{{ 
                            <span class="w-1/2">{{ 
                              <span class="w-1/2">{{ 
                            <span class="w-1/2">{{ 
                              <span class="w-1/2">{{ 
                            <span class="w-1/2">{{ 
                              <span class="w-1/2">{{ 
                            <span class="w-1/2">{{ 
                              $t("lms.create.networking.preview.city") 
                                $t("lms.create.networking.preview.city") 
                              $t("lms.create.networking.preview.city") 
                                $t("lms.create.networking.preview.city") 
                              $t("lms.create.networking.preview.city") 
                                $t("lms.create.networking.preview.city") 
                              $t("lms.create.networking.preview.city") 
                                $t("lms.create.networking.preview.city") 
                              $t("lms.create.networking.preview.city") 
                            }}</span>
                          </div>
                          <div class="flex">
                            <span class="w-1/2 font-medium">{{
                              $t("lms.create.networking.base.hobby")
                            }}</span>
                            <span class="w-1/2">{{ 
                              <span class="w-1/2">{{ 
                            <span class="w-1/2">{{ 
                              <span class="w-1/2">{{ 
                            <span class="w-1/2">{{ 
                              <span class="w-1/2">{{ 
                            <span class="w-1/2">{{ 
                              <span class="w-1/2">{{ 
                            <span class="w-1/2">{{ 
                              $t("lms.create.networking.preview.hobby") 
                                $t("lms.create.networking.preview.hobby") 
                              $t("lms.create.networking.preview.hobby") 
                                $t("lms.create.networking.preview.hobby") 
                              $t("lms.create.networking.preview.hobby") 
                                $t("lms.create.networking.preview.hobby") 
                              $t("lms.create.networking.preview.hobby") 
                                $t("lms.create.networking.preview.hobby") 
                              $t("lms.create.networking.preview.hobby") 
                            }}</span>
                          </div>
                          <div class="flex">
                            <span class="w-1/2 font-medium">{{
                              $t("lms.create.networking.base.interests")
                            }}</span>
                            <span class="w-1/2">{{ 
                              <span class="w-1/2">{{ 
                            <span class="w-1/2">{{ 
                              <span class="w-1/2">{{ 
                            <span class="w-1/2">{{ 
                              <span class="w-1/2">{{ 
                            <span class="w-1/2">{{ 
                              <span class="w-1/2">{{ 
                            <span class="w-1/2">{{ 
                              $t("lms.create.networking.preview.interests") 
                                $t("lms.create.networking.preview.interests") 
                              $t("lms.create.networking.preview.interests") 
                                $t("lms.create.networking.preview.interests") 
                              $t("lms.create.networking.preview.interests") 
                                $t("lms.create.networking.preview.interests") 
                              $t("lms.create.networking.preview.interests") 
                                $t("lms.create.networking.preview.interests") 
                              $t("lms.create.networking.preview.interests") 
                            }}</span>
                          </div>
                          <template v-if="newLms[5].value">
                            <template
                              v-for="(item, index) in newLms[5].selected"
                              :key="index"
                            >
                              <div class="flex" v-if="item.text">
                                <span class="w-1/2 font-medium">{{
                                  item.text
                                }}</span>
                                <span class="w-1/2" v-if="item.values">{{
                                  item.values
                                }}</span>
                                <span class="w-1/2" v-else
                                  >Ответ в свободной форме</span
                                >
                              </div>
                            </template>
                          </template>
                        </TabPanel>
                        <TabPanel class="py-5">{{ 
                          <TabPanel class="py-5">{{ 
                        <TabPanel class="py-5">{{ 
                          <TabPanel class="py-5">{{ 
                        <TabPanel class="py-5">{{ 
                          <TabPanel class="py-5">{{ 
                        <TabPanel class="py-5">{{ 
                          <TabPanel class="py-5">{{ 
                        <TabPanel class="py-5">{{ 
                          $t("lms.create.networking.preview.education_info") 
                            $t("lms.create.networking.preview.education_info") 
                          $t("lms.create.networking.preview.education_info") 
                            $t("lms.create.networking.preview.education_info") 
                          $t("lms.create.networking.preview.education_info") 
                            $t("lms.create.networking.preview.education_info") 
                          $t("lms.create.networking.preview.education_info") 
                            $t("lms.create.networking.preview.education_info") 
                          $t("lms.create.networking.preview.education_info") 
                        }}</TabPanel>
                        <TabPanel class="py-5">{{ 
                          <TabPanel class="py-5">{{ 
                        <TabPanel class="py-5">{{ 
                          <TabPanel class="py-5">{{ 
                        <TabPanel class="py-5">{{ 
                          <TabPanel class="py-5">{{ 
                        <TabPanel class="py-5">{{ 
                          <TabPanel class="py-5">{{ 
                        <TabPanel class="py-5">{{ 
                          $t("lms.create.networking.preview.additional_info") 
                            $t("lms.create.networking.preview.additional_info") 
                          $t("lms.create.networking.preview.additional_info") 
                            $t("lms.create.networking.preview.additional_info") 
                          $t("lms.create.networking.preview.additional_info") 
                            $t("lms.create.networking.preview.additional_info") 
                          $t("lms.create.networking.preview.additional_info") 
                            $t("lms.create.networking.preview.additional_info") 
                          $t("lms.create.networking.preview.additional_info") 
                        }}</TabPanel>
                      </TabPanels>
                    </TabGroup>
                  </div>
                </div>
              </Inlay>
            </div -->
          </Inlay>
        </div>
        <div
          class="relative border p-4 flex flex-col lg:pl-4 lg:pr-6 focus:outline-none rounded-md bg-white"
        >
          <div class="items-center flex">
            <label class="flex items-center space-x-1 cursor-pointer">
              <Checkbox v-model="newLms[7].value" />
              <span>{{ $t("lms.create.lifelong_learning.title") }}</span>
              <QuestionMarkCircleIcon
                class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                ref="lifelong_learning"
              />
              <Hint targetref="lifelong_learning" position="right">
                {{ $t("lms.create.lifelong_learning.hint") }}
              </Hint>
            </label>
            <button
              type="button"
              @click="newLms[7].childActive = !newLms[7].childActive"
              class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
            >
              <component
                :is="arrowComponent(newLms[7].childActive)"
                class="h-6 w-6 text-white"
              />
            </button>
            <div class="ml-auto pl-1 text-sm flex flex-col text-center">
              <span v-if="newLms[7].type === 'once'">{{
                $t("lms.create.one_time")
              }}</span>
              <span class="font-medium"
                >{{ newLms[7][getPrice] || 0 }}
                {{ $t(`currency.${selectedCurrency.value}`)
                }}<span v-if="newLms[7].type === 'month'">
                  / {{ $t("lms.create.time_unit") }}</span
                ></span
              >
            </div>
          </div>
          <Inlay
            :open="newLms[7].childActive"
            class="ml-5 mt-2 px-3 py-1.5 space-y-2"
          >
            <div>
              <div class="flex space-x-1 flex-col">
                <h3 class="font-medium">
                  {{ $t("lms.create.lifelong_learning.select") }}
                </h3>
                <div class="flex items-center">
                  <div
                    class="flex flex-row rounded-lg relative bg-transparent mt-1 counter"
                  >
                    <button
                      :disabled="!newLms[7].value"
                      @click="MinusLifeCount"
                      type="button"
                      class="bg-gray-50 border hover:bg-gray-100 w-7 p-0.5 rounded-l cursor-pointer outline-none flex items-center justify-center"
                    >
                      <MinusIcon class="w-4 h-4" />
                    </button>
                    <input
                      :disabled="!newLms[7].value"
                      type="number"
                      v-model="newLms[8].count"
                      class="p-0 w-10 border border-gray-200 outline-none focus:outline-none text-center bg-gray-50 font-medium text-md hover:text-black focus:text-black lg:text-basecursor-default flex items-center text-gray-700 outline-none"
                    />
                    <button
                      :disabled="!newLms[7].value"
                      @click="PlusLifeCount"
                      type="button"
                      class="bg-gray-50 border hover:bg-gray-100 w-7 p-0.5 rounded-r cursor-pointer outline-none flex items-center justify-center"
                    >
                      <PlusIcon class="w-4 h-4" />
                    </button>
                  </div>
                  <!-- QuestionMarkCircleIcon
                    class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                    ref="lifelong_learning_count"
                  />
                  <Hint targetref="lifelong_learning_count" position="top">
                    {{ $t("lms.create.lifelong_learning_count.hint") }}
                  </Hint !-->
                  <div class="ml-3 pl-1 text-sm flex flex-col text-center">
                    <span v-if="newLms[8].type === 'once'">{{
                      $t("lms.create.one_time")
                    }}</span>
                    <span class="font-medium"
                      >{{ newLms[8][getPrice] * newLms[8].count }}
                      {{ $t(`currency.${selectedCurrency.value}`)
                      }}<span v-if="newLms[8].type === 'month'">
                        / {{ $t("lms.create.time_unit") }}</span
                      ></span
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- div>
              <div class="flex items-center space-x-1">
                <span>{{ $t("lms.create.lifelong.preview.title") }}</span>
                <QuestionMarkCircleIcon
                  class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                  ref="preview_lifelong"
                />
                <Hint targetref="preview_lifelong" position="right">
                  {{ $t("lms.create.lifelong.preview.hint") }}
                </Hint>
                <button
                  type="button"
                  @click="newLms[8].childActive = !newLms[8].childActive"
                  class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
                >
                  <component
                    :is="arrowComponent(newLms[8].childActive)"
                    class="h-6 w-6 text-white"
                  />
                </button>
              </div>
              <Inlay
                :open="newLms[8].childActive"
                class="mt-2 relative border p-4 lg:pl-4 lg:pr-6 focus:outline-none rounded-md bg-white shadow-sm"
              >
                <div>
                  <div class="flex flex-col text-center">
                    <span>{{
                      $t("lms.create.lifelong.preview.program")
                    }}</span>
                    <Slider
                      v-model="scoreProgram"
                      :format="format"
                      class="mt-12"
                    />
                    <span class="mt-3">{{
                      $t("lms.create.lifelong.preview.exam")
                    }}</span>
                    <Slider v-model="scoreExam" :max="10" class="mt-12" />
                  </div>
                </div>
                <div class="flex flex-col mt-3">
                  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div
                      class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
                    >
                      <div
                        class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
                      >
                        <table class="min-w-full divide-y divide-gray-200">
                          <thead class="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {{
                                  $t("lms.create.lifelong.preview.knowledges")
                                }}
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {{
                                  $t("lms.create.lifelong.preview.knowledges_gained")
                                }}
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {{ $t("lms.create.lifelong.preview.module") }}
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {{
                                  $t("lms.create.lifelong.preview.skills_gained")
                                }}
                              </th>
                              <th
                                scope="col"
                                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                {{ $t("lms.create.lifelong.preview.skills") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, idX) in table"
                              :key="item.know"
                              :class="[
                                idX % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                                scoreProgram >= item.need ? '' : 'opacity-60',
                              ]"
                            >
                              <td
                                class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                              >
                                {{ $t(item.know) }} {{ item.need/10 }}
                              </td>
                              <td
                                class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                              >
                                {{ scoreExam * 10 }}%
                              </td>
                              <td
                                class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                              >
                                {{ $t(item.module) }} {{ item.need/10 }}
                              </td>
                              <td
                                class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                              >
                                {{ scoreExam * 10 }}%
                              </td>
                              <td
                                class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                              >
                                {{ $t(item.skill) }} {{ item.need/10 }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </Inlay>
            </div -->
          </Inlay>
        </div>
        <div
          class="relative border p-4 flex flex-col lg:pl-4 lg:pr-6 focus:outline-none rounded-md bg-white"
        >
          <div class="items-center flex">
            <label class="flex items-center space-x-1 cursor-pointer">
              <Checkbox v-model="newLms[9].value" />
              <span>{{ $t("lms.create.rating.title") }}</span>
              <QuestionMarkCircleIcon
                class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                ref="rating"
              />
              <Hint targetref="rating" position="top">
                {{ $t("lms.create.rating.hint") }}</Hint
              >
            </label>
            <button
              type="button"
              @click="newLms[9].childActive = !newLms[9].childActive"
              class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
            >
              <component
                :is="arrowComponent(newLms[9].childActive)"
                class="h-6 w-6 text-white"
              />
            </button>
            <div class="ml-auto pl-1 text-sm flex flex-col text-center">
              <span v-if="newLms[9].type === 'once'">{{
                $t("lms.create.one_time")
              }}</span>
              <span class="font-medium"
                >{{ newLms[9][getPrice] || 0 }}
                {{ $t(`currency.${selectedCurrency.value}`)
                }}<span v-if="newLms[9].type === 'month'">
                  / {{ $t("lms.create.time_unit") }}</span
                ></span
              >
            </div>
          </div>
          <Inlay :open="newLms[9].childActive" class="ml-5 mt-2 px-3 py-1.5">
            <fieldset class="space-y-2">
              <div class="flex flex-col space-y-2">
                <div class="flex items-center">
                  <h3 class="font-medium">
                    {{ $t("lms.create.rating.view.title") }}
                  </h3>
                  <QuestionMarkCircleIcon
                    class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                    ref="rating_type"
                  />
                  <Hint targetref="rating_type" position="right">
                    {{ $t("lms.create.rating.view.hint") }}</Hint
                  >
                </div>
                <RadioGroup
                  v-model="newLms[12].value"
                  :disabled="!newLms[9].value"
                >
                  <div class="relative bg-white rounded-md -space-y-px">
                    <RadioGroupOption
                      as="template"
                      v-for="(item, index) in newLms[12].child"
                      :key="index"
                      :value="item.value"
                      v-slot="{ checked, active, disabled }"
                    >
                      <div
                        :class="[
                          index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                          index === 1 ? 'rounded-bl-md rounded-br-md' : '',
                          checked
                            ? 'bg-indigo-50 border-indigo-200 z-10'
                            : 'border-gray-200',
                          disabled ? 'opacity-80' : '',
                          'relative border p-4 flex flex-col cursor-pointer lg:pl-4 lg:pr-6 focus:outline-none lg:flex-row',
                        ]"
                      >
                        <div class="flex items-center text-sm">
                          <span
                            :class="[
                              checked
                                ? 'bg-primary border-transparent'
                                : 'bg-white border-gray-300',
                              active ? 'ring-2 ring-offset-2 ring-primary' : '',
                              'h-4 w-4 rounded-full border flex items-center justify-center',
                            ]"
                            aria-hidden="true"
                          >
                            <span class="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <RadioGroupLabel
                            as="span"
                            :class="[
                              checked ? 'text-primary' : 'text-gray-900',
                              'ml-3 font-medium',
                            ]"
                            >{{ $t(item.name) }}</RadioGroupLabel
                          >
                        </div>
                      </div>
                    </RadioGroupOption>
                  </div>
                </RadioGroup>
              </div>
              <!-- Блок ниже пока должен быть скрыт -->
              <div class="flex flex-col space-y-2" v-if="false">
                <h3 class="font-medium">
                  {{ $t("lms.create.rating.format.title") }}
                </h3>
                <RadioGroup
                  v-model="newLms[9].format"
                  :disabled="!newLms[9].value"
                >
                  <div class="relative bg-white rounded-md -space-y-px">
                    <RadioGroupOption
                      as="template"
                      value="standart"
                      v-slot="{ checked, active, disabled }"
                    >
                      <div
                        :class="[
                          checked
                            ? 'bg-indigo-50 border-indigo-200 z-10'
                            : 'border-gray-200',
                          disabled ? 'opacity-80' : '',
                          'relative border p-4 flex flex-col cursor-pointer lg:pl-4 lg:pr-6 focus:outline-none lg:flex-row rounded-tl-md rounded-tr-md',
                        ]"
                      >
                        <div class="flex items-center text-sm w-3/5">
                          <span
                            :class="[
                              checked
                                ? 'bg-primary border-transparent'
                                : 'bg-white border-gray-300',
                              active ? 'ring-2 ring-offset-2 ring-primary' : '',
                              'h-4 w-4 rounded-full border flex items-center justify-center',
                            ]"
                            aria-hidden="true"
                          >
                            <span class="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <RadioGroupLabel
                            as="span"
                            :class="[
                              checked ? 'text-primary' : 'text-gray-900',
                              'ml-3 font-medium',
                            ]"
                            >{{
                              $t("lms.create.rating.format.list.standart.title")
                            }}</RadioGroupLabel
                          >
                        </div>
                        <div class="w-1/5">
                          <QuestionMarkCircleIcon
                            class="m-auto h-5 w-5 text-gray-400 hover:text-gray-700"
                            ref="rating_format_standart"
                          />
                          <Hint
                            targetref="rating_format_standart"
                            position="right"
                          >
                            {{
                              $t("lms.create.rating.format.list.standart.hint")
                            }}
                          </Hint>
                        </div>
                        <RadioGroupDescription
                          class="ml-6 pl-1 text-sm lg:ml-0 lg:pl-0 lg:text-center w-1/5"
                        >
                          <span
                            :class="[
                              checked ? 'text-primary' : 'text-gray-900',
                              'font-medium',
                            ]"
                            >{{ newLms[10][getPrice] }}
                            {{ $t(`currency.${selectedCurrency.value}`) }} /
                            {{ $t("lms.create.time_unit") }}</span
                          >
                        </RadioGroupDescription>
                      </div>
                    </RadioGroupOption>
                    <RadioGroupOption
                      as="template"
                      value="custom"
                      v-slot="{ checked, active, disabled }"
                    >
                      <div
                        :class="[
                          checked
                            ? 'bg-indigo-50 border-indigo-200 z-10'
                            : 'border-gray-200',
                          disabled ? 'opacity-80' : '',
                          'relative border p-4 flex flex-col cursor-pointer lg:pl-4 lg:pr-6 focus:outline-none lg:flex-row rounded-bl-md rounded-br-md',
                        ]"
                      >
                        <div class="flex items-center text-sm w-3/5">
                          <span
                            :class="[
                              checked
                                ? 'bg-primary border-transparent'
                                : 'bg-white border-gray-300',
                              active ? 'ring-2 ring-offset-2 ring-primary' : '',
                              'h-4 w-4 rounded-full border flex items-center justify-center',
                            ]"
                            aria-hidden="true"
                          >
                            <span class="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <RadioGroupLabel
                            as="span"
                            :class="[
                              checked ? 'text-primary' : 'text-gray-900',
                              'ml-3 font-medium',
                            ]"
                            >{{
                              $t("lms.create.rating.format.list.custom.title")
                            }}</RadioGroupLabel
                          >
                        </div>
                        <div class="w-1/5">
                          <QuestionMarkCircleIcon
                            class="m-auto h-5 w-5 text-gray-400 hover:text-gray-700"
                            ref="rating_format_custom"
                          />
                          <Hint
                            targetref="rating_format_custom"
                            position="right"
                          >
                            {{
                              $t("lms.create.rating.format.list.custom.title")
                            }}
                          </Hint>
                        </div>
                        <RadioGroupDescription
                          class="ml-6 pl-1 text-sm lg:ml-0 lg:pl-0 lg:text-center w-1/5"
                        >
                          <span
                            :class="[
                              checked ? 'text-primary' : 'text-gray-900',
                              'font-medium',
                            ]"
                          >
                            {{ newLms[11][getPrice] }}
                            {{ $t(`currency.${selectedCurrency.value}`) }} /
                            {{ $t("lms.create.time_unit") }}</span
                          >
                        </RadioGroupDescription>
                      </div>
                    </RadioGroupOption>
                  </div>
                </RadioGroup>
              </div>
            </fieldset>
          </Inlay>
        </div>
        <div
          class="relative border p-4 flex flex-col lg:pl-4 lg:pr-6 focus:outline-none rounded-md bg-white"
        >
          <div class="items-center flex">
            <label class="flex items-center space-x-1 cursor-pointer">
              <Checkbox v-model="newLms[13].value" />
              <span>{{ $t("lms.create.message.title") }}</span>
              <QuestionMarkCircleIcon
                class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                ref="message"
              />
              <Hint targetref="message" position="top">
                {{ $t("lms.create.message.hint") }}
              </Hint>
            </label>
            <button
              type="button"
              @click="newLms[13].childActive = !newLms[13].childActive"
              class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
            >
              <component
                :is="arrowComponent(newLms[13].childActive)"
                class="h-6 w-6 text-white"
              />
            </button>
            <div class="ml-auto pl-1 text-sm flex flex-col text-center">
              <span v-if="newLms[13].type === 'once'">{{
                $t("lms.create.one_time")
              }}</span>
              <span class="font-medium"
                >{{ newLms[13][getPrice] || 0 }}
                {{ $t(`currency.${selectedCurrency.value}`)
                }}<span v-if="newLms[13].type === 'month'">
                  / {{ $t("lms.create.time_unit") }}</span
                ></span
              >
            </div>
          </div>
          <Inlay :open="newLms[13].childActive" class="ml-5 mt-2 px-3 py-1.5">
            {{ $t("lms.create.message.text") }}
          </Inlay>
        </div>
        <div
          class="relative border p-4 flex flex-col lg:pl-4 lg:pr-6 focus:outline-none rounded-md bg-white"
        >
          <div class="items-center flex">
            <label class="flex items-center space-x-1 cursor-pointer">
              <Checkbox v-model="newLms[14].value" />
              <span>{{ $t("lms.create.payment.title") }}</span>
              <QuestionMarkCircleIcon
                class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                ref="payment"
              />
              <Hint targetref="payment" position="top">
                {{ $t("lms.create.payment.hint") }}
              </Hint>
            </label>
            <div class="ml-auto pl-1 text-sm flex flex-col text-center">
              <span v-if="newLms[14].type === 'once'">{{
                $t("lms.create.one_time")
              }}</span>
              <span class="font-medium"
                >{{ newLms[14][getPrice] || 0 }}
                {{ $t(`currency.${selectedCurrency.value}`)
                }}<span v-if="newLms[14].type === 'month'">
                  / {{ $t("lms.create.time_unit") }}</span
                ></span
              >
            </div>
          </div>
        </div>
        <div class="flex space-x-5">
          <Select
            v-model="newLms[15].selected"
            :options="newLms[15].child"
            :label="$t('lms.create.duration.title')"
            :onChange="setPeriod($t(newLms[15].selected.text))"
            class="w-1/2"
          />
          <Select
            v-model="selectedInstallments"
            :options="installmentsOptions"
            :label="$t('lms.payment.options')"
            class="w-1/2"
          />
        </div>
        <Select
          v-if="companies.length"
          v-model="selectedCompany"
          :options="companies"
          :label="$t('lms.company.select')"
          class="select-company"
          text="name"
          value="id"
        />
        <CreateCompany v-else />
        <div>
          <button
            @click="addNew"
            :disabled="disabledButtonConditions"
            :class="
              disabledButtonConditions
                ? 'opacity-50 cursor-not-allowed disabled:opacity-50'
                : 'hover:bg-primary-light'
            "
            type="button"
            class="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            {{ $t("lms.create.create") }}
          </button>
        </div>
      </Form>
    </div>

    <div class="lg:w-1/3">
      <div
        class="shadow-md bg-white rounded-md px-3 py-4 text-center space-y-2"
      >
        <p>{{ $t("lms.create.add_in_system") }}</p>
        <div class="" v-for="item in newLms" :key="item.name">
          <template v-if="item.value && item.name === 'transfer_students'">
            <div class="flex justify-between">
              <span class="font-medium text-left">
                {{ $t("lms.create.transfer_base") }}
              </span>
              <div class="text-right">
                <div v-if="item.type === 'once'" class="w-full">
                  {{ $t(item.type) }}
                </div>
                <span>
                  {{ item[getPrice] }}
                  {{ $t(`currency.${selectedCurrency.value}`)
                  }}<span v-if="item.type !== 'once'">
                    / {{ $t(item.type) }}</span
                  >
                </span>
              </div>
            </div>
          </template>
          <template v-if="item.value && item.name === 'base'">
            <div class="flex justify-between">
              <span class="font-medium text-left">
                {{ $t("lms.create.base.title") }}
              </span>
              <div class="text-right">
                <div v-if="item.type === 'once'" class="w-full">
                  {{ $t(item.type) }}
                </div>
                <span>
                  {{ item[getPrice] * parseInt(this.newLms[15].value) }}
                  {{ $t(`currency.${selectedCurrency.value}`)
                  }}<span v-if="item.type !== 'once'">
                    / {{ formatMonth($t(`${newLms[15].selected.text}`)) }}</span
                  >
                </span>
              </div>
            </div>
          </template>
          <template v-if="item.value && item.name === 'networking'">
            <div class="flex justify-between">
              <span class="font-medium text-left">
                {{ $t("lms.create.networking.title") }}
              </span>
              <div class="right">
                <div v-if="item.type === 'once'" class="w-full">
                  {{ $t(item.type) }}
                </div>
                <span>
                  {{ item[getPrice] * parseInt(this.newLms[15].value) }}
                  {{ $t(`currency.${selectedCurrency.value}`)
                  }}<span v-if="item.type !== 'once'">
                    / {{ formatMonth($t(`${newLms[15].selected.text}`)) }}</span
                  >
                </span>
              </div>
            </div>
            <div class="flex justify-between">
              <template v-if="item.child[0].value === 'local'">
                <span class="font-medium text-left">
                  &bullet; {{ $t("lms.create.networking.type.local.headline") }}
                </span>
                <div class="text-right">
                  <div v-if="item.type === 'once'" class="w-full">
                    {{ $t(item.type) }}
                  </div>
                  <span>
                    {{
                      item.child[0].type[0][getPrice] *
                      parseInt(this.newLms[15].value)
                    }}
                    {{ $t(`currency.${selectedCurrency.value}`)
                    }}<span v-if="item.type !== 'once'">
                      /
                      {{ formatMonth($t(`${newLms[15].selected.text}`)) }}</span
                    >
                  </span>
                </div>
              </template>
              <template v-if="item.child[0].value === 'global'">
                <span class="font-medium text-left">
                  &bullet;
                  {{ $t("lms.create.networking.type.global.headline") }}
                </span>
                <div class="text-right">
                  <div v-if="item.type === 'once'" class="w-full">
                    {{ $t(item.type) }}
                  </div>
                  <span>
                    {{
                      item.child[0].type[1][getPrice] *
                      parseInt(this.newLms[15].value)
                    }}
                    {{ $t(`currency.${selectedCurrency.value}`)
                    }}<span v-if="item.type !== 'once'">
                      /
                      {{ formatMonth($t(`${newLms[15].selected.text}`)) }}</span
                    >
                  </span>
                </div>
              </template>
            </div>
            <div class="flex items-center">
              <span class="font-medium text-left"
                >&bullet; {{ $t("lms.create.networking.base.headline") }}</span
              >
            </div>
            <div
              class="flex justify-between"
              v-if="newLms[5].selected.length > 0"
            >
              <span class="font-medium text-left"
                >&bullet;
                {{ $t("lms.create.networking.additional_fields.title") }}
                {{ newLms[5].selected.length }}
                {{ $t("lms.create.piece") }}</span
              >
              <div class="text-right">
                <div v-if="item.type === 'once'" class="w-full">
                  {{ $t(item.type) }}
                </div>
                <span
                  >{{
                    newLms[5].selected.length *
                    newLms[5][this.getPrice] *
                    parseInt(this.newLms[15].value)
                  }}
                  {{ $t(`currency.${selectedCurrency.value}`)
                  }}<span v-if="item.type !== 'once'">
                    / {{ formatMonth($t(`${newLms[15].selected.text}`)) }}</span
                  >
                </span>
              </div>
            </div>
          </template>
          <template v-if="item.value && item.name === 'lifelong_learning'">
            <div class="flex justify-between">
              <span class="font-medium text-left">
                {{ $t("lms.create.lifelong_learning.title") }}
              </span>
              <div class="text-right">
                <div v-if="item.type === 'once'" class="w-full">
                  {{ $t(item.type) }}
                </div>
                <span>
                  {{ item[getPrice] }}
                  {{ $t(`currency.${selectedCurrency.value}`)
                  }}<span v-if="item.type !== 'once'">
                    / {{ formatMonth($t(`${newLms[15].selected.text}`)) }}</span
                  >
                </span>
              </div>
            </div>
            <div class="flex justify-between" v-if="newLms[8].count > 0">
              <span class="font-medium text-left">
                &bullet; {{ $t("lms.create.lifelong_learning.programme") }}
                {{ newLms[8].count }}
                {{ $t("lms.create.piece") }}
              </span>
              <div class="text-right">
                <div v-if="item.type === 'once'" class="w-full">
                  {{ $t(item.type) }}
                </div>
                <span>
                  {{
                    newLms[8].count *
                    newLms[8][getPrice] *
                    parseInt(this.newLms[15].value)
                  }}
                  {{ $t(`currency.${selectedCurrency.value}`)
                  }}<span>
                    / {{ formatMonth($t(`${newLms[15].selected.text}`)) }}</span
                  >
                </span>
              </div>
            </div>
          </template>
          <template v-if="item.value && item.name === 'rating'">
            <div class="flex justify-between">
              <span class="font-medium text-left">
                {{ $t("lms.create.rating.title") }}
              </span>
              <div class="text-right">
                <div v-if="item.type === 'once'" class="w-full">
                  {{ $t(item.type) }}
                </div>
                <span>
                  {{ item[getPrice] * parseInt(this.newLms[15].value) }}
                  {{ $t(`currency.${selectedCurrency.value}`)
                  }}<span v-if="item.type !== 'once'">
                    / {{ formatMonth($t(`${newLms[15].selected.text}`)) }}</span
                  >
                </span>
              </div>
            </div>
            <div class="flex justify-between" v-if="item.format === 'standart'">
              <span class="font-medium text-left">
                &bullet;
                {{ $t("lms.create.rating.format.list.standart.headline") }}
              </span>
              <div class="text-right">
                <div v-if="item.type === 'once'" class="w-full">
                  {{ $t(item.type) }}
                </div>
                <span>
                  {{ newLms[10][getPrice] * parseInt(this.newLms[15].value) }}
                  {{ $t(`currency.${selectedCurrency.value}`)
                  }}<span v-if="item.type !== 'once'">
                    / {{ formatMonth($t(`${newLms[15].selected.text}`)) }}</span
                  >
                </span>
              </div>
            </div>
            <div class="flex justify-between" v-if="item.format === 'custom'">
              <span class="font-medium">
                &bullet;
                {{ $t("lms.create.rating.format.list.custom.headline") }}
              </span>
              <div class="text-right">
                <div v-if="item.type === 'once'" class="w-full">
                  {{ $t(item.type) }}
                </div>
                <span>
                  {{ newLms[11][getPrice] * parseInt(this.newLms[15].value) }}
                  {{ $t(`currency.${selectedCurrency.value}`)
                  }}<span v-if="item.type !== 'once'">
                    / {{ formatMonth($t(`${newLms[15].selected.text}`)) }}</span
                  >
                </span>
              </div>
            </div>
            <div class="flex justify-between" v-if="newLms[12].value === 100">
              <span class="font-medium">
                &bullet;
                {{ $t("lms.create.rating.view.list.100") }}
              </span>
            </div>
            <div class="flex justify-between" v-if="newLms[12].value === 10">
              <span class="font-medium">
                &bullet;
                {{ $t("lms.create.rating.view.list.10") }}
              </span>
            </div>
            <div class="flex justify-between" v-if="newLms[12].value === 5">
              <span class="font-medium">
                &bullet;
                {{ $t("lms.create.rating.view.list.5") }}
              </span>
            </div>
          </template>
          <template v-if="item.value && item.name === 'message'">
            <div class="flex justify-between">
              <span class="font-medium text-left">
                {{ $t("lms.create.message.title") }}
              </span>
              <div class="text-right">
                <div v-if="item.type === 'once'" class="w-full">
                  {{ $t(item.type) }}
                </div>
                <span>
                  {{ item[getPrice] * parseInt(this.newLms[15].value) }}
                  {{ $t(`currency.${selectedCurrency.value}`)
                  }}<span v-if="item.type !== 'once'">
                    / {{ formatMonth($t(`${newLms[15].selected.text}`)) }}</span
                  >
                </span>
              </div>
            </div>
          </template>
          <template v-if="item.value && item.name === 'payment'">
            <div class="flex justify-between">
              <span class="font-medium text-left">
                {{ $t("lms.create.payment.title") }}
              </span>
              <div class="text-right">
                <div v-if="item.type === 'once'" class="w-full">
                  {{ $t(item.type) }}
                </div>
                <span>
                  {{ item[getPrice] * parseInt(this.newLms[15].value) }}
                  {{ $t(`currency.${selectedCurrency.value}`)
                  }}<span v-if="item.type !== 'once'">
                    / {{ formatMonth($t(`${newLms[15].selected.text}`)) }}</span
                  >
                </span>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="shadow-md bg-white rounded-md px-3 py-4 text-center mt-3">
        <p>{{ $t("lms.create.summary") }}</p>
        <template v-if="monthElmenets.length">
          <p>
            {{ summOfMonth }} {{ $t(`currency.${selectedCurrency.value}`) }} /
            {{ $t("lms.create.time_unit").slice(0, -1) }}.
          </p>
        </template>
        <template v-if="onceElmenets.length">
          <p v-if="monthElmenets.length">+</p>
          <p>
            {{ summOfOnce }} {{ $t(`currency.${selectedCurrency.value}`) }}
            {{ $t("lms.create.one_time").toLowerCase() }}
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from "@vueform/slider";
import { onMounted, reactive, computed, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  ListboxLabel,
} from "@headlessui/vue";
import Checkbox from "@/components/Checkbox";
import Inlay from "@/components/Inlay";
import Hint from "@/components/Hint";
import Radio from "@/components/Radio";
import PasswordInput from "@/components/PasswordInput/PasswordInput";
import CreateCompany from "./CreateCompany.vue";
import {
  QuestionMarkCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PlusIcon,
  MinusIcon,
  CheckIcon,
  XIcon,
  SelectorIcon,
  PlusSmIcon,
  MinusSmIcon,
} from "@heroicons/vue/solid";
// import PrefixInput from "./PrefixInput.vue";
import { Loader, Form, Input, Select } from "@/UI";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";

const tabs = [
  { name: "lms.create.networking.preview.general" },
  { name: "lms.create.networking.preview.education" },
  { name: "lms.create.networking.preview.additional" },
];
const table = [
  {
    know: "lms.create.lifelong.preview.knowledge",
    module: "lms.create.lifelong.preview.module",
    skill: "lms.create.lifelong.preview.skill",
    need: 100,
  },
  {
    know: "lms.create.lifelong.preview.knowledge",
    module: "lms.create.lifelong.preview.module",
    skill: "lms.create.lifelong.preview.skill",
    need: 90,
  },
  {
    know: "lms.create.lifelong.preview.knowledge",
    module: "lms.create.lifelong.preview.module",
    skill: "lms.create.lifelong.preview.skill",
    need: 80,
  },
  {
    know: "lms.create.lifelong.preview.knowledge",
    module: "lms.create.lifelong.preview.module",
    skill: "lms.create.lifelong.preview.skill",
    need: 70,
  },
  {
    know: "lms.create.lifelong.preview.knowledge",
    module: "lms.create.lifelong.preview.module",
    skill: "lms.create.lifelong.preview.skill",
    need: 60,
  },
  {
    know: "lms.create.lifelong.preview.knowledge",
    module: "lms.create.lifelong.preview.module",
    skill: "lms.create.lifelong.preview.skill",
    need: 50,
  },
  {
    know: "lms.create.lifelong.preview.knowledge",
    module: "lms.create.lifelong.preview.module",
    skill: "lms.create.lifelong.preview.skill",
    need: 40,
  },
  {
    know: "lms.create.lifelong.preview.knowledge",
    module: "lms.create.lifelong.preview.module",
    skill: "lms.create.lifelong.preview.skill",
    need: 30,
  },
  {
    know: "lms.create.lifelong.preview.knowledge",
    module: "lms.create.lifelong.preview.module",
    skill: "lms.create.lifelong.preview.skill",
    need: 20,
  },
  {
    know: "lms.create.lifelong.preview.knowledge",
    module: "lms.create.lifelong.preview.module",
    skill: "lms.create.lifelong.preview.skill",
    need: 10,
  },
];

export default {
  components: {
    Checkbox,
    Hint,
    QuestionMarkCircleIcon,
    Inlay,
    ChevronDownIcon,
    Radio,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    PlusIcon,
    MinusIcon,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    ListboxLabel,
    CheckIcon,
    XIcon,
    PasswordInput,
    SelectorIcon,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    PlusSmIcon,
    MinusSmIcon,
    Slider,
    Loader,
    Form,
    Input,
    Select,
    CreateCompany,
    // PrefixInput,
  },
  watch: {
    "$i18n.locale"(value) {
      this.newLms.forEach((key) => {
        if (key.name === "additional_fields") {
          key.child = this.networking;
          key.child = key.child.map((item) => ({
            value: item.key,
            text: value === "ru" ? item.name : item.name_en,
            values: item.values,
          }));
        }
      });
    },
  },
  setup() {
    const { locale } = useI18n();
    const scoreProgram = ref(30);
    const loading = ref(true);
    const loadingDomain = ref(false);
    const viewCheckDomain = ref(false);
    const name = ref("");
    const prefix = ref("");
    const password = ref("");
    const scoreExam = ref(7);
    const store = useStore();
    const price = computed(() => store.getters.getAllPrice);
    const networking = computed(() => store.getters.getAllNetworking);
    const domainValid = computed(() => store.getters.getValidDomain);
    const user = computed(() => store.getters.getProfile);
    const email = ref(user.value.email);
    const student = (list) => ({
      title: `lms.create.base.list.student.${list[0]}.title`,
      hint: `lms.create.base.list.student.${list[0]}.hint`,
    });
    const admin = (list) => ({
      title: `lms.create.base.list.admin.${list[0]}.title`,
      hint: `lms.create.base.list.admin.${list[0]}.hint`,
    });
    const newLms = reactive([
      {
        name: "transfer_students",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
      },
      {
        name: "base",
        value: true,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: "month",
        childActive: false,
        child: [
          student`lk`,
          student`programs`,
          student`scores`,
          student`news`,
          student`docs`,
          student`calendar`,
          admin`programs`,
          admin`students`,
          admin`projects`,
          admin`file-manager`,
          admin`prices`,
          admin`docs`,
          admin`reports`,
          admin`tests`,
          admin`settings`,
        ],
      },
      {
        name: "networking",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
        childActive: false,
        child: [
          {
            name: "networking_type",
            value: "",
            type: [
              {
                name: "local",
                price: null,
                price_usd: null,
                price_euro: null,
                title: "lms.create.networking.type.local.title",
                hint: "lms.create.networking.type.local.hint",
              },
              {
                name: "global",
                price: null,
                price_usd: null,
                price_euro: null,
                title: "lms.create.networking.type.global.title",
                hint: "lms.create.networking.type.global.hint",
              },
            ],
          },
          {
            name: "networking_base",
            active: false,
            fields: [
              "lms.create.networking.base.surname",
              "lms.create.networking.base.name",
              "lms.create.networking.base.country",
              "lms.create.networking.base.city",
              "lms.create.networking.base.age",
              "lms.create.networking.base.hobby",
              "lms.create.networking.base.interests",
            ],
          },
        ],
      },
      {
        name: "networking_local",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
      },
      {
        name: "networking_global",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
      },
      {
        name: "additional_fields",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
        selected: [],
        child: [],
        childActive: false,
      },
      {
        name: "preview",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
        childActive: false,
      },
      {
        name: "lifelong_learning",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
        childActive: false,
      },
      {
        name: "lifelong_learning_count",
        count: 0,
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
        childActive: false,
      },
      {
        name: "rating",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
        childActive: false,
        format: null,
      },
      {
        name: "rating_standard",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
      },
      {
        name: "rating_custom",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
      },
      {
        name: "rating_type",
        value: null,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
        child: [
          {
            name: "lms.create.rating.view.list.100",
            value: 100,
          },
          {
            name: "lms.create.rating.view.list.10",
            value: 10,
          },
          {
            name: "lms.create.rating.view.list.5",
            value: 5,
          },
        ],
      },
      {
        name: "message",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
      },
      {
        name: "payment",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
      },
      {
        name: "duration_contract",
        value: null,
        child: [
          {
            text: "lms.create.duration.1",
            value: 1,
          },
          {
            text: "lms.create.duration.3",
            value: 3,
          },
          {
            text: "lms.create.duration.6",
            value: 6,
          },
          {
            text: "lms.create.duration.9",
            value: 9,
          },
          {
            text: "lms.create.duration.12",
            value: 12,
          },
        ],
      },
    ]);
    const currencyOptions = [
      {
        text: "currency.rub",
        value: "rub",
      },
      {
        text: "currency.usd",
        value: "usd",
      },
      {
        text: "currency.euro",
        value: "euro",
      },
    ];
    const selectedCurrency = ref(currencyOptions[0]);
    const selectedInstallments = ref(null);
    const installmentsOptions = computed(() => {
      const value = newLms[15].value;

      return [
        ...(value >= 1
          ? [
              {
                value: 0,
                text: "lms.payment.prepayment",
              },
            ]
          : []),
        ...(value >= 3
          ? [
              {
                value: 3,
                text: "lms.payment.installment_3",
              },
            ]
          : []),
        ...(value >= 6
          ? [
              {
                value: 6,
                text: "lms.payment.installment_6",
              },
            ]
          : []),
        ...(value >= 9
          ? [
              {
                value: 9,
                text: "lms.payment.installment_9",
              },
            ]
          : []),
        ...(value >= 12
          ? [
              {
                value: 12,
                text: "lms.payment.installment_12",
              },
            ]
          : []),
      ];
    });
    const selectedCompany = ref(null);
    const companies = computed(() => store.getters.getCompanies);

    const format = function (value) {
      return `${value}%`;
    };
    onMounted(async () => {
      await store.dispatch("fetchAllNetworking");
      await store.dispatch("fetchCompanies");
      await store.dispatch("fetchAllPrice", "perPage=-1");
      newLms.forEach((key, i) => {
        price.value.forEach((item) => {
          if (item.module === key.name) {
            if (item.price) newLms[i].price = item.price;
            if (item.price_usd) newLms[i].price_usd = item.price_usd;
            if (item.price_euro) newLms[i].price_euro = item.price_euro;
            if (item.type) newLms[i].type = item.type;
            if (item.parent_module)
              newLms[i].parent_module = price.value[item.id - 1].module;
          }
          if (item.module === "networking_local") {
            newLms[2].child[0].type[0].price = item.price;
            newLms[2].child[0].type[0].price_usd = item.price_usd;
            newLms[2].child[0].type[0].price_euro = item.price_euro;
            newLms[2].child[0].type[0].type = item.type;
          }
          if (item.module === "networking_global") {
            newLms[2].child[0].type[1].price = item.price;
            newLms[2].child[0].type[1].price_usd = item.price_usd;
            newLms[2].child[0].type[1].price_euro = item.price_euro;
            newLms[2].child[0].type[1].type = item.type;
          }
          if (item.module === "networking_additional_fields") {
            newLms[5].price = item.price;
            newLms[5].price_usd = item.price_usd;
            newLms[5].price_euro = item.price_euro;
          }
        });
        if (key.name === "additional_fields") {
          key.child = networking.value;
          key.child = key.child.map((item) => ({
            value: item.key,
            text: locale._value === "ru" ? item.name : item.name_en,
            values: item.values,
          }));
        }
        newLms[15].selected = {
          text: "lms.create.duration.1",
          value: "1",
        };
      });
      loading.value = false;
    });

    return {
      newLms,
      price,
      networking,
      tabs,
      scoreProgram,
      scoreExam,
      format,
      table,
      prefix,
      name,
      email,
      password,
      store,
      loading,
      domainValid,
      loadingDomain,
      viewCheckDomain,
      selectedCurrency,
      currencyOptions,
      selectedInstallments,
      installmentsOptions,
      companies,
      selectedCompany,
    };
  },
  methods: {
    arrowComponent(value) {
      return value ? ChevronUpIcon : ChevronDownIcon;
    },
    changeNetworkingStatus(value) {
      const networkingType = this.newLms[2].child[0];

      value &&
        !networkingType.value &&
        (networkingType.value = networkingType.type[0].name);
    },
    addAdditionalField() {
      const isNetworkingEnabled = this.newLms[2].value;
      const isAdditionalFieldsEnabled = this.newLms[5].value;

      isNetworkingEnabled &&
        isAdditionalFieldsEnabled &&
        this.newLms[5].selected.push({
          value: null,
          text: null,
        });
    },
    deleteAdditional(index) {
      this.newLms[5].selected.splice(index, 1);
    },
    changeAdditional(value, index) {
      this.newLms[5].selected[index].value = value;
    },
    PlusLifeCount() {
      this.newLms[8].count++;
    },
    MinusLifeCount() {
      if (this.newLms[8].count > 0) this.newLms[8].count--;
    },
    async checkDomain() {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(async () => {
        this.viewCheckDomain = true;
        this.loadingDomain = true;
        await this.store.dispatch("checkDomain", this.prefix);
        this.loadingDomain = false;
      }, 800);
    },
    async addNew() {
      this.loading = true;
      try {
        const getLifelongLearningCount = () => {
          if (!this.newLms[7].value) return false;

          const count = this.newLms[8].count;
          const moreThanZero = count > 0;
          const isCount = !!count;
          const isNotNan = !isNaN(count);
          const conditions = moreThanZero && isCount && isNotNan;

          if (conditions)
            return {
              count,
            };

          return { count: 0 };
        };

        const {
          data: { id },
        } = await this.store.dispatch("addNewLms", {
          ...(this.name !== "" && {
            name: this.name,
          }),
          ...(this.prefix !== "" && {
            domain: this.prefix,
          }),
          ...(this.email !== "" && {
            email: this.email,
          }),
          ...(this.password !== "" && {
            password: this.password,
          }),
          ...(this.newLms[15].selected && {
            duration_contract: this.newLms[15].selected.value,
          }),
          ...(this.newLms[0].value && {
            transfer_students: this.newLms[0].value,
          }),
          ...(this.newLms[2].value && {
            networking: {
              enabled: this.newLms[2].value,
              ...(this.newLms[2].child[0].value !== "" && {
                type: this.newLms[2].child[0].value,
              }),
              ...(this.newLms[5].value &&
                this.newLms[5].selected[0] && {
                  additional_fields: {
                    items: this.newLms[5].selected,
                    enabled: this.newLms[5].value,
                  },
                }),
            },
          }),
          ...(this.newLms[7].value && {
            lifelong_learning: {
              enabled: this.newLms[7].value,
              ...getLifelongLearningCount(),
            },
          }),
          ...(this.newLms[9].value && {
            rating: {
              enabled: this.newLms[9].value,
              ...(this.newLms[12].value && {
                view: this.newLms[12].value,
              }),
              ...(this.newLms[9].format && {
                format: this.newLms[9].format,
              }),
            },
          }),
          ...(this.newLms[13].value && {
            message: this.newLms[13].value,
          }),
          ...(this.newLms[14].value && {
            payment: this.newLms[14].value,
          }),
          ...(this.selectedInstallments?.value != null && {
            installments: this.selectedInstallments.value,
          }),
          ...(this.selectedCurrency?.value != null && {
            currency: this.selectedCurrency.value,
          }),
          ...(this.selectedCompany?.id != null && {
            company_id: this.selectedCompany.id,
          }),
        });

        this.$router.push(`/lms/${id}/payment`);
      } catch (error) {
        this.loading = false;
      }
    },
    setPeriod(val) {
      switch (val) {
        case "1 year":
        case "1 год": {
          this.newLms[15].value = 12;
          break;
        }
        case "3 months":
        case "3 месяца": {
          this.newLms[15].value = 3;
          break;
        }
        case "6 months":
        case "6 месяцев": {
          this.newLms[15].value = 6;
          break;
        }
        case "9 months":
        case "9 месяцев": {
          this.newLms[15].value = 9;
          break;
        }
        default:
          this.newLms[15].value = 1;
      }

      const { value } = this.newLms[15];

      if (this.selectedInstallments?.value <= value) return;

      const found = this.installmentsOptions.find((obj) => {
        return obj.value === value;
      });

      this.selectedInstallments = found;
    },
    formatMonth(date) {
      if (date.indexOf("месяца") > -1) {
        return date.split("месяца").join("мес.");
      }
      if (date.indexOf("месяцев") > -1) {
        return date.split("месяцев").join("мес.");
      }
      if (date.indexOf("месяц") > -1) {
        return date.split("месяц").join("мес.");
      }
      return " год.";
    },
    isDisabled(value) {
      const length = this.newLms[5].selected.filter(
        (element) => element.value === value
      ).length;

      return length === 1;
    },
  },
  computed: {
    monthElmenets() {
      return this.newLms.filter((item) => {
        return item.type === "month" && item.value === true;
      });
    },
    // eslint-disable-next-line vue/return-in-computed-property
    summOfMonth() {
      let summ = 0;
      summ += this.monthElmenets.reduce((acc, curr) => {
        return acc + curr[this.getPrice] * parseInt(this.newLms[15].value);
      }, 0);
      if (this.newLms[2].child[0].value === "local" && this.newLms[2].value) {
        summ +=
          this.newLms[2].child[0].type[0][this.getPrice] *
          parseInt(this.newLms[15].value);
      }
      if (this.newLms[2].child[0].value === "global" && this.newLms[2].value) {
        summ +=
          this.newLms[2].child[0].type[1][this.getPrice] *
          parseInt(this.newLms[15].value);
      }
      if (this.newLms[5].selected.length && this.newLms[5].value) {
        summ +=
          this.newLms[5].selected.length *
          this.newLms[5][this.getPrice] *
          parseInt(this.newLms[15].value);
      }
      if (this.newLms[7].value && this.newLms[8].count > 0) {
        summ +=
          this.newLms[8].count *
          this.newLms[8][this.getPrice] *
          parseInt(this.newLms[15].value);
      }
      if (this.newLms[9].value) {
        if (this.newLms[9].format === "standart") {
          summ +=
            this.newLms[10][this.getPrice] * parseInt(this.newLms[15].value);
        }
        if (this.newLms[9].format === "custom") {
          summ +=
            this.newLms[11][this.getPrice] * parseInt(this.newLms[15].value);
        }
      }
      return summ;
    },
    onceElmenets() {
      return this.newLms.filter((item) => {
        return item.type === "once" && item.value === true;
      });
    },
    summOfOnce() {
      return this.onceElmenets.reduce((acc, curr) => {
        return acc + curr[this.getPrice];
      }, 0);
    },
    // eslint-disable-next-line vue/return-in-computed-property
    summary() {},
    placerNewLms() {
      return this.$t("lms.create.placeholder");
    },
    disabledButtonConditions() {
      const nameLength = this.name.length;
      const prefixLength = this.prefix.length;
      const isValidDomain = this.domainValid.data === true;
      const emailLength = this.email.length;
      const passwordLength = this.password.length;
      const isInstallmentSelected = this.selectedInstallments?.value != null;
      const isCurrencySelected = this.selectedCurrency?.value != null;
      const isCompanySelected = this.selectedCompany?.id != null;

      const conditions =
        !nameLength ||
        !prefixLength ||
        !isValidDomain ||
        !emailLength ||
        !passwordLength ||
        !isInstallmentSelected ||
        !isCurrencySelected ||
        !isCompanySelected ||
        this.loading;

      return conditions;
    },
    getPrice() {
      switch (this.selectedCurrency.value) {
        case "usd":
          return "price_usd";
        case "euro":
          return "price_euro";
        default:
          return "price";
      }
    },
  },
};
</script>

<style lang="scss">
li[aria-disabled="true"] {
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

#prefix {
  width: 150px;
}

.select-company {
  width: calc(50% - 10px);
}
</style>

<template>
  <div v-if="data" class="paginator flex justify-center items-center mt-5">
    <button
      class="paginator__paginator"
      @click="pageNumber--"
      :disabled="pageNumber <= 1"
    >
      <ChevronLeftIcon
        :class="`w-8 h-8 text-gray-${pageNumber <= 1 ? '4' : '7'}00`"
      />
    </button>

    <span>{{ pageNumber }} | {{ pageCount }}</span>

    <button
      class="paginator__paginator"
      @click="pageNumber++"
      :disabled="pageNumber >= pageCount"
    >
      <ChevronRightIcon
        :class="`w-8 h-8 text-gray-${pageNumber >= pageCount ? '4' : '7'}00`"
      />
    </button>
  </div>
</template>

<script setup>
const props = defineProps({
  data: {
    required: true,
    type: Object,
  },
});
const emits = defineEmits(["onChangePage"]);

const size = ref(props.data?.per_page);
const pageNumber = ref(props.data?.current_page);
const pageCount = ref(props.data?.last_page);

// eslint-disable-next-line
function load() {
  size.value = props.data?.per_page;
  pageNumber.value = props.data?.current_page;
  pageCount.value = props.data?.last_page;
}

watch(pageNumber, (value) => {
  emits("onChangePage", value);
});
</script>

<script>
import { ref, defineProps, watch, defineEmits } from "vue";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/solid";

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  created() {
    this.load();
  },
};
</script>

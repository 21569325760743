<template>
  <div :class="cls">
    <input
      :id="id"
      v-model="checkboxChecked"
      type="checkbox"
      :class="$style.Checkbox__input"
      :disabled="disabled"
      :name="name"
      :value="value"
      :checked="$attrs.modelValue"
      autocomplete="off"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
export const SIZES = ["s", "m", "l"];

export default {
  name: "Checkbox",
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    value: {
      default: null,
    },
    size: {
      type: String,
      default: SIZES[1],
      validator(value) {
        return SIZES.indexOf(value) !== -1;
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkboxChecked: {
      get() {
        return this.$attrs.modelValue;
      },
      set(val) {
        this.$emit("change", val);
        this.$emit("changed", val);
      },
    },
    cls() {
      return {
        [this.$style.Checkbox]: true,
        [this.$style[`Checkbox_size_${this.size}`]]: true,
        [this.$style.Checkbox_checked]: !!this.checkboxChecked,
        [this.$style.Checkbox_error]: this.error && !this.checkboxChecked,
        [this.$style.Checkbox_disabled]: this.disabled,
      };
    },
  },
};
</script>

<style module lang="scss">
@import "@/lib/variables";
$checkboxSizes: (s 16px 4px), (m 20px 5px), (l 24px 6px);
.Checkbox {
  position: relative;
  display: inline-block;
  cursor: pointer;
  vertical-align: bottom;
  width: 20px;
  height: 20px;
  transition: background-color 0.1s ease-out;
  border-radius: 5px;
  border-width: 1px;
  @apply border-gray-300 bg-white hover:bg-gray-100;

  &:active {
    @apply bg-gray-200;
  }
}

.Checkbox__input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: inherit;
  height: inherit;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: pointer;
  overflow: hidden;
}

@each $name, $size, $bRadius in $checkboxSizes {
  .Checkbox_size_#{$name} {
    width: $size;
    height: $size;
    border-radius: $bRadius;
  }
}

.Checkbox.Checkbox_error {
  background-color: $xLightRed;
}

.Checkbox.Checkbox_disabled {
  background-color: $lightGrey;
  cursor: default;

  & > .Checkbox__input {
    cursor: default;
  }
}

.Checkbox.Checkbox_checked {
  @apply border-transparent;
  background-color: $primary;

  &::before {
    content: "";
    position: absolute;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none' stroke='white'%3E%3Cpath d='M2.66669 6.96289L6.5778 11.111L13.2445 4.44446' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
      no-repeat 50% 50% / 16px 16px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  &:hover {
    background-color: $primary-light;
  }

  &:active {
    background-color: $primary-light;
  }
}

.Checkbox.Checkbox_checked.Checkbox_disabled {
  background-color: $primary;
  opacity: 0.5;
}
</style>

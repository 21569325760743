<template>
  <Loader v-if="!currentNews" />
  <form class="py-4" v-else>
    <fieldset class="space-y-5">
      <div>
        <div class="mt-1">
          <label for="title" class="block text-sm font-medium">
            {{ $t("news.title") }}
          </label>
          <input
            type="text"
            name="title"
            id="title"
            class="shadow-sm focus:ring-primary w-2/3 focus:border-primary block sm:text-sm border-gray-300 rounded-md mb-2"
            v-model="currentNews.data.title"
          />

          <label for="title_en" class="block text-sm font-medium">
            {{ $t("news.title_en") }}
          </label>
          <input
            type="text"
            name="title_en"
            id="title_en"
            class="shadow-sm focus:ring-primary w-2/3 focus:border-primary block sm:text-sm border-gray-300 rounded-md mb-2"
            v-model="currentNews.data.title_en"
          />

          <label for="body" class="block text-sm font-medium">
            {{ $t("news.message") }}
          </label>
          <textarea
            type="text"
            name="body"
            id="body"
            class="shadow-sm focus:ring-primary w-2/3 focus:border-primary block sm:text-sm border-gray-300 rounded-md mb-2"
            v-model="currentNews.data.body"
          />

          <label for="body_end" class="block text-sm font-medium">
            {{ $t("news.message_en") }}
          </label>
          <textarea
            type="text"
            name="body_end"
            id="body_end"
            class="shadow-sm focus:ring-primary w-2/3 focus:border-primary block sm:text-sm border-gray-300 rounded-md mb-2"
            v-model="currentNews.data.body_en"
          />
        </div>
      </div>
    </fieldset>

    <button
      type="button"
      @click="onCancel"
      class="inline-flex items-center px-4 py-2 mr-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-900 bg-slate-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-400"
    >
      {{ $t("cancel") }}
    </button>
    <button
      type="button"
      @click="editNews"
      class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary mb-2 mt-2"
      :class="
        isDisabledButton
          ? 'opacity-50 cursor-not-allowed disabled:opacity-50'
          : 'hover:bg-slate-400'
      "
      :disabled="isDisabledButton"
    >
      {{ $t("edit") }}
    </button>
  </form>
</template>

<script>
import { Loader } from "@/UI";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  components: {
    Loader,
  },
  methods: {
    async editNews() {
      this.isLoading = true;
      await this.store.dispatch("updateNews", {
        id: this.id,
        title: this.currentNews.data.title,
        title_en: this.currentNews.data.title_en,
        body: this.currentNews.data.body,
        body_en: this.currentNews.data.body_en,
      });
      this.isLoading = false;
    },
    getNews() {
      console.log(this.currentNews);
    },
    onCancel() {
      this.$router.push(`/news/${this.id}`);
    },
  },
  setup() {
    const store = useStore();
    const router = useRoute();
    const id = router.params.id;
    const currentNews = computed(() => store.getters.getCurrentNews);
    // const title = ref(currentNews.value.title);
    // const body = ref(currentNews.value.body);
    const isLoading = ref(false);

    const isDisabledButton = computed(() => {
      const title = currentNews.value.data.title?.length;
      const title_en = currentNews.value.data.title_en?.length;
      const body = currentNews.value.data.body?.length;
      const body_en = currentNews.value.data.body_en?.length;

      const titles = title && title_en;
      const bodies = body && body_en;
      const condition = !(titles && bodies) || isLoading.value;

      return condition;
    });

    return {
      id,
      currentNews,
      store,
      isLoading,
      isDisabledButton,
    };
  },
  mounted() {
    console.log(this.currentNews);
  },
  async created() {
    await this.store.dispatch("fetchNewsById", this.id);
  },
};
</script>

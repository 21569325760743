export default {
  xs: {
    viewBox: "0 0 12 12",
    circlePath:
      "M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6 10.5C8.48528 10.5 10.5 8.48528 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5C3.51472 1.5 1.5 3.51472 1.5 6C1.5 8.48528 3.51472 10.5 6 10.5Z",
    sectorPath:
      "M12 6C12 2.68629 9.31371 0 6 0V1.5C8.48528 1.5 10.5 3.51472 10.5 6H12Z",
  },
  s: {
    viewBox: "0 0 16 16",
    circlePath:
      "M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z",
    sectorPath:
      "M16 8C16 3.58172 12.4183 0 8 0V2C11.3137 2 14 4.68629 14 8H16Z",
  },
  m: {
    viewBox: "0 0 24 24",
    circlePath:
      "M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z",
    sectorPath:
      "M24 12C24 5.37258 18.6274 0 12 0V3C16.9706 3 21 7.02944 21 12H24Z",
  },
  l: {
    viewBox: "0 0 32 32",
    circlePath:
      "M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29Z",
    sectorPath:
      "M32 16C32 7.16344 24.8366 0 16 0V3C23.1797 3 29 8.8203 29 16H32Z",
  },
  xl: {
    viewBox: "0 0 64 64",
    circlePath:
      "M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64ZM32 60C47.464 60 60 47.464 60 32C60 16.536 47.464 4 32 4C16.536 4 4 16.536 4 32C4 47.464 16.536 60 32 60Z",
    sectorPath:
      "M64 32C64 14.3269 49.6731 0 32 0V4C47.464 4 60 16.536 60 32H64Z",
  },
};

<template>
  <div class="ml-3 flex-1">
    <p class="text-sm font-medium text-gray-900" v-if="title">
      {{ $t(title) }}
    </p>
    <p class="mt-1 text-sm text-gray-500" v-if="text">
      {{ $t(text) }}
      <template v-if="vars">{{ getVars }}</template>
    </p>
  </div>
</template>

<script>
export default {
  props: ["title", "text", "vars"],
  computed: {
    getVars() {
      return this.vars
        ?.map((element) => this.$t(`errors.${element}`))
        .join(", ");
    },
  },
};
</script>

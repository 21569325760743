"use strict";

import styleEnv from "./env.scss";
import grid from "./grid.scss";

const maxMobileWidth = (function () {
  // see max-mobile-width sass mixin
  let {
    gridColCount,
    gridCol,
    gridGutter,
    gridMinK,
    scrollBarWidth,
    minSideMargin,
  } = grid;
  gridCol = parseInt(gridCol);
  gridGutter = parseInt(gridGutter);
  minSideMargin = parseInt(minSideMargin);
  scrollBarWidth = parseInt(scrollBarWidth);

  const gridWidthPx = gridColCount * gridCol + (gridColCount - 1) * gridGutter;
  const minPageWidth = gridWidthPx * gridMinK + minSideMargin * 2;
  return minPageWidth - 1 + scrollBarWidth;
})();

const _narrowViewportMatch = {};

function _matchMedia(mediaQueryString) {
  if (styleEnv.mobile_support === "false" || typeof window === "undefined") {
    return {
      matches: false,
      media: mediaQueryString,
      addListener: () => {},
      removeListener: () => {},
    };
  }
  return window.matchMedia(mediaQueryString);
}

export function narrowViewportMatch(
  maxWidth = `(max-width: ${maxMobileWidth}px)`
) {
  if (_narrowViewportMatch[maxWidth] === undefined)
    _narrowViewportMatch[maxWidth] = _matchMedia(maxWidth);
  return _narrowViewportMatch[maxWidth];
}

let _touchDeviceMatch;

export function touchDeviceMatch() {
  if (_touchDeviceMatch === undefined)
    _touchDeviceMatch = _matchMedia("(pointer: coarse)");
  return _touchDeviceMatch;
}

export function isMobileTouchDevice(
  userAgent = typeof navigator !== "undefined" ? navigator.userAgent : null
) {
  const touchSupport = touchDeviceMatch().matches;
  if (!touchSupport) return false;
  return (
    /Mobile/.test(userAgent) ||
    (/Android/.test(userAgent) && !/TV/.test(userAgent))
  );
}

let _isSafari;

export function isSafari() {
  if (_isSafari !== undefined) return _isSafari;

  try {
    _isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(!window.safari || window.safari.pushNotification);
  } catch (e) {
    _isSafari = false;
  }

  return _isSafari;
}

import { narrowViewportMatch } from "./env";

export default {
  data() {
    return {
      narrowViewportObserver: null,
      isNarrowViewport: null,
    };
  },
  mounted() {
    this.narrowViewportObserver = narrowViewportMatch();
    this.narrowViewportObserver.addEventListener(
      "change",
      this.onNarrowViewportMatch
    );
    this.isNarrowViewport = this.narrowViewportObserver.matches;
  },
  beforeDestroy() {
    this.narrowViewportObserver.removeEventListener(
      "change",
      this.onNarrowViewportMatch
    );
    this.narrowViewportObserver = null;
  },
  methods: {
    onNarrowViewportMatch(event) {
      this.isNarrowViewport = event.matches;
    },
  },
};

<template>
  <Loader v-if="loading" />
  <template v-else>
    <vue-good-table
      :columns="columns"
      :rows="lms.data"
      :totalRows="lms.total"
      class="tailwind"
      @page-change="onPageChange"
      @per-page-change="onPerPageChange"
      v-if="lms.data.length > 0"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: +lms.per_page,
        position: 'bottom',
        perPageDropdown: [3, 7, 9, 10],
        dropdownAllowAll: false,
        setCurrentPage: lms.current_page,
        nextLabel: $t('table.nextLabel'),
        prevLabel: $t('table.prevLabel'),
        rowsPerPageLabel: $t('table.rowsPerPageLabel'),
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
        infoFn: (params) =>
          $t('table.infoFn', {
            from: params.firstRecordOnPage,
            to: params.lastRecordOnPage,
            total: params.totalRecords,
          }),
      }"
    >
      <template #table-column="props">
        <span>
          {{ $t(props.column.label) }}
        </span>
      </template>
      <template #table-row="props">
        <span v-if="props.column.field == 'docs'">
          <span class="relative z-0 inline-flex shadow-sm rounded-md">
            <button
              @click="openModal(props.row)"
              type="button"
              class="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary"
            >
              {{ props.row.info ? $t("edit") : $t("create") }}
            </button>
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div
      class="text-center border-dashed rounded-lg border-2 border-gray-300 p-12"
      v-else
    >
      <svg
        class="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vector-effect="non-scaling-stroke"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 class="mt-2 text-sm font-medium text-gray-900">
        У вас нет созданных СДО
      </h3>
      <p class="mt-1 text-sm text-gray-500">Создайте свою первую СДО</p>
      <div class="mt-6">
        <button
          @click="addNew"
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Создать новую СДО
        </button>
      </div>
    </div>
  </template>
  <CreateAndUpdateDetails />
</template>
<script>
import { VueGoodTable } from "vue-good-table-next";
import { Loader } from "@/UI";
import CreateAndUpdateDetails from "./CreateAndUpdateDetails.vue";
import { useStore } from "vuex";
import { ref, reactive, computed, onMounted } from "vue";
import router from "@/router";
import { PlusIcon } from "@heroicons/vue/solid";
import { makeQueryURL } from "@/helpers/utils/functions";
import { useI18n } from "vue-i18n";

export default {
  components: {
    VueGoodTable,
    Loader,
    CreateAndUpdateDetails,
    PlusIcon,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const loading = ref(true);
    const isOpen = ref(false);
    const serverParams = reactive({
      page: 1,
      perPage: 10,
    });
    const columns = [
      {
        label: "lms.list.id",
        field: "id",
      },
      {
        label: "lms.list.domain",
        field: "domain",
      },
      {
        label: "lms.list.status",
        field: "status",
        formatFn: (status) => t(`lms.status.${status}`),
      },
      {
        label: "",
        field: "docs",
        width: "50px",
      },
    ];
    onMounted(async () => {
      await store.dispatch("fetchAllLms");
      loading.value = false;
    });
    return {
      lms: computed(() => store.getters.getAllLms),
      loading,
      store,
      serverParams,
      columns,
      onRowClick(params) {
        router.push(`/lms/${params.row.id}`);
      },
      isOpen,
      closeModal() {
        isOpen.value = false;
      },
    };
  },
  methods: {
    openModal(data) {
      if (data.info) this.$emitter.$emit("updateDetails", data);
      else this.$emitter.$emit("createDetails", data);
    },
    addNew() {
      this.$emitter.$emit("createNewLms");
    },
    onPageChange(params) {
      this.serverParams.page = params.currentPage;
      const urlParams = makeQueryURL(this.serverParams);

      this.store.dispatch("fetchAllLms", urlParams);
    },
    onPerPageChange(params) {
      this.serverParams.perPage = params.currentPerPage;
      this.serverParams.page = 1;
      const urlParams = makeQueryURL(this.serverParams);

      this.store.dispatch("fetchAllLms", urlParams);
    },
  },
};
</script>

<template>
  <div class="py-4">
    <Loader v-if="loading" />
    <template v-else>
      <div class="w-96 my-10 flex place-items-center">
        <input
          id="search"
          name="search"
          type="text"
          required=""
          v-model="filter"
          :placeholder="$t('search')"
          class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        />
        <button
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary mb-2 mt-2 ml-2"
          @click="doSearch"
        >
          {{ $t("search") }}
        </button>
      </div>

      <vue-good-table
        v-if="programs.data.length > 0"
        :columns="columns"
        :rows="programs.data"
        :totalRows="programs.total"
        class="tailwind"
        @page-change="onPageChange"
        @per-page-change="onPerPageChange"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: +programs.per_page,
          position: 'bottom',
          perPageDropdown: [3, 7, 9, 10],
          dropdownAllowAll: false,
          setCurrentPage: programs.current_page,
          nextLabel: $t('table.nextLabel'),
          prevLabel: $t('table.prevLabel'),
          rowsPerPageLabel: $t('table.rowsPerPageLabel'),
          ofLabel: 'of',
          pageLabel: 'page', // for 'pages' mode
          allLabel: 'All',
          infoFn: (params) =>
            $t('table.infoFn', {
              from: params.firstRecordOnPage,
              to: params.lastRecordOnPage,
              total: params.totalRecords,
            }),
        }"
      >
        <template #table-column="props">
          <span>
            {{ $t(props.column.label) }}
          </span>
        </template>
        <template #table-row="props">
          <span v-if="props.column.field == 'sort'" class="vgt-field-invisible">
            <div>
              <div class="mt-1">
                <input
                  :disabled="props.row.enabled"
                  id="position"
                  name="position"
                  type="number"
                  required=""
                  v-model="props.row.sort"
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  @input="onChange($event, props.row)"
                />
              </div>
            </div>
          </span>
          <span
            v-if="props.column.field == 'date_start'"
            class="vgt-field-invisible"
          >
            <div>
              <div class="mt-1">
                <!-- v-model="form.password" -->
                <input
                  id="date_start"
                  name="date_start"
                  type="date"
                  required=""
                  v-model="props.row.date_start"
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  @input="onChange($event, props.row)"
                />
              </div>
            </div>
          </span>
          <span
            v-if="props.column.field == 'date_finish'"
            class="vgt-field-invisible"
          >
            <div>
              <div class="mt-1">
                <!-- v-model="form.password" -->
                <input
                  id="date_finish"
                  name="date_finish"
                  type="date"
                  required=""
                  v-model="props.row.date_finish"
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  @input="onChange($event, props.row)"
                />
              </div>
            </div>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </template>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table-next";
import { Loader } from "@/UI";
import { useStore } from "vuex";
import { ref, reactive, computed, onMounted } from "vue";
import { makeQueryURL } from "@/helpers/utils/functions";

const columns = [
  {
    label: "programs.list.school",
    field: "school",
  },
  {
    label: "programs.list.name",
    field: "name",
  },
  {
    label: "programs.list.position",
    field: "sort",
  },
  {
    label: "programs.list.start",
    field: "date_start",
  },
  {
    label: "programs.list.end",
    field: "date_finish",
  },
];
export default {
  components: {
    VueGoodTable,
    Loader,
  },
  setup() {
    const store = useStore();
    const loading = ref(true);
    const filter = "";
    const serverParams = reactive({
      page: 1,
      perPage: 10,
    });
    onMounted(async () => {
      await store.dispatch("fetchAllPrograms");
      loading.value = false;
    });
    return {
      programs: computed(() => store.getters.getFilteredPrograms),
      serverParams,
      loading,
      columns,
      filter,
      store,
    };
  },
  created() {
    window.addEventListener("keyup", this._keyupListener);
  },
  unmounted() {
    window.removeEventListener("keyup", this._keyupListener);
  },
  methods: {
    _keyupListener(event) {
      if (event.key === "Enter") this.doSearch();
    },
    doSearch() {
      this.store.dispatch("fetchAllPrograms", `searchTerm=${this.filter}`);
    },
    onChange(event, value) {
      const data = { ...value };

      this.store.dispatch("updateProgram", data);
    },
    onPageChange(params) {
      this.serverParams.page = params.currentPage;
      const urlParams = makeQueryURL(this.serverParams);

      this.store.dispatch("fetchAllPrograms", urlParams);
    },
    onPerPageChange(params) {
      this.serverParams.perPage = params.currentPerPage;
      this.serverParams.page = 1;
      const urlParams = makeQueryURL(this.serverParams);

      this.store.dispatch("fetchAllPrograms", urlParams);
    },
  },
};
</script>

<style lang="scss">
.vgt-field-invisible ~ span {
  display: none;
}
</style>

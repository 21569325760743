<template>
  <ExclamationIcon class="text-yellow-400" aria-hidden="true" />
</template>

<script>
import { ExclamationIcon } from "@heroicons/vue/outline";

export default {
  components: {
    ExclamationIcon,
  },
};
</script>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog :open="isOpen" @close="closeModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 overlay-bg" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                {{
                  create
                    ? $t("update_create.create")
                    : $t("update_create.update")
                }}{{ this.lms.id }}
              </DialogTitle>
              <div class="mt-2" v-if="create">
                <form class="space-y-2" action="#" method="POST">
                  <div>
                    <label
                      for="email"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <div class="mt-1">
                      <input
                        v-model="form.email"
                        id="email"
                        name="email"
                        type="email"
                        autocomplete="off"
                        required=""
                        class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      for="password"
                      class="block text-sm font-medium text-gray-700"
                    >
                      {{ $t("update_create.password") }}
                    </label>
                    <div class="mt-1">
                      <input
                        v-model="form.password"
                        id="password"
                        name="password"
                        type="password"
                        autocomplete="off"
                        required=""
                        class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div class="mt-2" v-else-if="update">
                <form class="space-y-2" action="#" method="POST">
                  <div>
                    <label
                      for="email_curr"
                      class="block text-sm font-medium text-gray-700"
                    >
                      {{ $t("update_create.current") }} Email
                    </label>
                    <div class="mt-1">
                      <input
                        disabled
                        v-model="form.email"
                        id="email_curr"
                        name="email_curr"
                        type="email"
                        autocomplete="off"
                        required=""
                        class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      for="email_new"
                      class="block text-sm font-medium text-gray-700"
                    >
                      {{ $t("update_create.new") }} Email
                    </label>
                    <div class="mt-1">
                      <input
                        v-model="formNew.email"
                        id="email_new"
                        name="email_new"
                        type="email"
                        autocomplete="off"
                        required=""
                        class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      for="password_curr"
                      class="block text-sm font-medium text-gray-700"
                    >
                      {{ $t("update_create.current") }}
                      {{ $t("update_create.password").toLowerCase() }}
                    </label>
                    <div class="mt-1 relative">
                      <input
                        v-model="checkPassword"
                        id="password_curr"
                        name="password_curr"
                        type="password"
                        autocomplete="off"
                        required=""
                        class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      />
                      <div
                        v-if="error"
                        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                      >
                        <ExclamationCircleIcon
                          class="h-5 w-5 text-red-500"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                    <p v-if="error" class="mt-2 text-sm text-red-600">
                      {{ $t("update_create.error") }}
                    </p>
                  </div>
                  <div>
                    <label
                      for="password_new"
                      class="block text-sm font-medium text-gray-700"
                    >
                      {{ $t("update_create.new") }}
                      {{ $t("update_create.password").toLowerCase() }}
                    </label>
                    <div class="mt-1">
                      <input
                        v-model="formNew.password"
                        id="password_new"
                        name="password_new"
                        type="password"
                        autocomplete="off"
                        required=""
                        class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      />
                    </div>
                  </div>
                </form>
              </div>

              <div class="mt-4">
                <button
                  type="button"
                  class="inline-flex justify-center px-4 py-2 text-sm font-medium text-primary bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                  @click="submit"
                >
                  {{ create ? $t("create") : $t("edit") }}
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import { ref, reactive } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    ExclamationCircleIcon,
  },

  setup() {
    const route = useRoute();
    const store = useStore();
    const isOpen = ref(false);
    const create = ref(false);
    const update = ref(false);
    const error = ref(false);
    const lms = reactive({});
    const checkPassword = ref("");
    const form = reactive({
      email: null,
      password: null,
    });
    const formNew = reactive({
      email: null,
      password: null,
    });
    return {
      isOpen,
      create,
      update,
      lms,
      form,
      formNew,
      route,
      store,
      error,
      checkPassword,
    };
  },
  mounted() {
    this.$emitter.$on("createDetails", (data) => {
      this.lms = data;
      this.create = true;
      this.openModal();
    });
    this.$emitter.$on("updateDetails", (data) => {
      this.lms = data;
      this.form = this.lms.info;
      this.update = true;
      this.openModal();
    });
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      setTimeout(() => {
        this.create = false;
        this.update = false;
      }, 500);
    },
    async submit() {
      if (this.create) {
        this.lms.info = this.form;
        await this.store.dispatch("updateLms", this.lms);
        this.closeModal();
      }
      if (this.update) {
        if (this.checkPass()) {
          this.lms.info = this.formNew;
          await this.store.dispatch("updateLms", this.lms);
          this.closeModal();
        }
      }
    },
    checkPass() {
      if (this.form.password === this.checkPassword) return true;
      else {
        this.error = true;
        return false;
      }
    },
  },
};
</script>

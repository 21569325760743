<template>
  <Loader v-if="loading" />
  <div class="w-full mt-4 flex container justify-between" v-else>
    <div class="w-full h-full space-y-4 justify-between flex flex-col">
      <div
        ref="chatWindow"
        class="messages overflow-y-auto px-4 flex space-y-4 flex-col"
      >
        <div
          v-for="(message, index) in issue.data.messages"
          :class="
            message.user_id === user.id
              ? 'self-end text-right'
              : 'self-start text-left'
          "
          class="message w-auto bg-white px-5 py-3 shadow-sm block sm:text-sm border-gray-300 rounded-md"
          :key="index"
        >
          <strong>{{ message.user.displayname }}</strong>
          <div class="mt-1">{{ message.message }}</div>
          <div class="font-light mt-1">
            {{ $d(message.created_at) }}
          </div>
          <div class="mt-2">
            <div
              v-for="(file, index) in message.files"
              class="text-primary cursor-pointer hover:underline"
              :key="file"
              @click="downloadFile(file)"
            >
              {{ $t("download_file") }} {{ index + 1 }}
            </div>
          </div>
        </div>
      </div>

      <div>
        <textarea
          name="message"
          id="message"
          class="chat-input mb-2 flex-shrink-0 shadow-sm focus:ring-primary focus:border-primary block sm:text-sm border-gray-300 rounded-md"
          :placeholder="$t('issue.chat.send_message')"
          v-model="message"
          autocomplete="off"
        />

        <div class="mb-6">
          <div
            v-for="(file, i) in files"
            :key="i"
            class="flex items-center justify-between mb-2"
          >
            <span class="ml-3">{{ file.name }}</span>
            <XIcon class="w-5 h-5 mr-7 cursor-pointer" @click="removeFile(i)" />
          </div>
        </div>

        <div class="flex items-center w-full">
          <button
            @click.prevent="sendMessage"
            type="button"
            :class="
              !message
                ? 'opacity-50 cursor-not-allowed disabled:opacity-50'
                : 'hover:bg-primary-light'
            "
            :disabled="!message"
            class="send-button justify-center flex-grow-0 hover:bg-primary-light inline-flex items-center px-4 py-2 mr-4 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            {{ $t("issue.chat.send_message") }}
          </button>

          <label class="add-file mr-2" for="file">
            <DocumentAddIcon
              class="flex-shrink-0 h-7 w-7"
              :class="!isFilesFull ? 'text-gray-900' : 'text-gray-300'"
            />
          </label>

          <span v-show="files.length"
            >{{ files.length }} / {{ maxCountFiles }}</span
          >

          <input
            name="file"
            id="file"
            type="file"
            class="hidden"
            ref="input"
            :disabled="isFilesFull"
            @change="onUpload"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Loader } from "@/UI";
// import { Message } from "@/components/Issue";
import { DocumentAddIcon, XIcon } from "@heroicons/vue/outline";

export default {
  components: {
    Loader,
    DocumentAddIcon,
    XIcon,
    // Message,
  },
  setup() {
    const router = useRoute();
    const id = router.params.id;
    const store = useStore();
    const message = ref("");
    const loading = ref(true);
    const chatWindow = ref(null);

    const files = reactive([]);
    const maxCountFiles = ref(5);
    const isFilesFull = computed(() => files.length === maxCountFiles.value);

    const issue = computed(() => store.getters.getIssue);
    const user = computed(() => store.getters.getProfile);

    function scrollToBottom() {
      chatWindow.value &&
        (chatWindow.value.scrollTop = chatWindow.value.scrollHeight);
    }

    async function sendMessage() {
      const params = {
        id,
        message: message.value,
        files,
      };

      await store.dispatch("sendMessageToIssue", params);

      message.value = null;
      files.length = 0;
      scrollToBottom();
    }

    return {
      id,
      store,
      issue,
      message,
      files,
      maxCountFiles,
      isFilesFull,
      user,
      loading,
      chatWindow,
      scrollToBottom,
      sendMessage,
    };
  },
  unmounted() {
    this.store.commit("SET_ISSUE", null);
  },
  async mounted() {
    await this.store.dispatch("fetchIssue", this.id);
    await this.store.dispatch("fetchProfile");
    this.loading = false;
    await this.$nextTick(this.scrollToBottom);
  },
  methods: {
    onUpload(event) {
      const file = event.target.files[0];
      this.files.length < this.maxCountFiles && this.files.push(file);
      this.$refs.input.value = null;
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    downloadFile(path) {
      const params = {
        issueId: this.id,
        path,
      };

      this.store.dispatch("downloadMessageFile", params);
    },
  },
};
</script>

<style>
.container {
  height: calc(100vh - 10rem);
}

.message {
  max-width: 50%;
  white-space: pre-wrap;
}

.send-button {
  width: fit-content;
  min-width: 110px;
}

.chat-input {
  width: 100%;
  height: 70px;
  resize: none;
}

.add-file {
  cursor: pointer;
}

textarea::-webkit-scrollbar,
.messages::-webkit-scrollbar {
  width: 0.5rem;
}

textarea::-webkit-scrollbar-track,
.messages::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

textarea::-webkit-scrollbar-thumb,
.messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>

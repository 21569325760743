<template>
  <div :class="getRootClasses">
    <div
      :class="[
        $style.Loader,
        $style[`Loader_kind_${kind}`],
        $style[`Loader_size_${size}`],
      ]"
    >
      <svg
        :class="$style.Loader__icon"
        :viewBox="iconData.viewBox"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          :class="$style.Loader__iconCircle"
          fill-rule="evenodd"
          clip-rule="evenodd"
          :d="iconData.circlePath"
        />
        <path :class="$style.Loader__iconSector" :d="iconData.sectorPath" />
      </svg>
    </div>
  </div>
</template>

<script>
import iconData from "./icon";

export const SIZES = ["xs", "s", "m", "l", "xl"];
export const KINDS = ["standard", "white"];

export default {
  name: "Loader",
  props: {
    kind: {
      type: String,
      default: "standard",
      validator: (v) => KINDS.includes(v),
    },
    size: {
      type: String,
      default: "xl",
      validator: (v) => SIZES.includes(v),
    },
    rootClasses: {
      type: String,
      required: false,
    },
  },
  computed: {
    iconData() {
      return iconData[this.size];
    },
    getRootClasses() {
      return this.rootClasses == null
        ? "flex justify-center mt-20"
        : this.rootClasses;
    },
  },
};
</script>

<style module lang="scss">
@import "../../lib/variables";
@import "./utils";

.Loader {
  @include loader;

  width: var(--r);
  height: var(--r);
}

.Loader__icon {
  width: 100%;
  height: 100%;
  display: block;
}

.Loader_size_xs {
  --r: 12px;
}

.Loader_size_s {
  --r: 16px;
}

.Loader_size_m {
  --r: 24px;
}

.Loader_size_l {
  --r: 32px;
}

.Loader_size_xl {
  --r: 64px;
}

.Loader_kind_standard {
  .Loader__iconCircle {
    fill: #c7d2fe;
  }

  .Loader__iconSector {
    fill: $primary;
  }
}

.Loader_kind_white {
  .Loader__iconCircle {
    fill: rgba($white, 0.4);
  }

  .Loader__iconSector {
    fill: $lightGrey;
  }
}
</style>

<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="fixed inset-0 flex z-40 md:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-gray-800"
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div
              class="flex-shrink-0 h-16 flex items-center justify-center py-5 px-4 bg-gray-900 overflow-hidden"
            >
              <img class="h-8 w-auto" src="@/assets/logo.svg" alt="Workflow" />
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <router-link
                  v-for="item in routes"
                  v-slot="{ href, navigate, isActive }"
                  :key="item.name"
                  :to="item.path"
                  custom
                >
                  <a
                    :href="href"
                    @click="navigate"
                    @mouseup="sidebarOpen = false"
                    :class="[
                      isActive
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                    ]"
                  >
                    <component
                      :is="item.meta.icon"
                      :class="[
                        isActive
                          ? 'text-gray-300'
                          : 'text-gray-400 group-hover:text-gray-300',
                        'mr-3 flex-shrink-0 h-6 w-6',
                      ]"
                      aria-hidden="true"
                    />
                    {{ $t(item.meta.name) }}</a
                  >
                </router-link>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex-1 flex flex-col min-h-0 bg-gray-800">
        <div
          class="flex items-center justify-center h-16 flex-shrink-0 px-4 bg-gray-900 overflow-hidden"
        >
          <img class="h-8 w-auto" src="@/assets/logo.svg" alt="Workflow" />
        </div>
        <div class="flex-1 flex flex-col overflow-y-auto">
          <nav class="flex-1 px-2 py-4 space-y-1">
            <router-link
              v-for="item in routes"
              v-slot="{ href, navigate, isActive }"
              :key="item.name"
              :to="item.path"
              custom
            >
              <a
                :href="href"
                @click="navigate"
                :class="[
                  isActive
                    ? 'bg-gray-900 text-white'
                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                ]"
              >
                <component
                  :is="item.meta.icon"
                  :class="[
                    isActive
                      ? 'text-gray-300'
                      : 'text-gray-400 group-hover:text-gray-300',
                    'mr-3 flex-shrink-0 h-6 w-6',
                  ]"
                  aria-hidden="true"
                />
                {{ $t(item.meta.name) }}</a
              >
            </router-link>
          </nav>
        </div>
      </div>
    </div>
    <div class="md:pl-64 flex flex-col">
      <div class="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <button
          type="button"
          class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary md:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex-1 px-4 flex justify-between">
          <div class="ml-auto flex items-center">
            <Lang />

            <button
              type="button"
              class="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button>

            <!-- Profile dropdown -->
            <Menu as="div" class="ml-3 relative">
              <div>
                <MenuButton class="flex items-center">
                  <div
                    class="w-7 h-7 mr-2 bg-primary text-white flex items-center justify-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    <span class="sr-only">Open user menu</span>
                    <UserIcon class="h-5" />
                  </div>
                  <ChevronDownIcon class="h-5" />
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div
                    class="text-sm text-gray-500 text-center mb-2 px-4 break-all"
                  >
                    {{ store.getters.getProfile.email }}
                  </div>
                  <MenuItem
                    v-for="item in userNavigation"
                    :key="item.name"
                    v-slot="{ active }"
                  >
                    <router-link
                      :to="item.href"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      >{{ $t(item.name) }}</router-link
                    >
                  </MenuItem>
                  <MenuItem v-slot="{ active }" @click="logout">
                    <a
                      href="#"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      >{{ $t("auth.logout") }}</a
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <main class="flex-1">
        <div class="py-6">
          <div class="px-4 sm:px-6 md:px-8">
            <h1 class="text-2xl font-semibold text-gray-900">
              {{ title }}
            </h1>
          </div>
          <div class="px-4 sm:px-6 md:px-8">
            <slot />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
  AcademicCapIcon,
  UserIcon,
  ChevronDownIcon,
} from "@heroicons/vue/outline";
import { SearchIcon } from "@heroicons/vue/solid";
import { Lang } from "@/components/Lang";

const navigation = [
  { name: "СДО", href: "/", icon: AcademicCapIcon, current: false },
];
const userNavigation = [{ name: "profile.title", href: "/profile" }];

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    UserIcon,
    Lang,
    ChevronDownIcon,
  },
  setup() {
    const sidebarOpen = ref(false);
    const routes = ref([]);
    const store = useStore();
    const router = useRouter();
    return {
      navigation,
      userNavigation,
      sidebarOpen,
      logout() {
        store.dispatch("logout");
      },
      routes,
      router,
      store,
    };
  },
  async mounted() {
    await this.store.dispatch("fetchProfile");
    if (this.store.getters.getProfile)
      this.$gates.setRoles(this.store.getters.getProfile.roles);
    await this.router.isReady();
    this.routes =
      this.$gates.getRoles() && this.$gates.hasRole("superadmin")
        ? this.routesForAdmin
        : this.routesForUser;
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("language", locale);
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    title() {
      if (this.$i18n && this.$route.meta.title)
        return this.$i18n.t(this.$route.meta.title);
    },
    routesForAdmin() {
      return this.router.options.routes.filter(
        (item) =>
          item.name !== "Auth" &&
          item.name !== "ResetPassword" &&
          !item.meta.disabledInNav
      );
    },
    routesForUser() {
      return this.$gates.getRoles()
        ? this.router.options.routes.filter(
            (item) =>
              item.name !== "Auth" &&
              item.name !== "ResetPassword" &&
              !item.meta.disabledInNav &&
              !this.$gates.hasRole(item.meta.disableFor)
          )
        : this.router.options.routes.filter(
            (item) =>
              item.name !== "Auth" &&
              item.name !== "ResetPassword" &&
              !item.meta.disabledInNav
          );
    },
  },
};
</script>

<template>
  <div class="p-5 rounded-md border-2" :class="classes">
    <div class="flex">
      <InformationCircleIcon class="w-6 h-6 mr-3" />
      <slot />
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
import { InformationCircleIcon } from "@heroicons/vue/outline";

const props = defineProps({
  type: {
    type: String,
    required: false,
    default: "info",
  },
});

const classes = computed(() => {
  switch (props.type) {
    case "success":
      return "border-green-300 bg-green-200 text-green-600";
    case "danger":
      return "border-red-500 bg-red-200 text-red-600";
    case "warning":
      return "border-yellow-300 bg-yellow-100 text-yellow-600";
    default:
      return "border-yellow-300 bg-yellow-100 text-yellow-600";
    // return "border-primary bg-yellow-100 text-primary";
  }
});
</script>

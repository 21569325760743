<template>
  <div class="py-4">
    <router-link to="/issue/create">
      <button
        type="button"
        class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary mb-2 mt-2 hover:bg-primary-light"
      >
        {{ $t("issue.create") }}
      </button>
    </router-link>
    <IssuesTable />
  </div>
</template>

<script>
import { IssuesTable } from "@/components/Issue";

export default {
  components: {
    IssuesTable,
  },
};
</script>

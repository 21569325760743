<template>
  <div>
    <label :class="labelClasses" :for="`${id}Input`">
      {{ label }}
    </label>
    <div class="relative">
      <div class="absolute inset-y-0 right-0 flex items-center px-2">
        <input
          @change="toggleShow"
          class="hidden"
          type="checkbox"
          :id="`${id}Toggle`"
        />
        <label
          ref="label"
          class="text-gray-500 bg-white rounded px-2 py-1 text-sm font-mono cursor-pointer"
          :for="`${id}Toggle`"
        >
          <EyeIcon v-if="!show" class="w-7 h-7" />
          <EyeOffIcon v-else class="w-7 h-7" />
        </label>
      </div>
      <input
        :id="`${id}Input`"
        @input="handleInput"
        ref="password"
        :class="inputClasses"
        :type="show ? 'text' : 'password'"
        autocomplete="off"
      />
    </div>
  </div>
</template>

<script>
import { EyeIcon, EyeOffIcon } from "@heroicons/vue/outline";

export default {
  name: "PasswordInput",
  components: { EyeIcon, EyeOffIcon },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "Password",
    },
    id: {
      type: String,
      required: true,
    },
    minLenght: {
      type: Number,
      default: null,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    labelClasses: {
      type: String,
      default: "block text-sm font-medium text-gray-700",
    },
    inputClasses: {
      type: String,
      default:
        "mt-1 w-full shadow-sm focus:ring-primary focus:border-primary block sm:text-sm border-gray-300 rounded-md bg-white",
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggleShow() {
      const password = this.$refs.password;
      this.show = !this.show;

      password.focus();
    },
    handleInput(e) {
      this.$emit("update:modelValue", e.target.value);
    },
  },
};
</script>

<style scoped>
input[type="password"] {
  background-color: white !important;
}
</style>

<template>
  <ExclamationCircleIcon class="text-red-400" aria-hidden="true" />
</template>

<script>
import { ExclamationCircleIcon } from "@heroicons/vue/outline";

export default {
  components: {
    ExclamationCircleIcon,
  },
};
</script>

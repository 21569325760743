<template>
  <div class="py-4">
    <Loader v-if="!currentNews" />
    <div v-else>
      <router-link
        v-role="'admin|superadmin'"
        :to="`/news/${id}/edit`"
      ></router-link>
      <div>
        <strong>{{ currentNews.data[langIsEn ? "title_en" : "title"] }}</strong>
        <div>{{ currentNews.data[langIsEn ? "body_en" : "body"] }}</div>
      </div>
      <router-link v-role="'admin|superadmin'" :to="`/news/${id}/edit`">
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary mb-2 mt-2 hover:bg-primary-light"
        >
          {{ $t("edit") }}
        </button>
      </router-link>
      <!-- Закомментированное задваивало новость на странице -->
      <!-- <div>
          <strong>
            {{currentNews.data.title}}
          </strong>
          <div>{{currentNews.data.body}}</div>
        </div> -->
      <!-- Закомментированное задваивало новость на странице -->
    </div>
  </div>
</template>

<script>
import { Loader } from "@/UI";
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  components: {
    Loader,
  },
  setup() {
    const { locale } = useI18n();
    const store = useStore();
    const router = useRoute();
    const id = router.params.id;
    const currentNews = computed(() => store.getters.getCurrentNews);
    const langIsEn = computed(() => locale.value === "en");

    return { id, currentNews, store, langIsEn };
  },
  async created() {
    await this.store.dispatch("fetchNewsById", this.id);
  },
  //при выходе с новости очищаем currentNews в сторе
  async unmounted() {
    this.store.dispatch("clearCurrentNews");
  },
  //при выходе с новости очищаем currentNews в сторе
};
</script>

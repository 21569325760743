<template>
  <Loader v-if="loading" />
  <div class="flex flex-col md:flex-row space-y-5" v-else>
    <div class="w-1/2">
      <h3 class="text-xl font-semibold text-gray-900">
        {{ $t("profile.yourProfile.title") }}
      </h3>
      <fieldset class="space-y-5 py-5">
        <div>
          <label for="email" class="block text-sm font-medium">{{
            $t("profile.yourProfile.email")
          }}</label>
          <div class="mt-1">
            <input
              type="text"
              name="email"
              id="email"
              class="w-1/2 form-control block px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              readonly
              :value="profile.email"
            />
          </div>
        </div>
        <div>
          <label for="login" class="block text-sm font-medium">{{
            $t("profile.yourProfile.login")
          }}</label>
          <div class="mt-1">
            <input
              type="text"
              name="login"
              id="login"
              class="w-1/2 form-control block px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              readonly
              :value="profile.name"
            />
          </div>
        </div>
        <PasswordInput
          id="oldPassword"
          v-model="oldPassword"
          class="w-1/2"
          labelClasses="block text-sm font-medium"
          :label="$t('profile.yourProfile.oldPassword')"
        />
        <PasswordInput
          v-model="newPassword"
          id="newPassword"
          class="w-1/2"
          labelClasses="block text-sm font-medium"
          :label="$t('profile.yourProfile.newPassword')"
        />
        <button
          @click.prevent="changePassword"
          type="button"
          class="hover:bg-primary-light inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          {{ $t("profile.data.changePassword") }}
        </button>
      </fieldset>

      <h3 class="text-xl font-semibold text-gray-900">
        {{ $t("profile.data.title") }}
      </h3>

      <form class="py-5">
        <fieldset class="space-y-5 py-5">
          <Input
            v-for="field in personalData"
            v-model="profile[field.model]"
            :label="$t(field.label)"
            :name="field.name"
            :id="field.name"
            :key="field.name"
          />
          <div class="mt-1">
            <Select
              v-if="selectedGender"
              v-model="selectedGender"
              :options="genderOptions"
              :label="$t('profile.data.gender.title')"
            />
          </div>
          <div>
            <div>
              <label
                for="addressResidential"
                class="block text-sm font-medium"
                >{{ $t("profile.data.addressResidential") }}</label
              >
              <div class="mt-1" v-if="!profile.payment_details_match">
                <input
                  type="text"
                  name="addressResidential"
                  id="addressResidential"
                  class="w-1/2 shadow-sm focus:ring-primary focus:border-primary block sm:text-sm border-gray-300 rounded-md"
                  v-model="profile.address_residential"
                />
              </div>
            </div>
            <div class="mt-1.5 flex align-middle">
              <Checkbox
                id="paymentDetailsMatch"
                v-model="profile.payment_details_match"
              ></Checkbox>
              <label for="paymentDetailsMatch" class="ml-2.5">{{
                $t("profile.data.payment_details_match")
              }}</label>
            </div>
          </div>
          <div>
            <label for="phone" class="block text-sm font-medium">{{
              $t("profile.data.phone")
            }}</label>
            <div class="mt-1">
              <input
                type="tel"
                @input="maskPhoneInput"
                name="phone"
                id="phone"
                class="w-1/2 shadow-sm focus:ring-primary focus:border-primary block sm:text-sm border-gray-300 rounded-md"
                v-model="profile.phone"
              />
            </div>
          </div>
          <div>
            <div>
              <label for="addressResidential" class="block text-sm font-medium"
                >Telegram</label
              >
              <div class="mt-1">
                <input
                  type="text"
                  name="telegram"
                  id="telegram"
                  class="w-1/2 shadow-sm focus:ring-primary focus:border-primary block sm:text-sm border-gray-300 rounded-md"
                  v-model="profile.telegram"
                  :disabled="msgrsMatchingPhone.telegram"
                />
              </div>
            </div>
            <div class="mt-1.5 flex align-middle">
              <Checkbox
                id="telegramMatchesPhone"
                v-model="msgrsMatchingPhone.telegram"
              ></Checkbox>
              <label for="telegramMatchesPhone" class="ml-2.5">{{
                $t("profile.data.matchesPhone")
              }}</label>
            </div>
          </div>
          <div>
            <div>
              <label for="addressResidential" class="block text-sm font-medium"
                >WhatsApp</label
              >
              <div class="mt-1">
                <input
                  type="text"
                  name="whatsapp"
                  id="whatsapp"
                  class="w-1/2 shadow-sm focus:ring-primary focus:border-primary block sm:text-sm border-gray-300 rounded-md"
                  v-model="profile.whatsapp"
                  :disabled="msgrsMatchingPhone.whatsapp"
                />
              </div>
            </div>
            <div class="mt-1.5 flex align-middle">
              <Checkbox
                id="whatsappMatchesPhone"
                v-model="msgrsMatchingPhone.whatsapp"
              ></Checkbox>
              <label for="whatsappMatchesPhone" class="ml-2.5">{{
                $t("profile.data.matchesPhone")
              }}</label>
            </div>
          </div>
          <div>
            <div>
              <label for="addressResidential" class="block text-sm font-medium"
                >Viber</label
              >
              <div class="mt-1">
                <input
                  type="text"
                  name="viber"
                  id="viber"
                  class="w-1/2 shadow-sm focus:ring-primary focus:border-primary block sm:text-sm border-gray-300 rounded-md"
                  v-model="profile.viber"
                  :disabled="msgrsMatchingPhone.viber"
                />
              </div>
            </div>
            <div class="mt-1.5 flex align-middle">
              <Checkbox
                id="viberMatchesPhone"
                v-model="msgrsMatchingPhone.viber"
              ></Checkbox>
              <label for="ViberMatchesPhone" class="ml-2.5">{{
                $t("profile.data.matchesPhone")
              }}</label>
            </div>
          </div>
          <!-- <div>
            <label for="timeZone" class="block text-sm font-medium">{{
              $t("profile.data.timeZone")
            }}</label>
            <div class="mt-1">
              <input
                type="text"
                name="timeZone"
                id="timeZone"
                class="w-1/2 shadow-sm focus:ring-primary focus:border-primary block sm:text-sm border-gray-300 rounded-md"
                v-model="profile.time_zone"
              />
            </div>
          </div> -->
        </fieldset>
        <div>
          <button
            @click.prevent="updateProfile"
            type="button"
            class="hover:bg-primary-light inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            {{ $t("profile.data.save") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref } from "vue";
import { useStore } from "vuex";
import PasswordInput from "@/components/PasswordInput/PasswordInput";
import Checkbox from "@/components/Checkbox/Checkbox";
import { Loader, Input, Select } from "@/UI";

export default {
  name: "Profile",
  components: {
    Checkbox,
    PasswordInput,
    Loader,
    Select,
    Input,
  },
  watch: {
    "profile.phone"(value) {
      if (this.msgrsMatchingPhone.telegram) this.profile.telegram = value;
      if (this.msgrsMatchingPhone.whatsapp) this.profile.whatsapp = value;
      if (this.msgrsMatchingPhone.viber) this.profile.viber = value;
    },
    "msgrsMatchingPhone.telegram"(value) {
      if (value) this.profile.telegram = this.profile.phone;
    },
    "msgrsMatchingPhone.whatsapp"(value) {
      if (value) this.profile.whatsapp = this.profile.phone;
    },
    "msgrsMatchingPhone.viber"(value) {
      if (value) this.profile.viber = this.profile.phone;
    },
  },
  setup() {
    const store = useStore();
    const profile = computed(() => store.getters.getProfile);
    const loading = ref(true);
    const msgrsMatchingPhone = reactive({
      telegram: false,
      whatsapp: false,
      viber: false,
    });
    const oldPassword = ref(null);
    const newPassword = ref(null);

    const selectedGender = ref(null);
    const genderOptions = [
      {
        value: "male",
        text: "profile.data.gender.male",
      },
      {
        value: "female",
        text: "profile.data.gender.female",
      },
    ];

    const personalData = [
      {
        model: "first_name",
        label: "profile.data.firstName",
        name: "firstName",
      },
      {
        model: "last_name",
        label: "profile.data.lastName",
        name: "lastName",
      },
      {
        model: "patronymic",
        label: "profile.data.patronymic",
        name: "patronymic",
      },
      {
        model: "place_birth",
        label: "profile.data.placeBirth",
        name: "placeBirth",
      },
      {
        model: "passport_series",
        label: "profile.data.passportSeries",
        name: "passportSeries",
      },
      {
        model: "passport_number",
        label: "profile.data.passportNumber",
        name: "passportNumber",
      },
      {
        model: "passport_who",
        label: "profile.data.passportWho",
        name: "passportWho",
      },
      {
        model: "passport_code",
        label: "profile.data.passportCode",
        name: "passportCode",
      },
      {
        model: "passport_when",
        label: "profile.data.passportWhen",
        name: "passportWhen",
      },
      {
        model: "address_registrations",
        label: "profile.data.addressRegistrations",
        name: "addressRegistrations",
      },
    ];

    return {
      profile,
      store,
      loading,
      msgrsMatchingPhone,
      oldPassword,
      newPassword,
      selectedGender,
      genderOptions,
      personalData,
    };
  },
  async mounted() {
    await this.store.dispatch("fetchProfile");
    const profileGender = this.profile.gender;

    if (profileGender)
      this.selectedGender = this.genderOptions.find(
        (gender) => gender.value === profileGender
      );
    this.loading = false;
  },
  methods: {
    maskPhoneInput(e) {
      let x = e.target.value
        .replace(/\D/g, "")
        .match(/(\d)(\d{0,3})(\d{0,3})(\d{0,4})/);
      if (!x) return;
      e.target.value = `+${
        (!x[3] ? x[1] + x[2] : x[1] + " (" + x[2] + ") ") +
        (!x[3] ? "" : x[3]) +
        (!x[4] ? "" : "-" + x[4])
      }`;
    },
    async updateProfile() {
      for (let msgr in this.msgrsMatchingPhone) {
        if (this.msgrsMatchingPhone[msgr]) {
          this.profile[msgr] = this.profile.phone;
        }
      }
      this.profile.gender = this.selectedGender.value;
      await this.store.dispatch("updateProfile", this.profile);
    },
    async changePassword() {
      await this.store.dispatch("changePassword", {
        password: this.newPassword,
        old_password: this.oldPassword,
      });
    },
  },
};
</script>

<style scoped></style>

<template>
  <a v-if="tag === 'a'" v-bind="attrs">
    <slot />
  </a>
  <component :is="tag" v-else ref="unilink" v-bind="attrs">
    <slot />
  </component>
</template>

<script>
export default {
  name: "UniLink",
  props: {
    href: {
      type: String,
      default: undefined,
    },
    to: {
      type: [String, Object],
      default: undefined,
    },
  },
  computed: {
    tag() {
      if (this.to !== undefined && this.$options.components) {
        if (this.$options.components.NuxtLink) return "NuxtLink";
        if (this.$options.components.RouterLink) return "RouterLink";
      }
      return "a";
    },
    attrs() {
      let { href, to, tag } = this;
      if (tag !== "a" && href === undefined) {
        return { to };
      } else if (href === undefined && typeof to === "string") {
        href = to;
      }
      return { href, to };
    },
  },
  mounted() {
    if (this.tag !== "a") {
      const el = this.$refs.unilink.$el;
      Object.keys(this.$attrs).forEach((type) => {
        el.addEventListener(type, this.emitEvent);
      });
    }
  },
  beforeUnmount() {
    if (this.tag !== "a") {
      const el = this.$refs.unilink.$el;
      Object.keys(this.$attrs).forEach((type) => {
        el.removeEventListener(type, this.emitEvent);
      });
    }
  },
  methods: {
    emitEvent(event) {
      this.$emit(event.type, event);
    },
  },
};
</script>

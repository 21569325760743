<template>
  <div class="py-4">
    <router-link to="/news/create" v-role="'admin|superadmin'">
      <button
        type="button"
        class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary mb-2 mt-2 hover:bg-primary-light"
      >
        {{ $t("news.create") }}
      </button>
    </router-link>

    <Loader v-if="!news" />

    <div v-else>
      <div v-if="!news.data.length">{{ $t("news.empty") }}</div>
      <div v-else>
        <Card
          :title="langIsEn ? title_en : title"
          v-for="{ id, title, title_en, body, body_en } in news.data"
          :key="id"
          :to="`/news/${id}`"
          >{{ langIsEn ? body_en : body }}</Card
        >
      </div>
    </div>

    <Paginator v-if="news" :data="news" @onChangePage="onChangePage" />
  </div>
</template>

<script>
import { Loader, Card } from "@/UI";
import { Paginator } from "@/components/Paginator";
import { computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default {
  components: {
    Loader,
    Paginator,
    Card,
  },
  setup() {
    const { locale } = useI18n();
    const store = useStore();
    const news = computed(() => store.getters.getAllNews);
    const langIsEn = computed(() => locale.value === "en");

    return { news, store, langIsEn };
  },
  async created() {
    await this.store.dispatch("fetchAllNews");
  },
  methods: {
    async onChangePage(page) {
      await this.store.dispatch("fetchAllNews", `page=${page}`);
    },
  },
};
</script>

<template>
  <Loader v-if="!issues" />
  <vue-good-table
    :columns="columns"
    :rows="issues.data"
    :totalRows="issues.total"
    class="tailwind w-full py-5"
    v-else
    @page-change="onPageChange"
    @per-page-change="onPerPageChange"
    @row-click="onRowClick"
    :pagination-options="{
      enabled: true,
      mode: 'records',
      perPage: +issues.per_page,
      position: 'bottom',
      perPageDropdown: [3, 7, 9, 10],
      dropdownAllowAll: false,
      setCurrentPage: issues.current_page,
      nextLabel: $t('table.nextLabel'),
      prevLabel: $t('table.prevLabel'),
      rowsPerPageLabel: $t('table.rowsPerPageLabel'),
      ofLabel: 'of',
      pageLabel: 'page', // for 'pages' mode
      allLabel: 'All',
      infoFn: (params) =>
        $t('table.infoFn', {
          from: params.firstRecordOnPage,
          to: params.lastRecordOnPage,
          total: params.totalRecords,
        }),
    }"
  >
    <template #emptystate> {{ $t("table.no_data") }}. </template>
  </vue-good-table>
</template>

<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import { Loader } from "@/UI";
import { VueGoodTable } from "vue-good-table-next";
import router from "@/router";
import moment from "moment";
import { useI18n } from "vue-i18n";
import { makeQueryURL } from "@/helpers/utils/functions";

export default {
  components: {
    Loader,
    VueGoodTable,
  },
  methods: {
    onRowClick(params) {
      router.push(`/issue/${params.row.id}`);
    },
    onPageChange(params) {
      this.serverParams.page = params.currentPage;
      const urlParams = makeQueryURL(this.serverParams);

      this.store.dispatch("fetchAllIssues", urlParams);
    },
    onPerPageChange(params) {
      this.serverParams.perPage = params.currentPerPage;
      this.serverParams.page = 1;
      const urlParams = makeQueryURL(this.serverParams);

      this.store.dispatch("fetchAllIssues", urlParams);
    },
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const issues = computed(() => store.getters.getAllIssues);
    const serverParams = reactive({
      page: 1,
      perPage: 10,
    });
    const columns = [
      {
        label: t("issue.table.title"),
        field: "title",
      },
      {
        label: t("issue.table.date"),
        field: "created_at",
        formatFn(value) {
          if (value !== null) {
            return moment(value).format("DD.MM.YYYY");
          }
        },
      },
      {
        label: t("issue.table.status"),
        field: "status",
        formatFn: (value) => {
          if (value) {
            return t(`issue.status.${value}`);
          }

          return "";
        },
      },
    ];

    return { issues, store, serverParams, columns };
  },
  async created() {
    await this.store.dispatch("fetchAllIssues");
  },
};
</script>

<template>
  <AppLayout>
    <router-view />
  </AppLayout>
</template>
<script>
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  async mounted() {},
};
</script>

<template>
  <component
    :is="tag"
    v-bind="attrs"
    :class="{
      [$style.UnstyledButton]: true,
      [$style.UnstyledButton_pseudolink]: !!pseudolink,
      [$style.UnstyledButton_link]: !!link,
      [$style[`UnstyledButton_linkColor_${linkColor}`]]: linkColor,
    }"
  >
    <slot />
  </component>
</template>

<script>
import UniLink from "../UniLink";

export const COLORS = ["green", "black", "grey", "blue"];

export default {
  name: "UnstyledButton",
  components: { UniLink },
  props: {
    href: {
      type: String,
      default: undefined,
    },
    to: {
      type: [Object, String],
      default: undefined,
    },
    type: {
      type: String,
      default: "button",
    },
    disabled: Boolean,
    target: {
      type: String,
      default: undefined,
    },
    link: {
      type: [Boolean, String],
      validator(value) {
        return typeof value === "boolean" || COLORS.includes(value);
      },
      default: false,
    },
    pseudolink: {
      type: [Boolean, String],
      validator(value) {
        return typeof value === "boolean" || COLORS.includes(value);
      },
      default: false,
    },
  },
  computed: {
    isLink() {
      if (this.disabled) return false;
      return this.href !== undefined || this.to !== undefined;
    },
    linkColor() {
      const link = this.link || this.pseudolink || false;
      return link === true ? "default" : link;
    },
    tag() {
      if (this.isLink) return UniLink;
      return "button";
    },
    attrs() {
      return {
        type: !this.isLink ? this.type : undefined,
        href: this.isLink ? this.href : undefined,
        to: this.isLink ? this.to : undefined,
        disabled: !this.isLink ? this.disabled : undefined,
        target: this.isLink ? this.target : undefined,
      };
    },
  },
};
</script>

<style module lang="scss">
@import "@/lib/variables";
.UnstyledButton {
  display: inline-block;
  position: relative;
  height: auto;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  border-radius: 0;
  font: inherit;
  text-decoration: none;
  text-transform: inherit;
  letter-spacing: inherit;

  &::-moz-focus-inner {
    border: 10px;
  }

  @media (hover: hover) {
    &:hover,
    &:active {
      color: inherit;
    }
  }

  &:disabled {
    cursor: default;
  }
}

$colors: (green $darkGreen darken($darkGreen, 10)), (black $almostBlack $black),
  (grey $darkGrey $almostBlack), (blue $vividBlue darken($vividBlue, 10));

@mixin pseudolink-decor {
  text-decoration: none;
  border-bottom: 1px dashed;

  @supports (text-decoration: underline dashed) {
    text-decoration: underline dashed;
    text-decoration-thickness: from-font;
    text-underline-offset: 0.15em;
    border-bottom: none;
  }
}

.UnstyledButton_link {
  &,
  &:link,
  &:visited {
    transition: color 0.1s ease-out;
    text-decoration: none;

    &[disabled] {
      pointer-events: none;
      color: $semiDarkGrey;
    }
  }

  @media (hover: hover) {
    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      text-underline-offset: 0.15em;
    }
  }
}

.UnstyledButton_pseudolink {
  &,
  &:link,
  &:visited,
  &:hover,
  &:active {
    @include pseudolink-decor;

    &[disabled] {
      pointer-events: none;
      color: $semiDarkGrey;
    }
  }
}

@each $colorName, $defaultColor, $activeColor in $colors {
  .UnstyledButton_linkColor_#{"" + $colorName} {
    &,
    &:link,
    &:visited {
      color: $defaultColor;
    }

    @media (hover: hover) {
      &:hover,
      &:active,
      &:focus {
        color: $activeColor;
      }
    }
  }
}

.UnstyledButton_linkColor_default {
  &,
  &:link,
  &:visited {
    text-decoration: underline;
    text-underline-offset: 0.15em;
  }

  @media (hover: hover) {
    &:hover,
    &:active,
    &:focus {
      color: $jinoRed;
    }
  }
}

.UnstyledButton_pseudolink.UnstyledButton_linkColor_default {
  &,
  &:link,
  &:visited {
    @include pseudolink-decor;
  }
}
</style>

<template>
  <div class="ml-4 flex-shrink-0 flex">
    <button
      @click="show = false"
      class="rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
    >
      <span class="sr-only">Close</span>
      <XIcon class="h-5 w-5" aria-hidden="true" />
    </button>
  </div>
</template>

<script>
import { XIcon } from "@heroicons/vue/solid";

export default {
  components: {
    XIcon,
  },
};
</script>

<template>
  <form class="py-5">
    <fieldset class="space-y-5">
      <div>
        <label for="title" class="block text-sm font-medium">{{
          $t("issue.send_issue")
        }}</label>
        <div class="mt-1">
          <input
            type="text"
            name="title"
            id="title"
            class="shadow-sm focus:ring-primary focus:border-primary block w-2/3 sm:text-sm border-gray-300 rounded-md mb-2"
            :placeholder="$t('issue.title')"
            v-model="title"
          />
          <textarea
            type="text"
            name="title"
            id="title"
            class="shadow-sm focus:ring-primary focus:border-primary block w-2/3 sm:text-sm border-gray-300 rounded-md mb-2"
            :placeholder="$t('issue.message')"
            v-model="message"
          />
        </div>
      </div>
    </fieldset>
    <button
      type="button"
      @click="sendIssue"
      :class="
        !title.length || !message.length || isLoading
          ? 'opacity-50 cursor-not-allowed disabled:opacity-50'
          : 'hover:bg-primary-light'
      "
      class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
      :disabled="!title.length || !message.length || isLoading"
    >
      {{ $t("issue.send") }}
    </button>
  </form>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  methods: {
    async sendIssue() {
      this.isLoading = true;
      await this.store.dispatch("sendIssue", {
        title: this.title,
        message: this.message,
      });
      this.title = this.message = "";
      await this.store.dispatch("fetchAllIssues");
      this.isLoading = false;
    },
  },
  setup() {
    const store = useStore();
    const title = ref("");
    const message = ref("");
    const isLoading = ref(false);

    return { store, title, message, isLoading };
  },
};
</script>

<template>
  <div class="py-4">
    <div class="w-full px-2">
      <TabGroup
        as="div"
        :defaultIndex="selectedIndex"
        :key="selectedIndex"
        @change="changeTab"
      >
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex md:space-x-8 space-x-2">
            <Tab
              as="template"
              v-slot="{ selected }"
              v-for="tab in tabs"
              :key="tab.name"
            >
              <a
                :class="[
                  selected
                    ? 'border-primary text-primary'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm cursor-pointer text-center',
                ]"
              >
                <component
                  :is="tab.icon"
                  :class="[
                    selected
                      ? 'text-primary-light'
                      : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5 hidden md:block',
                  ]"
                  aria-hidden="true"
                />
                <span>{{ $t(tab.name) }}</span>
              </a>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel class="py-5" v-for="tab in tabs" :key="tab.name">
            <component :is="tab.content" />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  </div>
</template>

<script>
import { All, AddNew, Details } from "@/components/LMS";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import {
  AdjustmentsIcon,
  PlusCircleIcon,
  AcademicCapIcon,
} from "@heroicons/vue/outline";
import { ref } from "vue";
import { useRoute } from "vue-router";
const tabs = [
  { name: "lms.list.title", icon: AcademicCapIcon, content: All },
  { name: "lms.create.title", icon: PlusCircleIcon, content: AddNew },
  { name: "lms.auth.title", icon: AdjustmentsIcon, content: Details },
];
export default {
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
  setup() {
    const route = useRoute();
    const selectedIndex = ref(0);
    return {
      tabs,
      selectedIndex,
      route,
      changeTab(index) {
        selectedIndex.value = index;
      },
    };
  },
  async mounted() {
    this.$emitter.$on("createNewLms", () => {
      this.selectedIndex = 1;
    });
    this.$emitter.$on("viewAllLms", () => {
      this.selectedIndex = 0;
    });
  },
};
</script>

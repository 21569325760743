<template>
  <Loader v-if="loading" />
  <div v-else>
    <div class="flex flex-col lg:flex-row space-x-5">
      <div class="lg:w-2/3">
        <form class="py-5">
          <Info v-if="disabledConditions" class="mb-3">{{
            $t("lms.edit.cant")
          }}</Info>
          <fieldset class="space-y-5">
            <div>
              <label for="name" class="block text-sm font-medium">{{
                $t("lms.create.name")
              }}</label>
              <div class="mt-1">
                <input
                  type="text"
                  name="name"
                  id="name"
                  class="shadow-sm focus:ring-primary focus:border-primary block sm:text-sm border-gray-300 rounded-md"
                  placeholder="Новая СДО"
                  v-model="curLms.name"
                  :disabled="disabledConditions"
                />
              </div>
            </div>
            <div class="flex flex-col">
              <label for="prefix" class="text-sm font-medium">
                {{ $t("lms.create.prefix") }}
              </label>
              <div
                class="flex flex-col lg:flex-row space-x-2 space-y-2 items-center"
              >
                <div class="mt-1 flex">
                  <span
                    class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                  >
                    https://
                  </span>
                  <input
                    type="text"
                    name="prefix"
                    id="prefix"
                    class="min-w-0 px-3 py-2 rounded-none focus:ring-primary focus:border-primary sm:text-sm border-gray-300"
                    placeholder="emas"
                    v-model="curLms.domain"
                    :disabled="disabledConditions"
                    @input="checkDomain"
                    @change="domainChanged"
                  />
                  <span
                    class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                  >
                    .studixy.com
                  </span>
                </div>
                <div class="flex items-center space-x-1" v-if="viewCheckDomain">
                  <span
                    v-if="loadingDomain"
                    class="inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium bg-yellow-100 text-primary"
                  >
                    <Loader size="s" class="mr-1.5" rootClasses="" />
                    {{ $t("lms.create.domain_wait") }}
                  </span>
                  <span
                    v-else-if="domainValid.data === true"
                    class="inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium bg-green-200 text-green-600"
                  >
                    <CheckIcon class="-ml-1 mr-1.5 h-5 w-5 text-green-600" />
                    {{ $t("lms.create.domain_success") }}
                  </span>
                  <span
                    v-else
                    class="inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium bg-red-200 text-red-600"
                  >
                    <XIcon
                      class="-ml-1 mr-1.5 min-w-5 min-h-5 h-5 w-5 text-red-600"
                    />
                    <template v-if="domainValid.data === 'ISSET_DOMAIN'">
                      {{ $t("lms.create.domain_isset") }}
                    </template>
                    <template v-if="domainValid.data === 'EXCEPTION_DOMAIN'">
                      {{ $t("lms.create.domain_exception") }}
                    </template>
                    <template v-if="domainValid.data.startsWith('MIN_LEN_')">
                      {{ $t("lms.create.domain_min_len") }}
                      {{ domainValid.data.split("_").pop() }}
                      {{ $t("auth.warnings.symbols") }}
                    </template>
                    <template v-if="domainValid.data === 'NOT_RIGHT_WRITING'">
                      {{ $t("lms.create.domain_not_right") }}
                    </template>
                    <template v-if="domainValid.data === 'DOMAIN_DONT_NUMBER'">
                      {{ $t("lms.create.domain_dont_number") }}
                    </template>
                  </span>
                </div>
              </div>
            </div>
            <Select
              v-model="selectedCurrency"
              :options="currencyOptions"
              :label="$t('currency.select')"
              :onChange="onChangeCurrency(selectedCurrency?.value)"
              :disabled="disabledConditions"
              class="w-1/2"
            />
            <div
              class="relative border p-4 flex flex-col lg:pl-4 lg:pr-6 focus:outline-none lg:flex-row rounded-md bg-white items-center"
            >
              <label class="flex items-center space-x-1 cursor-pointer">
                <Checkbox
                  v-model="curLms.transfer_students"
                  :disabled="disabledConditions"
                />
                <span>{{ $t("lms.create.transfer_students.title") }}</span>
                <QuestionMarkCircleIcon
                  class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                  ref="transfer_students"
                />
              </label>
              <Hint targetref="transfer_students" position="top">
                {{ $t("lms.create.transfer_students.hint") }}
              </Hint>
              <div class="ml-auto pl-1 text-sm flex flex-col text-center">
                <span v-if="newLms[0].type === 'once'">
                  {{ $t("lms.create.transfer_students.one_time") }}
                </span>
                <span class="font-medium"
                  >{{ newLms[0][getPrice] || 0 }}
                  {{ $t(`currency.${selectedCurrency.value}`)
                  }}<span v-if="newLms[0].type === 'month'">
                    / {{ $t("lms.create.time_unit") }}</span
                  ></span
                >
              </div>
            </div>
            <div
              class="relative border p-4 flex flex-col lg:pl-4 lg:pr-6 focus:outline-none rounded-md bg-white"
            >
              <div class="items-center flex">
                <label class="flex items-center space-x-1 cursor-pointer">
                  <Checkbox v-model="newLms[1].value" disabled />
                  <span>{{ $t("lms.create.base.title") }}</span>
                  <QuestionMarkCircleIcon
                    class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                    ref="base"
                  />
                </label>
                <Hint targetref="base" position="top">
                  {{ $t("lms.create.base.hint") }}
                </Hint>
                <button
                  type="button"
                  @click="newLms[1].childActive = !newLms[1].childActive"
                  class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
                >
                  <component
                    :is="arrowComponent(newLms[1].childActive)"
                    class="h-6 w-6 text-white"
                  />
                </button>
                <div class="ml-auto pl-1 text-sm flex flex-col text-center">
                  <span v-if="newLms[1].type === 'once'">{{
                    $t("lms.create.one_time")
                  }}</span>
                  <span class="font-medium"
                    >{{ newLms[1][getPrice] || 0 }}
                    {{ $t(`currency.${selectedCurrency.value}`)
                    }}<span v-if="newLms[1].type === 'month'">
                      / {{ $t("lms.create.time_unit") }}</span
                    ></span
                  >
                </div>
              </div>
              <Inlay
                :open="newLms[1].childActive"
                class="ml-5 mt-2 px-3 py-1.5"
              >
                <fieldset class="space-y-0.5">
                  <label
                    class="flex items-center space-x-1 text-sm"
                    v-for="(item, index) in newLms[1].child"
                    :key="index"
                  >
                    <Checkbox modelValue="true" size="s" disabled />
                    <span>{{ $t(item.title) }}</span>
                    <QuestionMarkCircleIcon
                      class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                      :ref="`base_student-${index}`"
                    />
                    <Hint :targetref="`base_student-${index}`" position="right">
                      {{ $t(item.hint) }}
                    </Hint>
                  </label>
                </fieldset>
              </Inlay>
            </div>
            <div
              class="relative border p-4 flex flex-col lg:pl-4 lg:pr-6 focus:outline-none rounded-md bg-white"
            >
              <div class="items-center flex">
                <label class="flex items-center space-x-1 cursor-pointer">
                  <Checkbox
                    v-model="curLms.networking.enabled"
                    :disabled="disabledConditions"
                    @changed="changeNetworkingStatus"
                  />
                  <span>{{ $t("lms.create.networking.title") }}</span>
                  <QuestionMarkCircleIcon
                    class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                    ref="network"
                  />
                </label>
                <Hint targetref="network" position="top">
                  {{ $t("lms.create.networking.hint") }}
                </Hint>
                <button
                  type="button"
                  @click="newLms[2].childActive = !newLms[2].childActive"
                  class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
                >
                  <component
                    :is="arrowComponent(newLms[2].childActive)"
                    class="h-6 w-6 text-white"
                  />
                </button>
                <div class="ml-auto pl-1 text-sm flex flex-col text-center">
                  <span v-if="newLms[2].type === 'once'">{{
                    $t("lms.create.one_time")
                  }}</span>
                  <span class="font-medium"
                    >{{ newLms[2][getPrice] || 0 }}
                    {{ $t(`currency.${selectedCurrency.value}`)
                    }}<span v-if="newLms[2].type === 'month'">
                      / {{ $t("lms.create.time_unit") }}</span
                    ></span
                  >
                </div>
              </div>
              <Inlay
                :open="newLms[2].childActive"
                class="ml-5 px-3 py-1.5 space-y-3"
              >
                <fieldset>
                  <h3 class="font-medium">
                    {{ $t("lms.create.networking.type.title") }}
                  </h3>
                  <RadioGroup
                    v-model="curLms.networking.type"
                    :disabled="!curLms.networking.enabled || disabledConditions"
                  >
                    <div class="relative bg-white rounded-md -space-y-px">
                      <RadioGroupOption
                        as="template"
                        v-for="(item, index) in newLms[2].child[0].type"
                        :key="index"
                        :value="item.name"
                        v-slot="{ checked, active, disabled }"
                      >
                        <div
                          :class="[
                            index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                            index === 1 ? 'rounded-bl-md rounded-br-md' : '',
                            checked
                              ? 'bg-indigo-50 border-indigo-200 z-10'
                              : 'border-gray-200',
                            disabled ? 'opacity-80' : '',
                            'relative border p-4 flex flex-col cursor-pointer lg:pl-4 lg:pr-6 focus:outline-none lg:flex-row',
                          ]"
                        >
                          <div class="flex items-center text-sm w-3/5">
                            <span
                              :class="[
                                checked
                                  ? 'bg-primary border-transparent'
                                  : 'bg-white border-gray-300',
                                active
                                  ? 'ring-2 ring-offset-2 ring-primary'
                                  : '',
                                'h-4 w-4 rounded-full border flex items-center justify-center',
                              ]"
                              aria-hidden="true"
                            >
                              <span class="rounded-full bg-white w-1.5 h-1.5" />
                            </span>
                            <RadioGroupLabel
                              as="span"
                              :class="[
                                checked ? 'text-primary' : 'text-gray-900',
                                'ml-3 font-medium',
                              ]"
                              >{{ $t(item.title) }}</RadioGroupLabel
                            >
                          </div>
                          <div class="w-1/5">
                            <QuestionMarkCircleIcon
                              class="m-auto h-5 w-5 text-gray-400 hover:text-gray-700"
                              :ref="`network-${item.name}`"
                            />
                            <Hint
                              :targetref="`network-${item.name}`"
                              position="right"
                            >
                              {{ $t(item.hint) }}
                            </Hint>
                          </div>
                          <RadioGroupDescription
                            class="ml-6 pl-1 text-sm lg:ml-0 lg:pl-0 lg:text-center w-1/5"
                          >
                            <div
                              v-if="item.type === 'once'"
                              :class="[
                                checked ? 'text-primary' : 'text-gray-900',
                              ]"
                            >
                              {{ $t("lms.create.one_time") }}
                            </div>
                            <span
                              :class="[
                                checked ? 'text-primary' : 'text-gray-900',
                                'font-medium',
                              ]"
                              >{{ item[getPrice] }}
                              {{ $t(`currency.${selectedCurrency.value}`) }}
                              <span v-if="item.type === 'month'"
                                >/ {{ $t("lms.create.time_unit") }}</span
                              >
                            </span>
                          </RadioGroupDescription>
                        </div>
                      </RadioGroupOption>
                    </div>
                  </RadioGroup>
                </fieldset>
                <div>
                  <label class="flex items-center space-x-1">
                    <Checkbox v-model="curLms.networking.enabled" disabled />
                    <span>{{ $t("lms.create.networking.base.title") }}</span>
                    <QuestionMarkCircleIcon
                      class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                      ref="base_func"
                    />
                    <Hint targetref="base_func" position="right">
                      {{ $t("lms.create.networking.base.hint") }}
                    </Hint>
                    <button
                      type="button"
                      @click="
                        newLms[2].child[1].active = !newLms[2].child[1].active
                      "
                      class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
                    >
                      <component
                        :is="arrowComponent(newLms[2].child[1].active)"
                        class="h-6 w-6 text-white"
                      />
                    </button>
                  </label>
                  <Inlay
                    :open="newLms[2].child[1].active"
                    class="ml-5 mt-2 px-3 py-1.5 space-y-0.5"
                  >
                    <label
                      class="flex items-center space-x-1 text-sm"
                      v-for="item in newLms[2].child[1].fields"
                      :key="item"
                    >
                      <Checkbox modelValue="true" size="s" disabled />
                      <span>{{ $t(item) }}</span>
                    </label>
                  </Inlay>
                </div>
                <div>
                  <div class="items-center flex">
                    <label class="flex items-center space-x-1 cursor-pointer">
                      <Checkbox
                        v-model="curLms.networking.additional_fields.enabled"
                        :disabled="
                          !curLms.networking.enabled || disabledConditions
                        "
                      />
                      <span>{{
                        $t("lms.create.networking.additional_fields.title")
                      }}</span>
                      <QuestionMarkCircleIcon
                        class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                        ref="additional"
                      />
                    </label>
                    <Hint targetref="additional" position="top">
                      {{ $t("lms.create.networking.additional_fields.hint") }}
                    </Hint>
                    <button
                      type="button"
                      @click="newLms[5].childActive = !newLms[5].childActive"
                      class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
                    >
                      <component
                        :is="arrowComponent(newLms[5].childActive)"
                        class="h-6 w-6 text-white"
                      />
                    </button>
                    <div
                      v-if="curLms.networking.additional_fields.items.length"
                      class="ml-auto pl-1 text-sm flex flex-col text-center"
                    >
                      <span v-if="newLms[5].type === 'once'">{{
                        $t("lms.create.one_time")
                      }}</span>
                      <span class="font-medium"
                        >{{
                          newLms[5][getPrice] *
                          curLms.networking.additional_fields.items.length
                        }}
                        {{ $t(`currency.${selectedCurrency.value}`)
                        }}<span v-if="newLms[5].type === 'month'"
                          >/ {{ $t("lms.create.time_unit") }}</span
                        >
                      </span>
                    </div>
                  </div>
                  <!-- <pre>{{ curLms.networking }}</pre> -->
                  <Inlay
                    :open="newLms[5].childActive"
                    class="ml-4 space-y-5 mt-5"
                  >
                    <div
                      class="flex space-x-2 items-center"
                      v-for="(item, index) in curLms.networking
                        ?.additional_fields?.items"
                      :key="index"
                    >
                      <button
                        @click="deleteAdditional(index)"
                        type="button"
                        class="h-6 w-6 ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
                        :disabled="disabledConditions"
                      >
                        <MinusIcon class="h-4 w-4 text-white" />
                      </button>
                      <Listbox
                        as="div"
                        v-model="
                          curLms.networking.additional_fields.items[index]
                        "
                        class="w-1/3 relative"
                        :disabled="disabledConditions"
                      >
                        <ListboxLabel
                          class="block text-sm font-medium text-gray-700 absolute -top-4"
                        >
                          {{
                            $t("lms.create.networking.additional_fields.name")
                          }}
                        </ListboxLabel>
                        <div class="mt-1 relative">
                          <ListboxButton
                            class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm"
                          >
                            <span class="flex items-center">
                              <span class="block truncate">{{
                                curLms.networking.additional_fields.items[index]
                                  .text ||
                                $t(
                                  "lms.create.networking.additional_fields.placeholder"
                                )
                              }}</span>
                            </span>
                            <span
                              class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                            >
                              <SelectorIcon
                                class="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </ListboxButton>

                          <transition
                            leave-active-class="transition ease-in duration-100"
                            leave-from-class="opacity-100"
                            leave-to-class="opacity-0"
                          >
                            <ListboxOptions
                              class="scrollbar absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                            >
                              <ListboxOption
                                as="template"
                                v-for="child in newLms[5].child"
                                :key="child.id"
                                :value="child"
                                v-slot="{ active, selected }"
                                :disabled="isDisabled(child.value)"
                              >
                                <li
                                  :class="[
                                    active
                                      ? 'text-white bg-primary'
                                      : 'text-gray-900',
                                    'cursor-default select-none relative py-2 pl-3 pr-9',
                                  ]"
                                >
                                  <div class="flex items-center">
                                    <span
                                      :class="[
                                        selected
                                          ? 'font-semibold'
                                          : 'font-normal',
                                        'block truncate',
                                      ]"
                                    >
                                      {{ child.text }}
                                    </span>
                                  </div>

                                  <span
                                    v-if="selected"
                                    :class="[
                                      active ? 'text-white' : 'text-primary',
                                      'absolute inset-y-0 right-0 flex items-center pr-4',
                                    ]"
                                  >
                                    <CheckIcon
                                      class="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox>
                    </div>
                    <div class="flex">
                      <div
                        class="flex items-center space-x-2 cursor-pointer"
                        @click="addAdditionalField"
                      >
                        <button
                          type="button"
                          class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
                          :disabled="disabledConditions"
                        >
                          <PlusIcon class="h-4 w-4 text-white" />
                        </button>
                        <span class="text-sm font-medium text-gray-700">
                          {{
                            $t("lms.create.networking.additional_fields.add")
                          }}
                        </span>
                      </div>
                    </div>
                  </Inlay>
                </div>
                <!-- div>
                  <div class="flex items-center space-x-1">
                    <span>{{ $t("lms.create.networking.preview.title") }}</span>
                    <QuestionMarkCircleIcon
                      class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                      ref="preview"
                    />
                    <Hint targetref="preview" position="right">
                      {{ $t("lms.create.networking.preview.hint") }}
                    </Hint>
                    <button
                      type="button"
                      @click="newLms[6].childActive = !newLms[6].childActive"
                      class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
                    >
                      <component
                        :is="arrowComponent(newLms[6].childActive)"
                        class="h-6 w-6 text-white"
                      />
                    </button>
                  </div>
                  <Inlay
                    :open="newLms[6].childActive"
                    class="mt-2 relative border p-4 lg:pl-4 lg:pr-6 focus:outline-none rounded-md bg-white shadow-sm"
                  >
                    <div class="flex space-x-3">
                      <div class="w-1/4 flex flex-col space-y-2 items-center">
                        <img
                          src="https://avatars.dicebear.com/api/initials/STUDIXY.svg"
                          class="h-28 w-28 rounded-full"
                        />
                        <span> {{ $t("lms.create.networking.preview.learning") }} </span>
                      </div>
                      <div class="w-3/4 flex flex-col space-y-2">
                        <p class="text-xl">
                          {{ $t("lms.create.networking.preview.name") }} {{ $t("lms.create.networking.preview.surname") }}
                        </p>
                        <TabGroup as="div">
                          <div class="border-b border-gray-200">
                            <TabList class="-mb-px flex lg:space-x-8 space-x-2">
                              <Tab
                                as="template"
                                v-slot="{ selected }"
                                v-for="tab in tabs"
                                :key="tab.name"
                              >
                                <a
                                  :class="[
                                    selected
                                      ? 'border-primary text-primary'
                                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                    'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm cursor-pointer text-center',
                                  ]"
                                >
                                  <span>{{ $t(tab.name)  }}</span>
                                </a>
                              </Tab>
                            </TabList>
                          </div>
                          <TabPanels as="template">
                            <TabPanel class="py-5 flex flex-col space-y-2">
                              <div class="flex">
                                <span class="w-1/2 font-medium">{{
                                  $t("lms.create.networking.base.country")
                                }}</span>
                                <span class="w-1/2">{{ 
                                  $t("lms.create.networking.preview.country") 
                                }}</span>
                              </div>
                              <div class="flex">
                                <span class="w-1/2 font-medium">{{
                                  $t("lms.create.networking.base.city")
                                }}</span>
                                <span class="w-1/2">{{ 
                                  $t("lms.create.networking.preview.city") 
                                }}</span>
                              </div>
                              <div class="flex">
                                <span class="w-1/2 font-medium">{{
                                  $t("lms.create.networking.base.hobby")
                                }}</span>
                                <span class="w-1/2">{{ 
                                  $t("lms.create.networking.preview.hobby") 
                                }}</span>
                              </div>
                              <div class="flex">
                                <span class="w-1/2 font-medium">{{
                                  $t("lms.create.networking.base.interests")
                                }}</span>
                                <span class="w-1/2">{{ 
                                  $t("lms.create.networking.preview.interests") 
                                }}</span>
                              </div>
                              <template
                                v-if="curLms.networking.additional_fields.enabled"
                              >
                                <template
                                  v-for="(item, index) in curLms.networking
                                    .additional_fields"
                                  :key="index"
                                >
                                  <div class="flex" v-if="item.text">
                                    <span class="w-1/2 font-medium">{{
                                      item.text
                                    }}</span>
                                    <span class="w-1/2" v-if="item.values">{{
                                      item.values
                                    }}</span>
                                    <span class="w-1/2" v-else
                                      >Ответ в свободной форме</span
                                    >
                                  </div>
                                </template>
                              </template>
                            </TabPanel>
                            <TabPanel class="py-5">
                              {{ $t("lms.create.networking.preview.education_info") }} 
                            </TabPanel>
                            <TabPanel class="py-5">
                              {{ $t("lms.create.networking.preview.additional_info") }} 
                            </TabPanel>
                          </TabPanels>
                        </TabGroup>
                      </div>
                    </div>
                  </Inlay>
                </div -->
              </Inlay>
            </div>
            <div
              class="relative border p-4 flex flex-col lg:pl-4 lg:pr-6 focus:outline-none rounded-md bg-white"
            >
              <div class="items-center flex">
                <label class="flex items-center space-x-1 cursor-pointer">
                  <Checkbox
                    v-model="curLms.lifelong_learning.enabled"
                    :disabled="disabledConditions"
                  />
                  <span>{{ $t("lms.create.lifelong_learning.title") }}</span>
                  <QuestionMarkCircleIcon
                    class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                    ref="lifelong_learning"
                  />
                </label>
                <Hint targetref="lifelong_learning" position="right">
                  {{ $t("lms.create.lifelong_learning.hint") }}
                </Hint>
                <button
                  type="button"
                  @click="newLms[7].childActive = !newLms[7].childActive"
                  class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
                >
                  <component
                    :is="arrowComponent(newLms[7].childActive)"
                    class="h-6 w-6 text-white"
                  />
                </button>
                <div class="ml-auto pl-1 text-sm flex flex-col text-center">
                  <span v-if="newLms[7].type === 'once'">{{
                    $t("lms.create.one_time")
                  }}</span>
                  <span class="font-medium"
                    >{{ newLms[7][getPrice] || 0 }}
                    {{ $t(`currency.${selectedCurrency.value}`)
                    }}<span v-if="newLms[7].type === 'month'">
                      / {{ $t("lms.create.time_unit") }}</span
                    ></span
                  >
                </div>
              </div>
              <Inlay
                :open="newLms[7].childActive"
                class="ml-5 mt-2 px-3 py-1.5 space-y-2"
              >
                <div>
                  <div class="flex space-x-1 flex-col">
                    <h3 class="font-medium">
                      {{ $t("lms.create.lifelong_learning.select") }}
                    </h3>
                    <div class="flex items-center">
                      <div
                        class="flex flex-row rounded-lg relative bg-transparent mt-1 counter"
                      >
                        <button
                          :disabled="
                            !curLms.lifelong_learning.enabled ||
                            disabledConditions
                          "
                          @click="MinusLifeCount"
                          type="button"
                          class="bg-gray-50 border hover:bg-gray-100 w-7 p-0.5 rounded-l cursor-pointer outline-none flex items-center justify-center"
                        >
                          <MinusIcon class="w-4 h-4" />
                        </button>
                        <input
                          :disabled="
                            !curLms.lifelong_learning.enabled ||
                            disabledConditions
                          "
                          type="number"
                          v-model="curLms.lifelong_learning.count"
                          class="p-0 w-10 border border-gray-200 outline-none focus:outline-none text-center bg-gray-50 font-medium text-md hover:text-black focus:text-black lg:text-basecursor-default flex items-center text-gray-700 outline-none"
                        />
                        <button
                          :disabled="
                            !curLms.lifelong_learning.enabled ||
                            disabledConditions
                          "
                          @click="PlusLifeCount"
                          type="button"
                          class="bg-gray-50 border hover:bg-gray-100 w-7 p-0.5 rounded-r cursor-pointer outline-none flex items-center justify-center"
                        >
                          <PlusIcon class="w-4 h-4" />
                        </button>
                      </div>
                      <!-- QuestionMarkCircleIcon
                        class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                        ref="lifelong_learning_count"
                      />
                      <Hint targetref="lifelong_learning_count" position="top">
                        {{ $t("lms.create.lifelong_learning.hint") }}
                      </Hint -->
                      <div class="ml-3 pl-1 text-sm flex flex-col text-center">
                        <span v-if="newLms[8].type === 'once'">{{
                          $t("lms.create.one_time")
                        }}</span>
                        <span class="font-medium"
                          >{{
                            curLms.lifelong_learning.count * newLms[8][getPrice]
                          }}
                          {{ $t(`currency.${selectedCurrency.value}`)
                          }}<span v-if="newLms[8].type === 'month'">
                            / {{ $t("lms.create.time_unit") }}</span
                          ></span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <!-- div>
                  <div class="flex items-center space-x-1">
                    <span>{{ $t("lms.create.lifelong_learning.preview.title") }}</span>
                    <QuestionMarkCircleIcon
                      class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                      ref="preview_lifelong"
                    />
                    <Hint targetref="preview_lifelong" position="right">
                      {{ $t("lms.create.lifelong_learning.preview.hint") }}
                    </Hint>
                    <button
                      type="button"
                      @click="newLms[8].childActive = !newLms[8].childActive"
                      class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
                    >
                      <component
                        :is="arrowComponent(newLms[8].childActive)"
                        class="h-6 w-6 text-white"
                      />
                    </button>
                  </div>
                  <Inlay
                    :open="newLms[8].childActive"
                    class="mt-2 relative border p-4 lg:pl-4 lg:pr-6 focus:outline-none rounded-md bg-white shadow-sm"
                  >
                    <div>
                      <div class="flex flex-col text-center">
                        <span>{{ 
                          $t("lms.create.lifelong_learning.preview.program") 
                        }}</span>
                        <Slider
                          v-model="scoreProgram"
                          :format="format"
                          class="mt-12"
                        />
                        <span class="mt-3">{{
                          $t("lms.create.lifelong_learning.preview.exam")
                        }}</span>
                        <Slider v-model="scoreExam" :max="10" class="mt-12" />
                      </div>
                    </div>
                    <div class="flex flex-col mt-3">
                      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div
                          class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
                        >
                          <div
                            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
                          >
                            <table class="min-w-full divide-y divide-gray-200">
                              <thead class="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {{
                                      $t("lms.create.lifelong_learning.preview.knowledges")
                                    }}
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {{
                                      $t("lms.create.lifelong_learning.preview.knowledges_gained")
                                    }}
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {{ $t("lms.create.lifelong_learning.preview.module") }}
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {{
                                      $t("lms.create.lifelong_learning.preview.skills_gained")
                                    }}
                                  </th>
                                  <th
                                    scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    {{ $t("lms.create.lifelong_learning.preview.skills") }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(item, idX) in table"
                                  :key="item.know"
                                  :class="[
                                    idX % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                                    scoreProgram >= item.need ? '' : 'opacity-60',
                                  ]"
                                >
                                  <td
                                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                  >
                                    {{ $t(item.know) }} {{ item.need/10 }}
                                  </td>
                                  <td
                                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                  >
                                    {{ scoreExam * 10 }}%
                                  </td>
                                  <td
                                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                  >
                                    {{ $t(item.module) }} {{ item.need/10 }}
                                  </td>
                                  <td
                                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                  >
                                    {{ scoreExam * 10 }}%
                                  </td>
                                  <td
                                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                  >
                                    {{ $t(item.skill) }} {{ item.need/10 }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Inlay>
                </div -->
              </Inlay>
            </div>
            <div
              class="relative border p-4 flex flex-col lg:pl-4 lg:pr-6 focus:outline-none rounded-md bg-white"
            >
              <div class="items-center flex">
                <label class="flex items-center space-x-1 cursor-pointer">
                  <Checkbox
                    v-model="curLms.rating.enabled"
                    :disabled="disabledConditions"
                  />
                  <span>{{ $t("lms.create.rating.title") }}</span>
                  <QuestionMarkCircleIcon
                    class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                    ref="rating"
                  />
                </label>
                <Hint targetref="rating" position="top">
                  {{ $t("lms.create.rating.hint") }}</Hint
                >
                <button
                  type="button"
                  @click="newLms[9].childActive = !newLms[9].childActive"
                  class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
                >
                  <component
                    :is="arrowComponent(newLms[9].childActive)"
                    class="h-6 w-6 text-white"
                  />
                </button>
                <div class="ml-auto pl-1 text-sm flex flex-col text-center">
                  <span v-if="newLms[9].type === 'once'">{{
                    $t("lms.create.one_time")
                  }}</span>
                  <span class="font-medium"
                    >{{ newLms[9][getPrice] || 0 }}
                    {{ $t(`currency.${selectedCurrency.value}`)
                    }}<span v-if="newLms[9].type === 'month'">
                      / {{ $t("lms.create.time_unit") }}</span
                    ></span
                  >
                </div>
              </div>
              <Inlay
                :open="newLms[9].childActive"
                class="ml-5 mt-2 px-3 py-1.5"
              >
                <fieldset class="space-y-2">
                  <div class="flex flex-col space-y-2">
                    <div class="flex items-center">
                      <h3 class="font-medium">
                        {{ $t("lms.create.rating.view.title") }}
                      </h3>
                      <QuestionMarkCircleIcon
                        class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                        ref="rating_type"
                      />
                      <Hint targetref="rating_type" position="right">
                        {{ $t("lms.create.rating.view.hint") }}</Hint
                      >
                    </div>
                    <RadioGroup
                      v-model="curLms.rating.view"
                      :disabled="!curLms.rating.enabled || disabledConditions"
                    >
                      <div class="relative bg-white rounded-md -space-y-px">
                        <RadioGroupOption
                          as="template"
                          v-for="(item, index) in newLms[12].child"
                          :key="index"
                          :value="item.value"
                          v-slot="{ checked, active, disabled }"
                        >
                          <div
                            :class="[
                              index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                              index === 1 ? 'rounded-bl-md rounded-br-md' : '',
                              checked
                                ? 'bg-indigo-50 border-indigo-200 z-10'
                                : 'border-gray-200',
                              disabled ? 'opacity-80' : '',
                              'relative border p-4 flex flex-col cursor-pointer lg:pl-4 lg:pr-6 focus:outline-none lg:flex-row',
                            ]"
                          >
                            <div class="flex items-center text-sm">
                              <span
                                :class="[
                                  checked
                                    ? 'bg-primary border-transparent'
                                    : 'bg-white border-gray-300',
                                  active
                                    ? 'ring-2 ring-offset-2 ring-primary'
                                    : '',
                                  'h-4 w-4 rounded-full border flex items-center justify-center',
                                ]"
                                aria-hidden="true"
                              >
                                <span
                                  class="rounded-full bg-white w-1.5 h-1.5"
                                />
                              </span>
                              <RadioGroupLabel
                                as="span"
                                :class="[
                                  checked ? 'text-primary' : 'text-gray-900',
                                  'ml-3 font-medium',
                                ]"
                                >{{ $t(item.name) }}</RadioGroupLabel
                              >
                            </div>
                          </div>
                        </RadioGroupOption>
                      </div>
                    </RadioGroup>
                  </div>
                  <!-- div class="flex flex-col space-y-2">
                    <h3 class="font-medium">
                      {{ $t("lms.create.rating.format.title") }}
                    </h3>
                    <RadioGroup
                      v-model="curLms.rating.format"
                      :disabled="!curLms.rating.enabled || disabledConditions"
                    >
                      <div class="relative bg-white rounded-md -space-y-px">
                        <RadioGroupOption
                          as="template"
                          value="standart"
                          v-slot="{ checked, active, disabled }"
                        >
                          <div
                            :class="[
                              checked
                                ? 'bg-indigo-50 border-indigo-200 z-10'
                                : 'border-gray-200',
                              disabled ? 'opacity-80' : '',
                              'relative border p-4 flex flex-col cursor-pointer lg:pl-4 lg:pr-6 focus:outline-none lg:flex-row rounded-tl-md rounded-tr-md',
                            ]"
                          >
                            <div class="flex items-center text-sm w-3/5">
                              <span
                                :class="[
                                  checked
                                    ? 'bg-primary border-transparent'
                                    : 'bg-white border-gray-300',
                                  active
                                    ? 'ring-2 ring-offset-2 ring-primary'
                                    : '',
                                  'h-4 w-4 rounded-full border flex items-center justify-center',
                                ]"
                                aria-hidden="true"
                              >
                                <span class="rounded-full bg-white w-1.5 h-1.5" />
                              </span>
                              <RadioGroupLabel
                                as="span"
                                :class="[
                                  checked ? 'text-primary' : 'text-gray-900',
                                  'ml-3 font-medium',
                                ]"
                                >{{
                                  $t(
                                    "lms.create.rating.format.list.standart.title"
                                  )
                                }}</RadioGroupLabel
                              >
                            </div>
                            <div class="w-1/5">
                              <!- QuestionMarkCircleIcon
                                class="m-auto h-5 w-5 text-gray-400 hover:text-gray-700"
                                ref="rating_format_standart"
                              />
                              <Hint
                                targetref="rating_format_standart"
                                position="right"
                              >
                                {{
                                  $t(
                                    "lms.create.rating.format.list.standart.hint"
                                  )
                                }}
                              </Hint ->
                            </div>
                            <RadioGroupDescription
                              class="ml-6 pl-1 text-sm lg:ml-0 lg:pl-0 lg:text-center w-1/5"
                            >
                              <span
                                :class="[
                                  checked ? 'text-primary' : 'text-gray-900',
                                  'font-medium',
                                ]"
                                >{{ newLms[10].price }}
                                {{ $t(`currency.${selectedCurrency.value}`) }} /
                                {{ $t("lms.create.time_unit") }}</span
                              >
                            </RadioGroupDescription>
                          </div>
                        </RadioGroupOption>
                        <RadioGroupOption
                          as="template"
                          value="custom"
                          v-slot="{ checked, active, disabled }"
                        >
                          <div
                            :class="[
                              checked
                                ? 'bg-indigo-50 border-indigo-200 z-10'
                                : 'border-gray-200',
                              disabled ? 'opacity-80' : '',
                              'relative border p-4 flex flex-col cursor-pointer lg:pl-4 lg:pr-6 focus:outline-none lg:flex-row rounded-bl-md rounded-br-md',
                            ]"
                          >
                            <div class="flex items-center text-sm w-3/5">
                              <span
                                :class="[
                                  checked
                                    ? 'bg-primary border-transparent'
                                    : 'bg-white border-gray-300',
                                  active
                                    ? 'ring-2 ring-offset-2 ring-primary'
                                    : '',
                                  'h-4 w-4 rounded-full border flex items-center justify-center',
                                ]"
                                aria-hidden="true"
                              >
                                <span class="rounded-full bg-white w-1.5 h-1.5" />
                              </span>
                              <RadioGroupLabel
                                as="span"
                                :class="[
                                  checked ? 'text-primary' : 'text-gray-900',
                                  'ml-3 font-medium',
                                ]"
                                >{{
                                  $t("lms.create.rating.format.list.custom.title")
                                }}</RadioGroupLabel
                              >
                            </div>
                            <div class="w-1/5">
                              <!- QuestionMarkCircleIcon
                                class="m-auto h-5 w-5 text-gray-400 hover:text-gray-700"
                                ref="rating_format_standart"
                              />
                              <Hint
                                targetref="rating_format_standart"
                                position="right"
                              >{{
                                  $t("lms.create.rating.format.list.custom.title")
                              }}</Hint ->
                            </div>
                            <RadioGroupDescription
                              class="ml-6 pl-1 text-sm lg:ml-0 lg:pl-0 lg:text-center w-1/5"
                            >
                              <span
                                :class="[
                                  checked ? 'text-primary' : 'text-gray-900',
                                  'font-medium',
                                ]"
                              >
                                {{ newLms[11].price }}
                                {{ $t(`currency.${selectedCurrency.value}`) }} /
                                {{ $t("lms.create.time_unit") }}</span
                              >
                            </RadioGroupDescription>
                          </div>
                        </RadioGroupOption>
                      </div>
                    </RadioGroup>
                  </div -->
                </fieldset>
              </Inlay>
            </div>
            <div
              class="relative border p-4 flex flex-col lg:pl-4 lg:pr-6 focus:outline-none rounded-md bg-white"
            >
              <div class="items-center flex">
                <label class="flex items-center space-x-1 cursor-pointer">
                  <Checkbox
                    v-model="curLms.message"
                    :disabled="disabledConditions"
                  />
                  <span>{{ $t("lms.create.message.title") }}</span>
                  <QuestionMarkCircleIcon
                    class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                    ref="message"
                  />
                </label>
                <Hint targetref="message" position="top">
                  {{ $t("lms.create.message.hint") }}
                </Hint>
                <button
                  type="button"
                  @click="newLms[13].childActive = !newLms[13].childActive"
                  class="ml-2 inline-flex items-center p-0.5 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary-light"
                >
                  <component
                    :is="arrowComponent(newLms[13].childActive)"
                    class="h-6 w-6 text-white"
                  />
                </button>
                <div class="ml-auto pl-1 text-sm flex flex-col text-center">
                  <span v-if="newLms[13].type === 'once'">{{
                    $t("lms.create.one_time")
                  }}</span>
                  <span class="font-medium"
                    >{{ newLms[13][getPrice] || 0 }}
                    {{ $t(`currency.${selectedCurrency.value}`)
                    }}<span v-if="newLms[13].type === 'month'">
                      / {{ $t("lms.create.time_unit") }}</span
                    ></span
                  >
                </div>
              </div>
              <Inlay
                :open="newLms[13].childActive"
                class="ml-5 mt-2 px-3 py-1.5"
              >
                {{ $t("lms.create.message.text") }}
              </Inlay>
            </div>
            <div
              class="relative border p-4 flex flex-col lg:pl-4 lg:pr-6 focus:outline-none rounded-md bg-white"
            >
              <div class="items-center flex">
                <label class="flex items-center space-x-1 cursor-pointer">
                  <Checkbox
                    v-model="curLms.payment"
                    :disabled="disabledConditions"
                  />
                  <span>{{ $t("lms.create.payment.title") }}</span>
                  <QuestionMarkCircleIcon
                    class="-mr-1 ml-2 h-5 w-5 text-gray-400 hover:text-gray-700"
                    ref="payment"
                  />
                </label>
                <Hint targetref="payment" position="top">
                  {{ $t("lms.create.payment.hint") }}
                </Hint>
                <div class="ml-auto pl-1 text-sm flex flex-col text-center">
                  <span v-if="newLms[14].type === 'once'">{{
                    $t("lms.create.one_time")
                  }}</span>
                  <span class="font-medium"
                    >{{ newLms[14][getPrice] || 0 }}
                    {{ $t(`currency.${selectedCurrency.value}`)
                    }}<span v-if="newLms[14].type === 'month'">
                      / {{ $t("lms.create.time_unit") }}</span
                    ></span
                  >
                </div>
              </div>
            </div>
            <div class="flex space-x-5">
              <Select
                v-model="newLms[15].selected"
                :options="newLms[15].child"
                :label="$t('lms.create.duration.title')"
                :onChange="setPeriod($t(newLms[15].selected.text))"
                :disabled="disabledConditions"
                class="w-1/2"
              />
              <Select
                v-model="selectedInstallments"
                :options="installmentsOptions"
                :label="$t('lms.payment.options')"
                :onChange="onChangeInstallments(selectedInstallments?.value)"
                :disabled="disabledConditions"
                class="w-1/2"
              />
            </div>
            <div>
              <button
                @click="updateLms"
                :disabled="disabledButtonConditions"
                :class="
                  disabledButtonConditions
                    ? 'opacity-50 cursor-not-allowed disabled:opacity-50'
                    : 'hover:bg-primary-light'
                "
                type="button"
                class="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                {{ $t("lms.save") }}
              </button>
            </div>
          </fieldset>
        </form>
      </div>
      <div class="lg:w-1/3" v-if="loadingPrice">
        <Loader />
      </div>
      <div class="lg:w-1/3" v-else>
        <div class="shadow-md bg-white rounded-md px-3 py-4 space-y-2">
          <p class="text-center">{{ $t("lms.create.add_in_system") }}</p>
          <!-- div class="" v-for="item in newLms" :key="item.name">
            <template
              v-if="item.name === 'transfer_students' && curLms.transfer_students"
            >
              <div class="flex items-center justify-between">
                <span class="font-medium text-left"> 
                  {{ $t("lms.create.transfer_base") }}  
                </span>
                <span> {{ item.price }} {{ $t(`currency.${selectedCurrency.value}`) }} {{ $t("lms.create.one_time").toLowerCase() }}  </span>
              </div>
            </template>
            <template v-if="item.value && item.name === 'base'">
              <div class="flex items-center justify-between">
                <span class="font-medium text-left"> {{ $t("lms.create.base.title") }} </span>
                <span> {{ item.price*newLms[16].period }} {{ $t(`currency.${selectedCurrency.value}`) }} / {{ formatMonth(($t(`${newLms[15].selected.text}`))) }} </span>
              </div>
            </template>
            <template
              v-if="item.name === 'networking' && curLms.networking.enabled"
            >
              <div class="flex items-center justify-between">
                <span class="font-medium text-left"> 
                  {{ $t("lms.create.networking.title") }}  
                </span>
                <span> {{ item.price*newLms[16].period }} {{ $t(`currency.${selectedCurrency.value}`) }} / {{ newLms[15].selected!==undefined ? formatMonth(($t(`${newLms[15].selected.text}`))): $t("lms.create.time_unit") }} </span>
              </div>
              <div class="flex items-center justify-between ml-3">
                <template v-if="curLms.networking.type === 'local'">
                  <span class="font-medium text-left">
                    {{ $t("lms.create.networking.type.local.headline") }}
                  </span>
                  <span> {{ item.child[0].type[0].price*newLms[16].period }} {{ $t(`currency.${selectedCurrency.value}`) }} / {{ newLms[15].selected!==undefined ? formatMonth(($t(`${newLms[15].selected.text}`))): $t("lms.create.time_unit") }} </span>
                </template>
                <template v-if="curLms.networking.type === 'global'">
                  <span class="font-medium text-left">
                    {{ $t("lms.create.networking.type.global.headline") }}
                  </span>
                  <span> {{ item.child[0].type[1].price*newLms[16].period }} {{ $t(`currency.${selectedCurrency.value}`) }} / {{ newLms[15].selected!==undefined ? formatMonth(($t(`${newLms[15].selected.text}`))): $t("lms.create.time_unit") }} </span>
                </template>
              </div>
              <div class="flex items-center ml-3">
                <span class="font-medium text-left">
                  {{ $t("lms.create.networking.base.headline") }}
                </span>
              </div>
              <div
                class="flex items-center justify-between ml-3"
                v-if="curLms.networking.additional_fields.length > 0"
              >
                <span class="font-medium text-left"
                  >{{ $t("lms.create.networking.additional_fields.title") }}
                  {{ curLms.networking.additional_fields.length }} {{ $t("lms.create.piece") }}</span
                >
                <span
                  >{{
                    curLms.networking.additional_fields.length *
                    newLms[5].childPrice*newLms[16].period
                  }} {{ $t(`currency.${selectedCurrency.value}`) }} / {{ newLms[15].selected!==undefined ? formatMonth(($t(`${newLms[15].selected.text}`))): $t("lms.create.time_unit") }}</span
                >
              </div>
            </template>
            <template
              v-if="
                item.name === 'lifelong_learning' &&
                curLms.lifelong_learning.enabled
              "
            >
              <div class="flex items-center justify-between">
                <span class="font-medium text-left">
                  {{ $t("lms.create.lifelong_learning.title") }}
                </span>
                <span> {{ item.price*newLms[16].period }} {{ $t(`currency.${selectedCurrency.value}`) }} / {{ newLms[15].selected!==undefined ? formatMonth(($t(`${newLms[15].selected.text}`))): $t("lms.create.time_unit") }} </span>
              </div>
              <div
                class="flex items-center justify-between ml-3"
                v-if="curLms.lifelong_learning.count > 0"
              >
                <span class="font-medium text-left">
                  {{ $t("lms.create.lifelong_learning.programme") }} {{ curLms.lifelong_learning.count }} {{ $t("lms.create.piece") }}
                </span>
                <span>
                  {{ curLms.lifelong_learning.count * newLms[8].price }} {{ $t(`currency.${selectedCurrency.value}`) }} / {{ newLms[15].selected!==undefined ? formatMonth(($t(`${newLms[15].selected.text}`))): $t("lms.create.time_unit") }}
                </span>
              </div>
            </template>
            <template v-if="item.name === 'rating' && curLms.rating.enabled">
              <div class="flex items-center justify-between">
                <span class="font-medium text-left">
                  {{ $t("lms.create.rating.title") }}
                </span>
                <span> {{ item.price*newLms[16].period }} {{ $t(`currency.${selectedCurrency.value}`) }} / {{ newLms[15].selected!==undefined ? formatMonth(($t(`${newLms[15].selected.text}`))): $t("lms.create.time_unit") }} </span>
              </div>
              <div
                class="flex items-center justify-between ml-3"
                v-if="curLms.rating.format === 'standart'"
              >
                <span class="font-medium text-left"> 
                  {{ $t("lms.create.rating.format.list.standart.headline") }} 
                </span>
                <span> {{ newLms[10].price*newLms[16].period }} {{ $t(`currency.${selectedCurrency.value}`) }} / {{ newLms[15].selected!==undefined ? formatMonth(($t(`${newLms[15].selected.text}`))): $t("lms.create.time_unit") }} </span>
              </div>
              <div
                class="flex items-center justify-between ml-3"
                v-if="curLms.rating.format === 'custom'"
              >
                <span class="font-medium">
                  {{ $t("lms.create.rating.format.list.custom.headline") }}
                </span>
                <span> {{ newLms[11].price*newLms[16].period }} {{ $t(`currency.${selectedCurrency.value}`) }} / {{ newLms[15].selected!==undefined ? formatMonth(($t(`${newLms[15].selected.text}`))): $t("lms.create.time_unit") }} </span>
              </div>
              <div
                class="flex items-center justify-between ml-3"
                v-if="curLms.rating.view === 100"
              >
                <span class="font-medium">
                  {{ $t("lms.create.rating.view.list.100") }}
                </span>
              </div>
              <div
                class="flex items-center justify-between ml-3"
                v-if="curLms.rating.view === 10"
              >
                <span class="font-medium">
                  {{ $t("lms.create.rating.view.list.10") }}
                </span>
              </div>
              <div
                class="flex items-center justify-between ml-3"
                v-if="curLms.rating.view === 5"
              >
                <span class="font-medium">
                  {{ $t("lms.create.rating.view.list.5") }}
                </span>
              </div>
            </template>
            <template v-if="item.name === 'message' && curLms.message">
              <div class="flex items-center justify-between">
                <span class="font-medium text-left">
                  {{ $t("lms.create.message.title") }} 
                </span>
                <span> {{ item.price*newLms[16].period }} {{ $t(`currency.${selectedCurrency.value}`) }} / {{ newLms[15].selected!==undefined ? formatMonth(($t(`${newLms[15].selected.text}`))): $t("lms.create.time_unit") }} </span>
              </div>
            </template>
            <template v-if="item.name === 'payment' && curLms.payment">
              <div class="flex items-center justify-between">
                <span class="font-medium text-left">
                  {{ $t("lms.create.payment.title") }}
                </span>
                <span> {{ item.price*newLms[16].period }} {{ $t(`currency.${selectedCurrency.value}`) }} / {{ newLms[15].selected!==undefined ? formatMonth(($t(`${newLms[15].selected.text}`))): $t("lms.create.time_unit") }} </span>
              </div>
            </template>
          </div -->
          <div v-for="(item, key) in lmsPrice.modules" :key="item.name">
            <div class="flex justify-between">
              <span class="font-medium text-left">
                {{ $t(`lms.create.${key}.title`) }}
              </span>
              <div class="text-right">
                <div v-if="item.period === 'once'" class="w-full">
                  {{ $t(item.period) }}
                </div>
                <span>
                  {{ item.price }}
                  {{ $t(`currency.${selectedCurrency.value}`)
                  }}<span v-if="item.period !== 'once'">
                    / {{ $t(item.period) }}</span
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="shadow-md bg-white rounded-md px-3 py-4 text-center mt-3">
          <p>{{ $t("lms.create.summary") }}</p>
          <p>
            {{ lmsPrice.amount }} {{ $t(`currency.${selectedCurrency.value}`) }}
          </p>
        </div>
        <Payment
          v-for="payment in payments"
          :data="payment"
          :key="payment.id"
        />
      </div>
    </div>

    <template v-if="curLms.user_documents">
      <Title>{{ $t("documents.all_user") }}</Title>
      <DocumentCard
        v-for="document in curLms.user_documents"
        :lmsId="curLms.id"
        :userId="curLms.user_id"
        :document="document"
        :key="document.id"
      />
    </template>
  </div>
</template>

<script>
import Slider from "@vueform/slider";
import { reactive, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  ListboxLabel,
} from "@headlessui/vue";
import Checkbox from "@/components/Checkbox";
import Inlay from "@/components/Inlay";
import Hint from "@/components/Hint";
import Radio from "@/components/Radio";
import Payment from "@/components/LMS/Payment";
import { DocumentCard } from "@/components/Documents";
import {
  QuestionMarkCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PlusIcon,
  MinusIcon,
  CheckIcon,
  XIcon,
  SelectorIcon,
  PlusSmIcon,
  MinusSmIcon,
} from "@heroicons/vue/solid";
import { Loader, Info, Select, Title } from "@/UI";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
const tabs = [
  { name: "lms.create.networking.preview.general" },
  { name: "lms.create.networking.preview.education" },
  { name: "lms.create.networking.preview.additional" },
];
const table = [
  {
    know: "lms.create.lifelong_learning.preview.knowledge",
    module: "lms.create.lifelong_learning.preview.module",
    skill: "lms.create.lifelong_learning.preview.skill",
    need: 100,
  },
  {
    know: "lms.create.lifelong_learning.preview.knowledge",
    module: "lms.create.lifelong_learning.preview.module",
    skill: "lms.create.lifelong_learning.preview.skill",
    need: 90,
  },
  {
    know: "lms.create.lifelong_learning.preview.knowledge",
    module: "lms.create.lifelong_learning.preview.module",
    skill: "lms.create.lifelong_learning.preview.skill",
    need: 80,
  },
  {
    know: "lms.create.lifelong_learning.preview.knowledge",
    module: "lms.create.lifelong_learning.preview.module",
    skill: "lms.create.lifelong_learning.preview.skill",
    need: 70,
  },
  {
    know: "lms.create.lifelong_learning.preview.knowledge",
    module: "lms.create.lifelong_learning.preview.module",
    skill: "lms.create.lifelong_learning.preview.skill",
    need: 60,
  },
  {
    know: "lms.create.lifelong_learning.preview.knowledge",
    module: "lms.create.lifelong_learning.preview.module",
    skill: "lms.create.lifelong_learning.preview.skill",
    need: 50,
  },
  {
    know: "lms.create.lifelong_learning.preview.knowledge",
    module: "lms.create.lifelong_learning.preview.module",
    skill: "lms.create.lifelong_learning.preview.skill",
    need: 40,
  },
  {
    know: "lms.create.lifelong_learning.preview.knowledge",
    module: "lms.create.lifelong_learning.preview.module",
    skill: "lms.create.lifelong_learning.preview.skill",
    need: 30,
  },
  {
    know: "lms.create.lifelong_learning.preview.knowledge",
    module: "lms.create.lifelong_learning.preview.module",
    skill: "lms.create.lifelong_learning.preview.skill",
    need: 20,
  },
  {
    know: "lms.create.lifelong_learning.preview.knowledge",
    module: "lms.create.lifelong_learning.preview.module",
    skill: "lms.create.lifelong_learning.preview.skill",
    need: 10,
  },
];
export default {
  components: {
    Checkbox,
    Hint,
    QuestionMarkCircleIcon,
    Inlay,
    ChevronDownIcon,
    Radio,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    PlusIcon,
    MinusIcon,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    ListboxLabel,
    CheckIcon,
    XIcon,
    SelectorIcon,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    PlusSmIcon,
    MinusSmIcon,
    Slider,
    Loader,
    Info,
    Select,
    Title,
    Payment,
    DocumentCard,
  },
  watch: {
    curLms: {
      deep: true,
      async handler(value) {
        if (this.loading || this.firstLoad) return;
        if (value.domain !== this.previousDomain) return;

        this.saveTemplateLms(value);
      },
    },
  },
  setup() {
    const scoreProgram = ref(30);
    const router = useRouter();
    const route = useRoute();
    const loading = ref(true);
    const firstLoad = ref(true);
    const loadingDomain = ref(false);
    const loadingPrice = ref(false);
    const viewCheckDomain = ref(false);
    const name = ref("");
    const prefix = ref("");
    const scoreExam = ref(7);
    const store = useStore();
    const curLms = computed(() => store.getters.getCurLms);
    const price = computed(() => store.getters.getAllPrice);
    const lmsPrice = computed(() => store.getters.getLmsPrice);
    const networking = computed(() => store.getters.getAllNetworking);
    const domainValid = computed(() => store.getters.getValidDomain);
    const payments = computed(() => store.getters.getPayments);
    const student = (list) => ({
      title: `lms.create.base.list.student.${list[0]}.title`,
      hint: `lms.create.base.list.student.${list[0]}.hint`,
    });
    const admin = (list) => ({
      title: `lms.create.base.list.admin.${list[0]}.title`,
      hint: `lms.create.base.list.admin.${list[0]}.hint`,
    });
    const newLms = reactive([
      {
        name: "transfer_students",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
      },
      {
        name: "base",
        value: true,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
        childActive: false,
        child: [
          student`lk`,
          student`programs`,
          student`scores`,
          student`news`,
          student`docs`,
          student`calendar`,
          admin`programs`,
          admin`students`,
          admin`projects`,
          admin`file-manager`,
          admin`prices`,
          admin`docs`,
          admin`reports`,
          admin`tests`,
          admin`settings`,
        ],
      },
      {
        name: "networking",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
        childActive: false,
        child: [
          {
            name: "networking_type",
            value: "",
            type: [
              {
                name: "local",
                price: null,
                price_usd: null,
                price_euro: null,
                title: "lms.create.networking.type.local.title",
                hint: "lms.create.networking.type.local.hint",
              },
              {
                name: "global",
                price: null,
                price_usd: null,
                price_euro: null,
                title: "lms.create.networking.type.global.title",
                hint: "lms.create.networking.type.global.hint",
              },
            ],
          },
          {
            name: "networking_base",
            active: false,
            fields: [
              "lms.create.networking.base.surname",
              "lms.create.networking.base.name",
              "lms.create.networking.base.country",
              "lms.create.networking.base.city",
              "lms.create.networking.base.age",
              "lms.create.networking.base.hobby",
              "lms.create.networking.base.interests",
            ],
          },
        ],
      },
      {
        name: "networking_local",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
      },
      {
        name: "networking_global",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
      },
      {
        name: "additional_fields",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
        selected: [],
        child: [],
        childActive: false,
      },
      {
        name: "preview",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
        childActive: false,
      },
      {
        name: "lifelong_learning",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
        childActive: false,
      },
      {
        name: "lifelong_learning_count",
        count: 0,
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
        childActive: false,
      },
      {
        name: "rating",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
        childActive: false,
        format: null,
      },
      {
        name: "rating_standard",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
      },
      {
        name: "rating_custom",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
      },
      {
        name: "rating_type",
        value: null,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
        child: [
          {
            name: "lms.create.rating.view.list.100",
            value: 100,
          },
          {
            name: "lms.create.rating.view.list.10",
            value: 10,
          },
          {
            name: "lms.create.rating.view.list.5",
            value: 5,
          },
        ],
      },
      {
        name: "message",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
      },
      {
        name: "payment",
        value: false,
        price: null,
        price_usd: null,
        price_euro: null,
        parent_module: null,
        type: null,
      },
      {
        name: "duration_contract",
        value: null,
        child: [
          {
            text: "lms.create.duration.1",
            value: 1,
          },
          {
            text: "lms.create.duration.3",
            value: 3,
          },
          {
            text: "lms.create.duration.6",
            value: 6,
          },
          {
            text: "lms.create.duration.9",
            value: 9,
          },
          {
            text: "lms.create.duration.12",
            value: 12,
          },
        ],
      },
    ]);
    const selectedCurrency = ref(null);
    const currencyOptions = [
      {
        text: "currency.rub",
        value: "rub",
      },
      {
        text: "currency.usd",
        value: "usd",
      },
      {
        text: "currency.euro",
        value: "euro",
      },
    ];
    const selectedInstallments = ref(null);
    const installmentsOptions = computed(() => {
      const value = newLms[16].period;

      return [
        ...(value >= 1
          ? [
              {
                value: 0,
                text: "lms.payment.prepayment",
              },
            ]
          : []),
        ...(value >= 3
          ? [
              {
                value: 3,
                text: "lms.payment.installment_3",
              },
            ]
          : []),
        ...(value >= 6
          ? [
              {
                value: 6,
                text: "lms.payment.installment_6",
              },
            ]
          : []),
        ...(value >= 9
          ? [
              {
                value: 9,
                text: "lms.payment.installment_9",
              },
            ]
          : []),
        ...(value >= 12
          ? [
              {
                value: 12,
                text: "lms.payment.installment_12",
              },
            ]
          : []),
      ];
    });

    const previousPeriod = ref(1);
    const previousDomain = ref("");
    const format = function (value) {
      return `${value}%`;
    };
    return {
      newLms,
      price,
      lmsPrice,
      networking,
      payments,
      tabs,
      scoreProgram,
      scoreExam,
      format,
      table,
      prefix,
      name,
      store,
      loading,
      firstLoad,
      domainValid,
      loadingDomain,
      loadingPrice,
      viewCheckDomain,
      curLms,
      route,
      router,
      previousPeriod,
      previousDomain,
      selectedCurrency,
      currencyOptions,
      selectedInstallments,
      installmentsOptions,
    };
  },
  async mounted() {
    await this.store.dispatch("getCurrentLms", this.route.params.id);
    await this.store.dispatch("fetchAllNetworking");
    await this.store.dispatch("fetchAllPrice", "perPage=-1");
    if (this.route.params.id)
      await this.store.dispatch("fetchLmsPrice", this.route.params.id);
    this.newLms.forEach((key, i) => {
      this.price.forEach((item) => {
        if (item.module === key.name) {
          this.newLms[i].price = item.price;
          this.newLms[i].price_usd = item.price_usd;
          this.newLms[i].price_euro = item.price_euro;
          this.newLms[i].type = item.type;

          if (item.parent_module)
            this.newLms[i].parent_module = this.price[item.id - 1].module;
        }
        if (item.module === "networking_local") {
          this.newLms[2].child[0].type[0].price = item.price;
          this.newLms[2].child[0].type[0].price_usd = item.price_usd;
          this.newLms[2].child[0].type[0].price_euro = item.price_euro;
          this.newLms[2].child[0].type[0].type = item.type;
        }
        if (item.module === "networking_global") {
          this.newLms[2].child[0].type[1].price = item.price;
          this.newLms[2].child[0].type[1].price_usd = item.price_usd;
          this.newLms[2].child[0].type[1].price_euro = item.price_euro;
          this.newLms[2].child[0].type[1].type = item.type;
        }
        if (item.module === "networking_additional_fields") {
          this.newLms[5].price = item.price;
          this.newLms[5].price_usd = item.price_usd;
          this.newLms[5].price_euro = item.price_euro;
        }
      });
      if (key.name === "additional_fields") {
        key.child = this.networking;
        key.child = key.child.map((item) => ({
          value: item.key,
          text: item.name,
          values: item.values,
        }));
      }
    });
    if (!this.curLms.rating) {
      this.curLms.rating = {
        enabled: null,
        view: null,
        format: null,
      };
    }
    if (!this.curLms.lifelong_learning) {
      this.curLms.lifelong_learning = {
        enabled: null,
        count: 0,
      };
    }
    if (!this.curLms.networking) {
      this.curLms.networking = {
        enabled: false,
        type: null,
        additional_fields: {
          enabled: false,
          items: [],
        },
      };
    }
    this.curLms.networking.additional_fields ??
      (this.curLms.networking.additional_fields = {
        enabled: false,
        items: [],
      });
    if (this.curLms.networking && this.curLms.networking.type) {
      this.newLms[2].child[0].value = this.curLms.networking.type;
    }
    switch (this.curLms.duration_contract) {
      case 1:
        this.newLms[15].selected = {
          text: "lms.create.duration.1",
          value: 1,
        };
        break;
      case 3:
        this.newLms[15].selected = {
          text: "lms.create.duration.3",
          value: 3,
        };
        break;
      case 6:
        this.newLms[15].selected = {
          text: "lms.create.duration.6",
          value: 6,
        };
        break;
      case 9:
        this.newLms[15].selected = {
          text: "lms.create.duration.9",
          value: 9,
        };
        break;
      case 12:
        this.newLms[15].selected = {
          text: "lms.create.duration.12",
          value: 12,
        };
        break;
      default:
        this.newLms[15].selected = {
          text: "lms.create.duration.1",
          value: 1,
        };
    }
    // ?
    switch (this.curLms.installments != null) {
      case 0:
        this.selectedInstallments = {
          value: 0,
          text: "lms.payment.prepayment",
        };
        break;
      case 3:
        this.selectedInstallments = {
          text: "lms.payment.installment_3",
          value: 3,
        };
        break;
      case 6:
        this.selectedInstallments = {
          text: "lms.payment.installment_6",
          value: 6,
        };
        break;
      case 9:
        this.selectedInstallments = {
          text: "lms.payment.installment_9",
          value: 9,
        };
        break;
      case 12:
        this.selectedInstallments = {
          text: "lms.payment.installment_9",
          value: 9,
        };
        break;
      default:
        this.selectedInstallments = {
          text: "lms.payment.prepayment",
          value: 0,
        };
    }
    switch (this.curLms.currency) {
      case "usd":
        this.selectedCurrency = this.currencyOptions[1];
        break;
      case "euro":
        this.selectedCurrency = this.currencyOptions[2];
        break;
      default:
        this.selectedCurrency = this.currencyOptions[0];
    }

    if (!this.newLms[16]) {
      this.newLms[16] = {
        name: "selectedPeriod",
        period: 1,
      };
    }
    this.previousPeriod = this.newLms[15].selected.value;

    this.previousDomain = this.curLms.domain;

    this.checkDomain(this.curLms.domain);
    this.$nextTick(() => (this.loading = false));
  },
  methods: {
    arrowComponent(value) {
      return value ? ChevronUpIcon : ChevronDownIcon;
    },
    changeNetworkingStatus(value) {
      const networkingType = this.curLms.networking;
      const defaultType = this.newLms[2].child[0].type[0].name;

      if (value && !networkingType.type) networkingType.type = defaultType;
    },
    addAdditionalField() {
      const isNetworkingEnabled = this.curLms.networking.enabled;
      const isAdditionalFieldsEnabled =
        this.curLms.networking.additional_fields.enabled;
      const conditions =
        !this.disabledConditions &&
        isNetworkingEnabled &&
        isAdditionalFieldsEnabled;

      if (conditions) {
        this.curLms.networking.additional_fields?.items.push({
          value: null,
          text: null,
        });
      }
    },
    deleteAdditional(index) {
      this.curLms.networking.additional_fields.items.splice(index, 1);
    },
    PlusLifeCount() {
      this.curLms.lifelong_learning.count++;
    },
    MinusLifeCount() {
      if (this.curLms.lifelong_learning.count > 0)
        this.curLms.lifelong_learning.count--;
    },
    async checkDomain() {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(async () => {
        let params = {
          domain: this.curLms.domain,
          id: this.route.params.id,
        };
        this.viewCheckDomain = true;
        this.loadingDomain = true;
        await this.store.dispatch("checkDomainId", params);
        this.loadingDomain = false;
      }, 800);
    },
    async saveTemplateLms(value) {
      value.duration_contract = this.newLms[15].selected.value;
      value.status = "template";
      this.loadingPrice = true;
      await this.store.dispatch("updateLmsAsTemplate", value);
      await this.store.dispatch("fetchLmsPrice", this.route.params.id);
      this.loadingPrice = false;
    },
    domainChanged() {
      this.saveTemplateLms(this.curLms);
      this.previousDomain = this.curLms.domain;
    },
    async updateLms() {
      this.loading = true;

      try {
        this.curLms.duration_contract = this.newLms[15].selected.value;
        if (this.curLms.status === "template") this.curLms.status = "new";
        await this.store.dispatch("updateLms", this.curLms);
        await this.$emitter.$emit("viewAllLms");

        this.router.push("/");
      } catch (error) {
        this.loading = false;
      }
    },
    formatMonth(date) {
      if (date.indexOf("месяца") > -1) {
        return date.split("месяца").join("мес.");
      }
      if (date.indexOf("месяцев") > -1) {
        return date.split("месяцев").join("мес.");
      }
      if (date.indexOf("месяц") > -1) {
        return date.split("месяц").join("мес.");
      }
      return " год.";
    },
    setPeriod(val) {
      if (this.previousPeriod !== this.newLms[15].selected.value) {
        const curLmsCopy = { ...this.curLms };
        curLmsCopy.duration_contract = this.newLms[15].selected.value;
        this.saveTemplateLms(curLmsCopy);
      }

      switch (val) {
        case "1 year":
        case "1 год": {
          this.newLms[16].period = 12;
          break;
        }
        case "3 months":
        case "3 месяца": {
          this.newLms[16].period = 3;
          break;
        }
        case "6 months":
        case "6 месяцев": {
          this.newLms[16].period = 6;
          break;
        }
        case "9 months":
        case "9 месяцев": {
          this.newLms[16].period = 9;
          break;
        }
        default:
          this.newLms[16].period = 1;
      }

      const { period: value } = this.newLms[16];

      if (this.selectedInstallments?.value > value) {
        const found = this.installmentsOptions.find((obj) => {
          return obj.value === value;
        });

        this.selectedInstallments = found;
      }
      this.previousPeriod = this.newLms[16].period;
      this.$nextTick(() => (this.firstLoad = false));
    },
    onChangeInstallments(value) {
      if (value !== null) this.curLms.installments = value;
    },
    onChangeCurrency(value) {
      if (value !== null) this.curLms.currency = value;
    },
    isDisabled(value) {
      const length = this.curLms.networking.additional_fields.items.filter(
        (element) => element.value === value
      ).length;

      return length === 1;
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    monthElmenets() {
      return this.newLms.filter((item) => {
        return item.type === "month" && item.value === true;
      });
    },
    // eslint-disable-next-line vue/return-in-computed-property
    summOfMonth() {
      let summ = 2000 * this.newLms[16].period;
      //summ += this.monthElmenets.reduce((acc, curr) => {
      //  return acc + curr[this.getPrice];
      //}, 0);
      if (this.curLms.lifelong_learning.enabled) {
        summ += this.newLms[7][this.getPrice] * this.newLms[16].period;
        if (this.curLms.lifelong_learning.count > 0) {
          summ +=
            this.curLms.lifelong_learning.count *
            this.newLms[8][this.getPrice] *
            this.newLms[16].period;
        }
      }
      if (this.curLms.networking.enabled) {
        summ += this.newLms[2][this.getPrice] * this.newLms[16].period;
        if (
          this.curLms.networking.type === "local" &&
          this.curLms.networking.enabled
        ) {
          summ +=
            this.newLms[2].child[0].type[0][this.getPrice] *
            this.newLms[16].period;
        }
        if (
          this.curLms.networking.type === "global" &&
          this.curLms.networking.enabled
        ) {
          summ +=
            this.newLms[2].child[0].type[1][this.getPrice] *
            this.newLms[16].period;
        }
        if (
          this.curLms.networking.additional_fields.items.length &&
          this.curLms.networking.additional_fields.enabled
        ) {
          summ +=
            this.curLms.networking.additional_fields.length *
            this.newLms[5].childPrice *
            this.newLms[16].period;
        }
      }
      if (this.curLms.rating.enabled) {
        summ += this.newLms[9][this.getPrice] * this.newLms[16].period;
        if (this.curLms.rating.format === "standart") {
          summ += this.newLms[10][this.getPrice] * this.newLms[16].period;
        }
        if (this.curLms.rating.format === "custom") {
          summ += this.newLms[11][this.getPrice] * this.newLms[16].period;
        }
      }
      // if (this.curLms.rating.enabled && this.curLms.rating.format) {
      //   summ += this.newLms[9][this.getPrice]*this.newLms[16].period;
      // }
      return summ;
    },
    onceElmenets() {
      return this.newLms.filter((item) => {
        return item.type === "once" && item.value === true;
      });
    },
    summOfOnce() {
      return this.onceElmenets.reduce((acc, curr) => {
        return acc + curr.price;
      }, 0);
    },
    // eslint-disable-next-line vue/return-in-computed-property
    summary() {},
    disabledButtonConditions() {
      return this.domainValid.data !== true || this.disabledConditions;
    },
    disabledConditions() {
      const isTemplate = this.curLms.status === "template";

      const isAdmin = this.$gates.hasRole("admin");
      const isSuperadmin = this.$gates.hasRole("superadmin");
      const roles = isAdmin || isSuperadmin;

      const conditions = !isTemplate && !roles;

      return conditions;
    },
    getPrice() {
      switch (this.selectedCurrency.value) {
        case "usd":
          return "price_usd";
        case "euro":
          return "price_euro";
        default:
          return "price";
      }
    },
  },
};
</script>

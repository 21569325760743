<template>
  <Loader v-if="!price" />
  <div v-else>
    <div class="my-5">{{ $t("lms.payment.sum") }}: {{ price.amount }}</div>
    <button
      @click="buy"
      :disabled="false"
      :class="
        false
          ? 'opacity-50 cursor-not-allowed disabled:opacity-50'
          : 'hover:bg-primary-light'
      "
      type="button"
      class="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
    >
      {{ $t("lms.payment.pay") }}
    </button>
  </div>
</template>

<script>
import { computed, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { Loader } from "@/UI";
import PaymentPageSdk from "@raiffeisen-ecom/payment-sdk";

export default {
  components: {
    Loader,
  },
  async created() {
    await this.store.dispatch("fetchLmsPrice", this.id);
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const price = computed(() => store.getters.getLmsPrice);
    const payment = computed(() => store.getters.getLmsPayment);
    const id = +route.params.id;

    async function buy() {
      await store.dispatch("fetchLmsPayment", id);

      new PaymentPageSdk(payment.value.publicId)
        .openPopup(payment.value)
        .then(() => router.push("/success_pay"))
        .catch((error) => console.error(error));
    }

    onUnmounted(() => {
      store.commit("SET_LMS_PRICE", null);
    });

    return {
      store,
      price,
      payment,
      id,
      buy,
    };
  },
};
</script>

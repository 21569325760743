<template>
  <span :class="cls">
    <input
      :id="id"
      v-model="radioValue"
      type="radio"
      :class="$style.Radio__input"
      :disabled="disabled"
      :name="name"
      :value="value"
      v-bind="$attrs"
    />
  </span>
</template>

<script>
export const SIZES = ["s", "m", "l"];

export default {
  name: "Radio",
  model: {
    prop: "current",
    event: "change",
  },
  props: {
    current: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    value: {
      type: String,
      default: "on",
    },
    size: {
      type: String,
      default: SIZES[1],
      validator(value) {
        return SIZES.indexOf(value) !== -1;
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checked() {
      return this.$attrs.modelValue === this.value;
    },
    radioValue: {
      get() {
        return this.$attrs.modelValue;
      },
      set() {
        this.$emit("change", this.value);
      },
    },
    cls() {
      return {
        [this.$style.Radio]: true,
        [this.$style[`Radio_size_${this.size}`]]: true,
        [this.$style.Radio_checked]: this.checked,
        [this.$style.Radio_error]: this.error && !this.checked,
        [this.$style.Radio_disabled]: this.disabled,
      };
    },
  },
};
</script>

<style module lang="scss">
@import "../../lib/variables";
@import "../../lib/mixins";

$radioSizes: (s 16px), (m 20px), (l 24px);

.Radio {
  position: relative;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-width: 1px;
  transition: background-color 0.1s ease-out;

  @apply border-gray-300 bg-white hover:bg-gray-100;

  &:active {
    @apply bg-gray-200;
  }
}

.Radio__input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: inherit;
  height: inherit;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: pointer;
  overflow: hidden;
}

@each $name, $size in $radioSizes {
  .Radio_size_#{$name} {
    width: $size;
    height: $size;

    &::before {
      top: calc($size / 5);
      left: calc($size / 5);
    }
  }
}

.Radio.Radio_error {
  background-color: $xLightRed;
}

.Radio.Radio_disabled {
  background-color: $lightGrey;
  cursor: default;

  & > .Radio__input {
    cursor: default;
  }
}

.Radio.Radio_checked {
  @apply border-transparent;
  background-color: $primary;

  & [type="radio"]:checked {
    background-image: none;
  }

  &::before {
    content: "";
    position: absolute;
    background: $white;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    z-index: 1;
  }

  &:hover {
    @apply bg-indigo-700;
  }

  &:active {
    @apply bg-indigo-800;
  }
}

.Radio.Radio_checked.Radio_disabled {
  @apply bg-indigo-300;
}
</style>

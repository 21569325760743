<template>
  <CheckCircleIcon class="text-green-400" aria-hidden="true" />
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/outline";

export default {
  components: {
    CheckCircleIcon,
  },
};
</script>

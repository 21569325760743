<template>
  <transition
    :enter-class="$style.Inlay_trans_enter"
    :enter-active-class="$style.Inlay_trans_active"
    :enter-to-class="$style.Inlay_trans_enterTo"
    :leave-class="$style.Inlay_trans_leave"
    :leave-active-class="$style.Inlay_trans_leaveActive"
    :leave-to-class="$style.Inlay_trans_leaveTo"
    @beforeEnter="beforeEnter"
    @beforeLeave="beforeLeave"
    @afterEnter="afterEnter"
    @afterLeave="afterLeave"
  >
    <div v-show="open" :class="$style.Inlay">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: "Inlay",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    complete(el) {
      el.style.maxHeight = "";
      el.style.boxSizing = "";
      el.style.overflow = "";
      this.$emit("transition-complete");
    },
    beforeEnter(el) {
      el.style.width = el.parentElement.offsetWidth + "px";
      el.style.position = "absolute";
      el.style.overflow = "hidden";
      el.style.visibility = "hidden";
      el.style.display = "block";
      el.style.boxSizing = "border-box";
      el.style.maxHeight = el.offsetHeight + "px";
      el.style.display = "";
      el.style.visibility = "";
      el.style.position = "";
      el.style.width = "";
    },
    afterEnter(el) {
      this.complete(el);
    },
    beforeLeave(el) {
      el.style.boxSizing = "border-box";
      el.style.overflow = "hidden";
      el.style.maxHeight = el.offsetHeight + "px";
    },
    afterLeave(el) {
      this.complete(el);
    },
  },
};
</script>

<style module lang="scss">
@import "./utils";

.Inlay {
  @include inlay();
}
</style>
